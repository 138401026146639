import React, { useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Notification as Notify } from "@progress/kendo-react-notification";
import BuzopsButton from "generic-components/BuzopsButton";
import PrimaryMember from "../../../PrimaryMember.png";
import SubMember from "../../../SubMember.png";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import { checkIfUserCanAddBankAccount } from "utils";
const PlanWithdrawFreeze = (props: any) => {
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);

  const [addNewItem, setAddNewItem] = useState<any>(null);
  const PaymentMethodItems = [
    {
      iconClass: "fa fa-credit-card pr-1",
      text: "Add credit card",
      key: "addCard",
      visible:true,
    },
    {
      iconClass: "fa fa-university pr-1",
      text: "Add bank account",
      key: "addBank",
      visible: checkIfUserCanAddBankAccount()
    },
  ];
  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const handleSuccessClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
    await props?.fetchPaymentProfiles(props?.userData?.MainUserId);
  };

  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
         className="bz-addCard-dialog"
          title={`${checkIfUserCanAddBankAccount() ? "Add Bank or Card": "Add Card"}`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={props?.staffPage}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={props?.userData?.UserMemberId}
          />
        </Dialog>
      </>
    );
  };
  return (
    <>
      <div className="bz-plan-note">
        <p className="mb-2">
          <Notify key="info" type={{ style: "warning" }}>
            {`Add-on will unfreeze along with Membership`}
          </Notify>
        </p>
      </div>

      <div className="card card-fluid bz-sub-card mb-2 bz-card bz-cancel-package">
        <div className="card-header bz-no-background  bz-card-heading text-capitalize d-flex align-items-center text-muted pl-4">
          {/* <h6 className="h6 bz-border-Inactive-sm bz-card-heading text-capitalize card-subtitle d-flex align-items-center text-muted bz--left-border pl-2">
            

          </h6> */}
          {props?.selectedPlan.MemberType === 0 ? (
              <img src={PrimaryMember} className="bz-member-icon" />
            ) : (
              <img src={SubMember} className="bz-member-icon" />
            )}
            {props?.selectedPlan?.MemberName}
        </div>
        <div className="card-body p-0 position-relative withdraw-plan">
          <div className="bz-row no-gutters">
            <div className="col">
              {props?.withdrawDetails?.Result.map((dataItem: any) => {
                const {
                  IsNeedToWithdraw,
                  RecurringAmount,
                  WithdrawFee,
                  EntityName,
                  ServiceType,
                  EntityId,
                } = dataItem;
                return (
                  <>
                    <div className="p-4 pb-0 pb-sm-4">
                      <h6 className="card-title bz-fw-600 bz-article">
                        {EntityName} <small>({ServiceType})</small>
                      </h6>
                      <div className="">
                        <div className="bz-row no-gutters mb-sm-3">
                          <div className="form-group col-12 bz-payment-profile-options">
                            <Checkbox
                              value={IsNeedToWithdraw}
                              onChange={(e) =>
                                props?.handleWithdrawChange(
                                  e.value,
                                  "IsNeedToWithdraw",
                                  EntityId
                                )
                              }
                              label={"Do you want to withdraw ?"}
                            />
                          </div>
                          <div className="form-group col-12 bz-payment-profile-options">
                            <div className="bzc-provider-name">
                              <label className="mb-2 d-inline-block k-label">
                                Recurring Amount
                              </label>
                              <div className="bzc-time-sec">
                                <NumericTextBox
                                  id={`RecurringAmount${EntityId}`}
                                  name={`RecurringAmount${EntityId}`}
                                  min={0}
                                  format={"c2"}
                                  className="k-dropdown-width-100"
                                  value={RecurringAmount}
                                  disabled={true}
                                  onChange={(e) =>
                                    props?.handleWithdrawChange(
                                      e.target.value,
                                      "RecurringAmount",
                                      EntityId
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-12 bz-payment-profile-options">
                            <div className="bzc-provider-name">
                              <label className="mb-2 d-inline-block k-label">
                                Withdraw Fee
                              </label>
                              <div className="bzc-time-sec">
                                <NumericTextBox
                                  id={`WithdrawFee`}
                                  name={`WithdrawFee`}
                                  min={0}
                                  format={"c2"}
                                  className="k-dropdown-width-100"
                                  value={WithdrawFee}
                                  disabled={true}
                                  onChange={(e) =>
                                    props?.handleWithdrawChange(
                                      e.target.value,
                                      "WithdrawFee",
                                      EntityId
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="col-sm-6 col-lg-5 bzc-border-left position-relative">
              <div className="p-4 pt-0 pt-sm-4 h-100">
                <div className={"bz-row no-gutters"}>
                  <div className="form-group col-12 bz-payment-profile-options">
                    <div className="bzc-provider-name">
                      <label className="mb-2 d-inline-block k-label">
                        Total Down Payment
                      </label>
                      <div className="bzc-time-sec">
                        <NumericTextBox
                          id={`TotalDownPayment`}
                          name={`TotalDownPayment`}
                          min={0}
                          format={"c2"}
                          className="k-dropdown-width-100"
                          value={props?.withdrawDetails?.TotalDownPayment}
                          disabled={true}
                          onChange={(e) =>
                            props?.handleWithdrawChange(
                              e.target.value,
                              "TotalDownPayment",
                              0
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {props?.withdrawDetails?.TotalDownPayment > 0 ?  <><div className="form-group col-12 pr-3 bz-payment-profile-options mb-1">
                    <div className="bzc-provider-name">
                      <label className="mb-2 d-inline-block k-label">
                        Payment Profile
                      </label>
                      <div className="">
                        <DropDownList
                          id={`PaymentGatewayProfileId`}
                          name={`PaymentGatewayProfileId`}
                          data={props?.paymentProfiles}
                          textField="MaskedCCNumber"
                          valueRender={(
                            el: React.ReactElement<HTMLSpanElement>,
                            value: any
                          ) => props?.valueRender(el, value)}
                          itemRender={props?.itemRender}
                          onChange={(e) => {
                            props?.handleWithdrawChange(
                              e.value,
                              "paymentProfile",
                              0
                            );
                          }}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    {props?.showErrorMessage(
                      "PaymentGatewayProfileId",
                      "Payment Profile"
                    )}
                  </div> <div className="addNewBtn mb-3">
                    <DropDownButton
                      text="Add New"
                      onItemClick={(e: any) => onCardOrBankAdd(e)}
                      items={PaymentMethodItems.filter((i:any)=>i?.visible===true)}
                      look="flat"
                      className="btn btn-link add-new-btn ml-0"
                    />
                  </div></>:null}
                  <div className="form-group col-12 pr-3 bz-payment-profile-options mb-0">
                    <div className="bzc-provider-name">
                      <label className="mb-2 d-inline-block k-label">
                        Comments
                      </label>
                      <div className="">
                        <textarea
                          className="form-control"
                          onChange={(e) =>
                            props?.handleWithdrawChange(
                              e.target.value,
                              "Comments",
                              0
                            )
                          }
                        ></textarea>
                      </div>
                    </div>
                    {props?.showErrorMessage("Comments", "Comments")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 mt-2">
          <div className="">
            <BuzopsButton
              label={"Withdraw"}
              className="ml-0"
              loading={props?.btnLoading}
              onClick={() => props?.handleSubmitWithdraw()}
            />
          </div>
        </div>
      </div>
      {showPaymentDialog && PaymethodDialog()}
    </>
  );
};

export default PlanWithdrawFreeze;
