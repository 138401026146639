import { NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { Notification as Notify } from "@progress/kendo-react-notification";
import withNotification from "components/Hoc/withNotification";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { CurrencyFormat } from "utils";
import FailedTransactionsGrid from "../FailedTransactionsGrid";
import ActivateSuspendedSummary from "./ActivateSuspendedSummary";
import PlanAddonActivate from "./PlanAddonActivate";
import { CreditsErrorMessage } from "utils/form-utils";

const ActivateSuspended = (props: any) => {
  const [activationDetails, setActivationDetails] = useState<any>({});
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [paymentProfiles, setPaymentProfiles] = useState<any>([]);
  const [pageType, setPageType] = useState("plan");
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const service = new TenantService();

  useEffect(() => {
    fetchAllApis();
  }, []);


  const fetchAllApis = async () => {
    setLoading(true);
    await fetchPlanSummary();
    await fetchPaymentProfiles(props?.userData?.MainUserId);
    setLoading(false);
  };
  const refreshPaymentProfiles = () => {
    fetchPaymentProfiles(props?.userData?.MainUserId)
  }
  async function fetchPaymentProfiles(userMemberId: any) {
    const result = await service.paymentProfile(userMemberId);
    setPaymentProfiles(result || []);
  }

  const fetchPlanSummary = async () => {
    const req = {
      PageNumber: 0,
      PageSize: 32767,
      UserMemberId: props?.userData?.UserMemberId,
      MemberPlanId: props?.selectedPlanId,
    };
    const res = await service?.getMemberPlanSummary(req);
    setSelectedPlan(res);
    await fetchActivationDetails(res?.UserMemberId);
  };

  const fetchActivationDetails = async (UserMemberId: string) => {
    const MemberPlanId = props.selectedPlanId;
    const req = {
      MemberPlanId: MemberPlanId,
      UserMemberId: UserMemberId,
    };

    const result = await service.GetPlanActivationDetails(req);
    setActivationDetails(result);
  };


  const selectionChange = (event: any, EntityId: number) => {
    const dataItem = JSON.parse(JSON.stringify(activationDetails));
    let updateDetails = dataItem;
    if (EntityId === 0) {
      const originalDup = dataItem?.FailedPayments;
      const original = originalDup.map((item: any) => {
        if (
          item?.PaymentGatewayTransactionHistoryId ===
          event?.dataItem?.PaymentGatewayTransactionHistoryId
        ) {
          item.CanWaive = !event.dataItem.CanWaive;
        }
        return item;
      });
      updateDetails = {
        ...dataItem,
        FailedPayments: original,
      };
    } else {
      const originalDup = dataItem?.Subscriptions;

      const original = originalDup.map((item: any) => {
        if (item?.SubscriptionId === EntityId) {
          const failedTransc = item?.FailedPayments.map((childItem: any) => {
            if (
              childItem?.PaymentGatewayTransactionHistoryId ===
              event?.dataItem?.PaymentGatewayTransactionHistoryId
            ) {
              childItem.CanWaive = !event.dataItem.CanWaive;
            }
            return { ...childItem };
          });
          return { ...item, FailedPayments: failedTransc };
        } else {
          return { ...item };
        }
      });

      updateDetails = {
        ...dataItem,
        Subscriptions: original,
      };
    }

    setActivationDetails(updateDetails);
  };

  const handleNext = async () => {
    setBtnLoading(true);
    const result = await service.UpdatePlanActivation(
      activationDetails
    );
    setBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPageType("final");
      setActivationDetails(result?.Item);
    }
  };

  const showErrorMessage = (key: any, mainKey: any, type = "freeze") => {
    if (
      key === "PaymentGatewayProfileId" &&
      (!activationDetails[key] || activationDetails[key] === 0)
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    } else if (
      key !== "PaymentGatewayProfileId" &&
      (!activationDetails[key] || activationDetails[key].length <= 0)
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    }
    return <></>;
  };

  const handleValidation = () => {
    if (activationDetails.TotalAmount > 0 && (
      !activationDetails?.PaymentGatewayPaymentProfileId ||
      activationDetails?.PaymentGatewayPaymentProfileId === 0)
    ) {
      return false;
    }else  if(activationDetails?.CardTypeId === 8 && activationDetails?.TotalAmount > activationDetails?.Credit){
      const errorMsg = CreditsErrorMessage
      props?.handleNotificationMessage(errorMsg, "error");
      return false
    }
    return true;
  };

  const handleSubmitActivation = async () => {
    const check = handleValidation();
    if (check) {
      setBtnLoading(true);
      const result = await service.CompletePlanActivation(activationDetails);
      setBtnLoading(false);
      if (result?.ResponseCode === 100) {
        props?.handleNotificationMessage(result?.SuccessMessage, 'success');
        setPageType("plan");
        await fetchAllApis();
        props?.onActivate?.()
      } else {
        const errorMsg =
          result?.ErrorMessages.length > 0
            ? result?.ErrorMessages[0]
            : "Error in Activating Membership";
        props?.handleNotificationMessage(errorMsg, 'error');
      }
    }
  };
  const handleChange = async (value: any, name: string, entityId: number) => {
    const data = JSON.parse(JSON.stringify(activationDetails));
    let activationDet = data;
    if (name === "paymentProfile") {
      const PaymentGatewayPaymentProfileID =
        value?.PaymentGatewayPaymentProfileID;
      activationDet = {
        ...data,
        PaymentGatewayPaymentProfileId: PaymentGatewayPaymentProfileID,
        CardTypeId:value?.CardTypeId,
        Credit:value?.Credit
      };
    } else if (entityId === 0) {
      activationDet = {
        ...data,
        [name]: value,
      };
    } else if (entityId > 0) {
      const resultItems = activationDetails?.Subscriptions?.map((i: any) => {
        if (i?.SubscriptionId === entityId) {
          return { ...i, [name]: value };
        } else {
          return { ...i };
        }
      });
      activationDet = {
        ...data,
        Subscriptions: resultItems,
      };
    }
    setActivationDetails(activationDet);
  };
  if (loading) {
    return <BuzopsLoader type="list" />
  }
  if (!activationDetails) {
    return <div className="bz-norecords-available">{"No Data Found"}</div>;
  }
  const {
    Subscriptions,
    PlanName,
    MonthlyRate,
    ProratedAmount,
    FailedPayments,
    PenaltyAmount,
    DurationType
  } = activationDetails;
  return (
    <>
      <div className="suspend-container">
        <h5 className="mb-3 bz_fs_1_5">Activation Details</h5>
        <hr />
        <div className="bz-subsection-row bz-plan-overview bz-addon-cancellation">
          <div className="left-section">
            {pageType === "final" && (
              <ActivateSuspendedSummary
                activationDetails={activationDetails}
                selectedPlan={selectedPlan}
                userData={props?.userData}
                paymentProfiles={paymentProfiles}
                valueRender={(
                  el: React.ReactElement<HTMLSpanElement>,
                  value: any
                ) => PaymentValueRender(el, value, value)}
                itemRender={PaymentItemRender}
                showErrorMessage={(key: any, mainKey: any) =>
                  showErrorMessage(key, mainKey)
                }
                handleChange={(val: any, name: string, entityId: number) =>
                  handleChange(val, name, entityId)
                }
                refreshPaymentProfiles={refreshPaymentProfiles}
                canAddCardOrBank={props?.canAddCardOrBank}
              />
            )}
            {pageType === "plan" && (
              <>
                <h5 className="mb-3 pt-3 bz_fs_1_5">Membership Details</h5>
                <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile">
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">Membership Name</div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">{PlanName}</div>
                  </div>

                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">
                     {DurationType === 3 ? "Rate":"Monthly Rate"}
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      {CurrencyFormat(MonthlyRate)}
                    </div>
                  </div>
                  {ProratedAmount > 0 && (
                    <div className="bz-content-text-row">
                      <div className="bz-content-title text-right">
                        {DurationType === 3 ? "Prorated Rate":"Prorated Monthly Rate"}
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        {CurrencyFormat(ProratedAmount)}
                        <Switch
                          className="ms-3"
                          name="Waive"
                          onChange={(e) =>
                            handleChange(e.target.value, "CanSkipProratedAmount", 0)
                          }
                        />
                        <label className={"switch-label"}>{"Skip prorated amount"}</label>
                      </div> 
                    </div>
                  )}
                  <div className="bz-content-text-row bz-notify-row">
                    <div className="bz-content-title text-right"></div>
                    <span className="bz-colon text-center"></span>
                    <div className="bz-content-body">
                      <div className="bz-plan-note">
                        <p className="mb-3 mb-md-0">
                          <Notify key="info" type={{ style: "info" }}>
                            {
                              "System will extend the plan end date since plan was in Suspended status"
                            }
                          </Notify>
                        </p>
                      </div>
                    </div>
                  </div>
                  {FailedPayments?.length > 0 && pageType === "plan" && (
                    <FailedTransactionsGrid
                      details={activationDetails?.FailedPayments}
                      selectionChange={(e: any, id: number) =>
                        selectionChange(e, id)
                      }
                      EntityId={0}
                    />
                  )}
                </div>
                {ProratedAmount > 0 && (
                  <>
                    <div className="bz-mb-2_5rem">
                      <div className="bz-content-text-row">
                        <div className="bz-content-title text-right">Penalty</div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          <NumericTextBox
                            id={`PenaltyAmount`}
                            name={`PenaltyAmount`}
                            min={0}
                            format={"c2"}
                            className="k-dropdown-width-100"
                            value={PenaltyAmount}
                            onChange={(e) =>
                              handleChange(e.target.value, "PenaltyAmount", 0)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}


                {Subscriptions?.length > 0 && (
                  <>
                    <h5 className="mb-3 pt-3 bz_fs_1_5">Add-on Details</h5>
                    <PlanAddonActivate
                      activationDetails={activationDetails}
                      userData={props?.userData}
                      showErrorMessage={(key: any, mainKey: any) =>
                        showErrorMessage(key, mainKey)
                      }
                      selectionChange={(e: any, id: number) =>
                        selectionChange(e, id)
                      }
                      handleChange={(val: any, name: string, entityId: number) =>
                        handleChange(val, name, entityId)
                      }
                    />
                  </>
                )}
              </>
            )}
            <div className="bz-mt-2rem bz-mb-2_5rem">
              {pageType === "plan" && (
                <>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right"></div>
                    <span className="bz-colon text-center"></span>
                    <div className="bz-content-body">
                      <BuzopsButton
                        label={"Next"}
                        loading={btnLoading}
                        onClick={() => handleNext()}
                      />
                    </div>
                  </div>
                </>
              )}

              {pageType === "final" && (
                <>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right"></div>
                    <span className="bz-colon text-center"></span>
                    <div className="bz-content-body">
                      <BuzopsButton
                        label={"Previous"}
                        onClick={() => setPageType("plan")}
                      />
                      <BuzopsButton
                        label={"Complete"}
                        loading={btnLoading}
                        onClick={() => handleSubmitActivation()}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withNotification(ActivateSuspended);
