import {
  DropDownList,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Switch } from "@progress/kendo-react-inputs";
import { Card } from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import Location from "components/Location/Location";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  CurrencyFormat,
  getDayFormat,
  getFullImageUrl,
  getMonthFormat,
  getPaymentOptionById,
  getTimeFormat,
  getWeekdayFormat,
  getYearFormat,
} from "utils";
const dataLimit = 2;

const PurchaseClassPanel = (props: any) => {
  const [expand, setExpand] = useState(true);
  const [limit, setLimit] = React.useState(dataLimit);

  useEffect(() => {
    if (props?.packageListToUse?.length > 0) {
      props?.handleAutoselectPackage();
    }
  }, []);

  const itemClassValueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any,
    selectedItem: any
  ) => {
    let children: any = [];
    if (selectedItem) {
      const value = selectedItem.Name;

      children = [
        <div className="bz-packageSelect-card">
          <h6 className="mb-0">{value}</h6>

        </div>,
      ];
    } else {
      children = [<span>Please Select</span>];
    }
    return React.cloneElement(element, { ...element.props }, children);
  };
  const itemClassRender = (li: any, itemProps: any, selectedItem: any) => {
    let selected = false;
    if (
      selectedItem?.PackageInstanceId === itemProps.dataItem?.PackageInstanceId
    ) {
      selected = true;
    }
    let ExpireFrom = itemProps.dataItem.ExpireFrom
      ? moment(itemProps.dataItem.ExpireFrom).format("L")
      : "NA";
    let ExpireOn = itemProps.dataItem.ExpireOn
      ? moment(itemProps.dataItem.ExpireOn).format("L")
      : "NA";

    const value = itemProps.dataItem.Name;
    const schedulesToUse = itemProps.dataItem.SessionType === 2
      ? "Unlimited"
      :
      `${itemProps.dataItem.SchedulesUsed} of ${itemProps.dataItem.SchedulesToUse + itemProps.dataItem.SchedulesUsed}`;
    const itemChildren = (
      <div
        className={`bz-packageSelect-card ${selected ? "selected-package" : ""
          }`}
      >
        <h6 className="mb-0">{value}</h6>
        <div className="pack-details">
          <p className="mb-0">
            <small>{`Available Appointments: ${schedulesToUse}`}</small>
          </p>
          <p className="mb-0">
            <small>{`Redeem: ${ExpireFrom} to ${ExpireOn}`}</small>
          </p>
        </div>
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const NewMyItemRender = (itemProps: any) => {
    let item = itemProps.dataItem;
    return item?.values?.length > 0 ? (
      <>
        <div className="bz-row px-0">
          <div className="class-title mt-0 mb-2 text-capitalize">
            {`${item?.className} (${item?.values?.length})`}
          </div>
        </div>

        {item?.values?.map((item1: any, indx: number) => {
          const Attributes =
            item1?.Attributes && typeof item1?.Attributes === "string"
              ? JSON.parse(item1?.Attributes)
              : item1?.Attributes;
          return (
            <>
              {item1?.PackagesList && item1?.PackagesList?.length > 0 && (
                <div className="d-flex justify-content-end">
                  {/* <MultiSelect
                id={`packages${item?.classId}`}
                name={`packages${item?.classId}`}
                value={item?.SelectedPackage}
                data={item?.PackagesList}
                placeholder="Redeem Your Packages"
                itemRender={(li: any, itemProps: ListItemProps) =>
                  itemClassRender(li, itemProps, item?.SelectedPackage)
                }
                onChange={(e: any) =>
                  props?.handlePackageSelectionForClass(e?.value, item)
                }
                textField="Name"
                dataItemKey="PackageInstanceId"
                filterable={true}
                allowCustom={false}
                autoClose={false}
                className="bz-multiSelect"
              /> */}
                  <DropDownList
                    id={`packages${item1?.ClassSessionId}`}
                    name={`packages${item1?.ClassSessionId}`}
                    // value={item1?.SelectedPackage}
                    data={item1?.PackagesList}
                    itemRender={(li: any, itemProps: ListItemProps) =>
                      itemClassRender(li, itemProps, item1?.SelectedPackage)
                    }
                    onChange={(e: any) =>
                      props?.handlePackageSelectionForClass(e?.value, item1)
                    }
                    valueRender={(
                      el: React.ReactElement<HTMLSpanElement>,
                      value: any
                    ) =>
                      itemClassValueRender(el, value, item1?.SelectedPackage)
                    }
                    textField="Name"
                    style={{ width: "35%" }}
                    dataItemKey="PackageInstanceId"
                    className="customDropdown packageSelectionDropdown "
                  />
                </div>
              )}
              {props?.UserMemberId && props?.PackagesData?.length > 0 && (
                <div className="bz-row px-0">
                  <small className="bz-ol-muted my-2 my-sm-0">{`Looks like you are ${item1?.PackagesList?.length > 0 ? "" : "not"
                    } eligible to redeem the below Group with your purchased packages.Please ${item1?.PackagesList?.length > 0 ? "select" : "purchase"
                    } package to reedem`}</small>
                </div>
              )}

              <div className="bz-row px-0">
                <div className="w-100">
                  <div className="card bzc-sessions-list-card-new  bz-classes-checkout bz-default-cursor mb-3  selectedgroupclasses">
                    <div className="card-body">
                      <div
                        className={
                          item1?.SelectedPackage
                            ? `bzc-sessions-list-row-new bzc-sessions-list-row-useCredit`
                            : `bzc-sessions-list-row-new`
                        }
                      >
                        <div className="section-1">
                          <div className="bzc-date-sec align-items-center">
                            <i
                              className="far fa-calendar-alt"
                              style={{ width: "15px" }}
                            ></i>
                            {getWeekdayFormat(item1?.ClassSessionStartDate)}
                            ,&nbsp;
                            {getDayFormat(item1?.ClassSessionStartDate)}&nbsp;
                            {getMonthFormat(item1?.ClassSessionStartDate)}&nbsp;
                            {getYearFormat(item1?.ClassSessionStartDate)}
                            {item1?.Attributes !== "" && (
                              <Location
                                Attributes={item1?.Attributes}
                                showDetails={props?.staffPage}
                                VirtualMeetingLink={item1?.VirtualMeetingLink}
                                clientPhnNum={item1?.Phone}
                              />
                            )}
                          </div>
                          <div className="bzc-time-sec text-uppercase">
                            <span>
                              <i className="far fa-clock"></i>
                              {getTimeFormat(
                                item1?.ClassSessionStartDate
                              )} - {getTimeFormat(item1?.ClassSessionEndTime)}
                            </span>
                          </div>
                        </div>
                        <div className="section-2">
                          <div className="bz-main-provider">
                            <div className="bz-provider-img">
                              <img
                                src={
                                  getFullImageUrl(item?.ProviderPhoto) ||
                                  "/images/user.png"
                                }
                                alt=""
                              />
                            </div>
                            <div className="bz-provider-name">
                              <h6>{item1?.TrainerName}</h6>
                              <span className="bz-provider">Provider</span>
                            </div>
                          </div>
                        </div>
                        <div className="section-3">
                          <div className="bz-main-provider">
                            {/* <div className="bz-attendees-icon">
                          <img src={attendees_icon} alt="" />
                        </div> */}
                            <div>
                              <h6>
                                Attendees: {item1?.BookedSlot} of {item1?.Slot}
                              </h6>
                              {item1?.CanBookAsWaitlist ? <span className="badge bz-badge-warning mx-0">Waitlisted</span> : null}
                            </div>
                          </div>
                        </div>
                        <div className="bzc-col-price">
                          <div>
                            <div className="bzc-session-price bzc-session-price-new">
                              {item1?.ClassType === 1 && <span> </span>}
                              <h5
                                className="mb-0"
                                style={{ display: "inline-flex" }}
                              >
                                {item1?.ClassType === 1 ? (
                                  CurrencyFormat(item1?.Cost)
                                ) : (
                                  <span className="bzo-status-success">
                                    {Attributes?.NonPaidLabel || "Non-Paid"}
                                  </span>
                                )}
                                <BuzopsTooltip
                                  html={
                                    <span
                                      title={getPaymentOptionById(
                                        item1?.PaymentOption
                                      )}
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5rem",
                                      }}
                                    >
                                      <a>
                                        <i
                                          style={{ fontSize: "16px" }}
                                          className="fa fa-credit-card"
                                        ></i>
                                      </a>
                                    </span>
                                  }
                                />
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="bzc-col-price">
                          <span
                            className="bz-close-icon2"
                            onClick={() => {
                              props?.handleRemoveSession(item1);
                            }}
                          >
                            <i className="fas fa-times mr-0"></i>
                          </span>
                        </div>
                      </div>
                      <div className="bz-checkout-switch-btns">
                        {item1?.SelectedPackage && item1?.PackagesList?.length > 0 && (
                          <div className="bzc-col-price redeem-switch">
                            <div className="bzc-session-price">
                              <small className="d-inline-block">Redeem via Package</small>
                              <span className="bz-toggle-btn">
                                <Switch
                                  name={`UseCredit${item1?.ClassSessionId}`}
                                  onChange={(e: any) =>
                                    props.handleUseCredit(e?.value, item1)
                                  }
                                  checked={props.checkUseCredit(item1)}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                        {props?.ComplimentaryPermission &&
                          item1?.Cost > 0 &&
                          Object.keys(props?.clientFormValues).length > 0 && (
                            <div className="bzc-col-price complimentary-switch">
                              <div className="bzc-session-price">
                                <small className="d-inline-block">
                                  Redeem via Complimentary
                                </small>
                                <span className="bz-toggle-btn">
                                  <Switch
                                    name={`Complimentary${item1?.ClassSessionId}`}
                                    onChange={(e: any) =>
                                      props.handleComplimentary(
                                        e?.value,
                                        item1
                                      )
                                    }
                                    checked={item1?.Complimentary}
                                  />
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </>
    ) : (
      <></>
    );
  };

  function findBillingPeriod(ClassSessionStartDate: any, BillingPeriods: any) {
    const sessionDate = new Date(ClassSessionStartDate);

    return BillingPeriods.find((period: any) => {
      const expireFrom = new Date(period.ExpireFrom);
      const expireOn = new Date(period.ExpireOn);
      expireOn.setHours(23);
      expireOn.setMinutes(59);
      expireOn.setSeconds(0);

      return sessionDate >= expireFrom && sessionDate <= expireOn;
    });
  }

  console.log('propsinPurchaseClass',props);

  const PackageRedeemStatusCard = (propValues: any) => {
    const classSessionStartDate = propValues?.session[0]?.values[0]?.ClassSessionStartDate;
    return (
      <>
        <div className="reedem-summary">
          <div>
            <h6>{`Available Packages (${propValues?.PackagesData?.length})`}</h6>
          </div>
        </div>
        <div className="package-redeem-card-blk">

          <div className="package-redeem-card row">
            {propValues?.PackagesData?.length > 0 ? (
              <>
                {propValues?.PackagesData?.slice(0, limit)?.map((item: any) => {
                 const billingPeriod = findBillingPeriod(classSessionStartDate, item.BillingPeriods);
                 const IsPastDate = moment(classSessionStartDate).isBefore(moment(), 'day');
                 
                 const { ExpireFrom, ExpireOn, SchedulesToUse: SchedulesRemaining } = IsPastDate && billingPeriod !== undefined
                   ? billingPeriod
                   : item;
                  const schedulesToUse = item.SessionType === 2
                    ? "Unlimited"
                    : `${item?.SchedulesUsed} of ${SchedulesRemaining + item?.SchedulesUsed}`;
                  console.log('schedulesRemaining',schedulesToUse);
                  console.log('SchedulesToUse',item?.SchedulesToUse);
                  
                  
                  return (
                    <>
                      <div className="package-item">
                        <div className="package-details bz-payment-options">
                          <div className="package-name">
                            <h5 className="primary-heading">{item?.Name}</h5>
                          </div>
                          <div className="package-expiry-schedules">
                            <small className="package-schedules ">{`Available Packages: ${schedulesToUse}`}</small>
                            {/* <span> {"|"}</span> */}
                            <small className="package-expiry">
                              {`Redeem: ${ExpireFrom === null
                                ? "N/A"
                                : moment(ExpireFrom).format("L")
                                } to ${ExpireOn === null
                                  ? "N/A"
                                  : moment(ExpireOn).format("L")
                                }`}
                            </small>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                {propValues?.PackagesData?.length > dataLimit && (
                  <div className="text-center">
                    <span className="btn btn-link  align-baseline bz-cursor-pointer p-0">
                      {propValues?.PackagesData?.length > limit ? (
                        <span
                          key="down"
                          onClick={() => setLimit((val) => val + dataLimit)}
                        >
                          View More
                          <i className="fas fa-caret-down ml-1"></i>
                        </span>
                      ) : (
                        <span key="up" onClick={() => setLimit(dataLimit)}>
                          View Less
                          <i className="fas fa-caret-up ml-1"></i>
                        </span>
                      )}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}

          </div>
        </div>
      </>
    );
  };

  const InitialItemRender = (propValues: any) => {
    return (
      <>
        {propValues?.UserMemberId &&
          propValues?.PackagesData?.length > 0 &&
          PackageRedeemStatusCard(propValues)}
        <div className="serviceDetailsPanel-grid">
          <div className="section-1">
            <h6>
              {`Total Group Selected : ${propValues?.totalClassSessions}`}
              <span
                className="btn btn-link text-decoration-underline ml-2"
                onClick={() => setExpand(!expand)}
              >
                {expand ? "Less View" : "Detailed View"}
              </span>
            </h6>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Card
        className={
          "bzc-purchase-class-list-view bz-purchase-checkout-list bz-multiplepackage-select"
        }
      >
        {!expand ? (
          InitialItemRender(props)
        ) : (
          <>
            {InitialItemRender(props)}
            <div style={{ maxHeight: "400px", overflow: "auto" }}>
              <ListView
                data={props.session}
                item={(props) => NewMyItemRender(props)}
                className="mt-2"
              />

            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default PurchaseClassPanel;
