/* eslint-disable jsx-a11y/alt-text */
import { DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import React, { useEffect, useState } from "react";
import { CurrencyFormat, checkIfUserCanAddBankAccount } from "utils";
import { GetLocalStore } from "utils/storage";
import AddonsInActivationOverview from "./AddonsInActivationOverview";
const ActivateSuspendedSummary = (props: any) => {
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [calculatedTotalAmount,setCalculatedTotalAmount]=useState(0);
  const PaymentMethodItems = [
    {
      iconClass: "fa fa-credit-card pr-1",
      text: "Add credit card",
      key: "addCard",
      visible:true,
    },
    {
      iconClass: "fa fa-university pr-1",
      text: "Add bank account",
      key: "addBank",
      visible: checkIfUserCanAddBankAccount()
    },
  ];
  const {
    PlanName,
    ProratedAmount,
    MonthlyRate,
    PenaltyAmount,
    DownPayment,
    FailedTransactionAmount,
    WaivedFailedTransactionAmount,
    FailedTransactionAmountToCollect,
    Subscriptions,
    TotalAmount,
    DurationType,
  } = props?.activationDetails;
  const onCardOrBankAdd = (e: any, memberId: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };
  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog)
  }
  const handleSuccessClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog)
    props?.refreshPaymentProfiles()
    // fetchPaymentProfiles(props?.UserMemberId)
  }

  const calculateTotalAmount=()=>{
    if (props?.activationDetails?.CanSkipProratedAmount &&  props?.activationDetails?.Subscriptions?.CanSkipProratedAmount) {
      setCalculatedTotalAmount(TotalAmount-(ProratedAmount + Subscriptions.ProratedAmount));
    } else if (props?.activationDetails?.CanSkipProratedAmount) {
      setCalculatedTotalAmount(TotalAmount-ProratedAmount);
    } else if (props?.activationDetails?.Subscriptions?.CanSkipProratedAmount) {
      setCalculatedTotalAmount(TotalAmount-Subscriptions.ProratedAmount);
    }else{
      setCalculatedTotalAmount(TotalAmount);
    }
  }
  
  useEffect(() => {
    calculateTotalAmount();
  }, [props]);

  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${checkIfUserCanAddBankAccount() ? "Add Bank or Card": "Add Card"}`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation staffPage={true} addNewItem={addNewItem} handleClosePaymethodDialog={handleClosePaymethodDialog} handleSuccessClosePaymethodDialog={handleSuccessClosePaymethodDialog} UserMemberId={props?.userData?.UserMemberId} />
        </Dialog>
      </>
    );
  };
  return (
    <>
    <h5 className="mb-3 pt-3 bz_fs_1_5">Membership Details</h5>
      <div className="bz-mb-2_5rem">
        {/* <div className="bz-mt-2rem">
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Client Name</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <div className="d-flex align-items-center">
                {MemberType === 0 ? (
                  <img src={PrimaryMember} className="bz-member-icon" />
                ) : (
                  <img src={SubMember} className="bz-member-icon" />
                )}
                {UserName}
              </div>
            </div>
          </div>
        </div> */}
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Membership Name</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{PlanName}</div>
        </div>

        <div className="bz-content-text-row">
          <div className="bz-content-title text-right"> {DurationType === 3 ? "Rate":"Monthly Rate"}</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(MonthlyRate)}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
          {DurationType === 3 ? "Prorated Rate":"Prorated Monthly Rate"}
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {CurrencyFormat(ProratedAmount)} {props?.activationDetails.CanSkipProratedAmount && (<label className={"switch-label ms-4"}>{"Skiped prorated amount"}</label>)}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Penalty</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(PenaltyAmount)}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
            Total Failed Transaction Amount
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {CurrencyFormat(FailedTransactionAmount)}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
            Waived Failed Transaction Amount
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {CurrencyFormat(WaivedFailedTransactionAmount)}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
            Failed Transaction Amount To Collect
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {CurrencyFormat(FailedTransactionAmountToCollect)}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
            Amount to Collect for Membership
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(props?.activationDetails.CanSkipProratedAmount ? DownPayment-ProratedAmount : DownPayment)}</div>
        </div>
      </div>
      <hr />
      {Subscriptions?.length >0 && <h5 className="mb-3 pt-3 bz_fs_1_5">Add-on Details</h5>}
      {Subscriptions?.map((addonItem: any, index: number) => {
        return (
          <>
            <AddonsInActivationOverview addonItem={addonItem} />
          </>
        );
      })}
      <div className="bz-mt-2rem bz-mb-2_5rem">
        {TotalAmount > 0 && (
          <div className="bz-content-text-row align-items-start">
            <div className="bz-content-title text-right">Payment Profile</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <div className="d-flex">
              <div>
              <DropDownList
                id={`PaymentGatewayPaymentProfileId`}
                name={`PaymentGatewayPaymentProfileId`}
                data={props?.paymentProfiles}
                textField="MaskedCCNumber"
                // dataItemKey="PaymentProfileID"
                valueRender={(
                  el: React.ReactElement<HTMLSpanElement>,
                  value: any
                ) => props?.valueRender(el, value, value)}
                itemRender={props?.itemRender}
                onChange={(e) => {
                  props?.handleChange(e.value, "paymentProfile");
                }}
                style={{ width: "100%" }}
              />
              {props?.showErrorMessage(
                "PaymentGatewayPaymentProfileId",
                "Payment Profile"
              )}
              </div>
              {
                props?.canAddCardOrBank &&
                <div className="addNewBtn pl-1">
                  <DropDownButton
                    text="Add New"
                    onItemClick={(e: any) => onCardOrBankAdd(e, props?.userData?.UserMemberId)}
                    items={PaymentMethodItems.filter((i:any)=>i?.visible===true)}
                    look="flat"
                    className="btn btn-link add-new-btn"
                  />
                </div>
              }
              </div>

            </div>
          </div>
        )}
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
            Total Amount to Collect for Re-activation
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(calculatedTotalAmount)}</div>
        </div>
      </div>
      {showPaymentDialog && PaymethodDialog()}
    </>
  );
};

export default ActivateSuspendedSummary;
