import { Switch } from "@progress/kendo-react-inputs";
import React from "react";
import { Notification as Notify } from "@progress/kendo-react-notification";
import { CurrencyFormat } from "utils";
import FailedTransactionsGrid from "../FailedTransactionsGrid";
const PlanAddonActivate = (props: any) => {
  return (
    <>
      {props?.activationDetails?.Subscriptions?.map((dataItem: any) => {
        const {
          ServiceName,
          Charge,
          Continue,
          ProratedAmount,
          FrequencyDescription,
          NextRecurrence,
          SubscriptionId,
          FailedPayments,
        } = dataItem;
        return (
          <>
            <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile">
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">
                  Do you want to activate Addon ?
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <Switch
                    checked={Continue}
                    onChange={(e) =>
                      props?.handleChange(e.value, "Continue", SubscriptionId)
                    }
                  />
                </div>
              </div>
              {!Continue && (
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right"></div>
                  <span className="bz-colon text-center"></span>
                  <div className="bz-content-body">
                    <div className="bz-plan-note">
                      <p className="mb-0">
                        <Notify key="info" type={{ style: "info" }}>
                          {"This Addon will cancel today"}
                        </Notify>
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Add-on Name</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{ServiceName}</div>
              </div>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Amount</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{CurrencyFormat(Charge)}</div>
              </div>
              {Continue && (
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">
                    Prorated Amount
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {CurrencyFormat(ProratedAmount)}
                    {ProratedAmount>0 &&(
                      <>
                        <Switch
                          className="ms-3"
                          name="Waive"
                          onChange={(e) =>
                            props?.handleChange(e.target.value, "CanSkipProratedAmount", SubscriptionId)
                          }
                        />
                        <label className={"switch-label"}>{"Skip prorated amount"}</label>
                      </>
                    )}                   
                  </div>
                </div>
              )}
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Status</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{FrequencyDescription}</div>
              </div>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Frequency</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{FrequencyDescription}</div>
              </div>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">
                  Next Recurrence
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{NextRecurrence}</div>
              </div>
            </div>
            {FailedPayments?.length > 0 && (
              <FailedTransactionsGrid
                details={dataItem?.FailedPayments}
                selectionChange={(e: any, id: number) =>
                  props.selectionChange(e, SubscriptionId)
                }
                EntityId={SubscriptionId}
              />
            )}
            <hr className="bz-addons-hr" />
          </>
        );
      })}
    </>
  );
};

export default PlanAddonActivate;
