import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import BuzopsLoader from "generic-components/BuzopsLoader";
import ClientNameWithMemberType from "../ClientNameWithMemberType";
import BuzopsWindow from "generic-components/BuzopsWindow";
import AgreementView from "./AgreementView";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import WaiverAgreement from "./WaiverAgreement";
import { useAgreement } from "./useAgreement";
import UploadAgreement from "./UploadAgreement";
import BuzopsButton from "generic-components/BuzopsButton";
import { useApiClient } from "services/axios-service-utils";

const Agreements = (props: any) => {
  const {
    fetchAgreementDetails,
    agreementDetails,
    loading,
    AllClientsList,
    clientSubmitDetails,
    OpenWaiverAgreement,
    handleClientSelection,
    showWaiver,
    handleBack,
    currentagreementDetails,
    toggleView,
    togglePdfView,
    isUploadAgreementVisible,
    toggleUploadAgreement,
  } = useAgreement(props);

  const { axiosRequest } = useApiClient();
  const [rowLoadingStates, setRowLoadingStates] = useState<{ [key: string]: boolean }>({});

  if (loading.page) {
    return <BuzopsLoader type={"list"} />;
  }

  if (isUploadAgreementVisible) {
    return <UploadAgreement
            {...props}
            UserMemberId={clientSubmitDetails?.UserMemberId}
            toggleUploadAgreement={toggleUploadAgreement}
            AllClientsList={AllClientsList}
          />;
  }

  const handleShareMail = async (dataItem: any) => {
    const uniqueId = dataItem.MemberAgreementId; 
    setRowLoadingStates((prevState) => ({
      ...prevState,
      [uniqueId]: true,
    }));
    const req= {
        UserMemberId: props.userData.UserMemberId,
        AgreementId  : dataItem.MemberAgreementId,
        AgreementName: dataItem.AgreementName
      };

    await axiosRequest.post("Agreement/SendAgreement",req,{
      successCallBack:async(response:any) => {
        props?.handleNotificationMessage(
          `Email sent Successfully`,
          "success"
        );
        setRowLoadingStates((prevState) => ({
          ...prevState,
          [uniqueId]: false,
        }));
      },
      errorCallBack: (error: any) => {
        console.log(error);
        const errorMsg = "Error in sending Email";
        props?.handleNotificationMessage(errorMsg, "error");
        setRowLoadingStates((prevState) => ({
          ...prevState,
          [uniqueId]: false,
        }));
      }
    })
  };

  const actionscell = (propsData: any) => {
    const uniqueId = propsData.dataItem.MemberAgreementId;
    return (
      <td
        style={{ display: "flex", overflow: "visible", width: "auto" }}
        className="trnasctionsaction"
      >
        <BuzopsButton
          label={
            <>
              <i className="fa fa-share" style={{ marginRight: "5px" }}></i>
              {rowLoadingStates[uniqueId] ? "Sending..." : "Share"}
            </>
          }
          disabled={rowLoadingStates[uniqueId] || false} 
          onClick={async () =>
            handleShareMail(propsData.dataItem)
          }
          style={{
            height: "20px",
            margin: "5px",
            fontSize: "12px !important",
            minWidth: "92px",
          }}
        />
      </td>
    );
  };
  
  return (
    <div className={`agreement-parent`}>
     <div className="d-flex align-items-center justify-content-between mb-3">
     <h5 className="mb-0 bz_fs_1_5">Documents</h5>
     
      <div className="d-flex justify-content-end align-items-center">
      <Button
          primary
          onClick={() => toggleUploadAgreement(true)}
        >{`Upload`}</Button>
        {AllClientsList?.length > 1 ? <DropDownButton
          text="Sign Waiver"
          onItemClick={(e: any) => handleClientSelection(e)}
          items={AllClientsList}
          look="flat"
          className="btn btn-link add-new-btn"
        /> : <Button
          primary
          className="float-right mr-0"
          onClick={() => OpenWaiverAgreement()}
        >{`Sign Waiver`}</Button>}
      </div>
     </div>
     
      <Grid data={agreementDetails || []} className="bzc-grid bz-checkins-grid">
        <Column
          field="MemberName"
          title="Member Name"
          cell={({ dataItem }) => {
            return (
              <td>
                <ClientNameWithMemberType
                  memberType={dataItem?.MemberType}
                  clientName={dataItem?.MemberName}
                />
              </td>
            );
          }}
        />
        <Column
          field="AgreementName"
          title="Name"
          cell={({ dataItem }) => {
            return (
              <td>
                <span
                  className="bz-custom-link bz-cursor-pointer"
                  onClick={() => fetchAgreementDetails(dataItem, "view")}
                >
                  {dataItem?.DocumentName ?? dataItem?.AgreementName}
                </span>
              </td>
            );
          }}
        />
        <Column field="AgreementDate" title="Date" />
        <Column 
          field="Action" 
          title="Action" 
          width={150}
          cell={actionscell}
        />
        <Column field="IsSystemGenerated" title="Is Uploaded"
        cell={({ dataItem }) => {
          return (
            <td>
              <span>
                {dataItem?.IsSystemGenerated ? "Yes" : "No"}
              </span>
            </td>
          );
        }}
         />
        
      </Grid>
      {showWaiver && (
        <BuzopsWindow
          title={
            <>
              <div className={"buzops-window-title"}>
                {" "}
                <div>
                  {"Waiver Agreement"}
                  <br />
                  <small className="text-muted">{`Accept & Sign the Terms and Conditions of a waiver`}</small>
                </div>
              </div>
            </>
          }
          reduceWidth={window.innerWidth < 800 ? 10 : 430}
          visibility={showWaiver}
          key={showWaiver.toString()}
          onWindowClose={() => handleBack()}
          className="bz-agreement-window"
        >
          <WaiverAgreement
            {...props}
            UserMemberId={clientSubmitDetails?.UserMemberId}
            handleBack={handleBack}
          />
        </BuzopsWindow>
      )}
      {currentagreementDetails?.type === "view" && (
        <BuzopsWindow
          title={
            <>
              <div className={"buzops-window-title"}>{"Agreement"}</div>
            </>
          }
          reduceWidth={window.innerWidth < 800 ? 10 : 430}
          visibility={toggleView}
          key={toggleView.toString()}
          onWindowClose={() => togglePdfView()}
          className="bz-agreement-window"
        >
          <AgreementView
            Id={currentagreementDetails?.MemberAgreementId}
            Name={currentagreementDetails?.AgreementName}
            Type={currentagreementDetails?.type}
          />
        </BuzopsWindow>
      )}
      
    </div>
  );
};
export default Agreements;
