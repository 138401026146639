import React, { useRef } from "react";
import { Chip, ChipList, ChipProps } from "@progress/kendo-react-buttons";
import { CurrencyFormat } from "utils";
import moment from "moment";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomPopup } from "./OrderSummary";
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { BiInfoCircle } from "react-icons/bi";
import { PackageFrequencyEnum, SessionTypeEnum } from "utils/form-utils";
import { Error, Hint } from "@progress/kendo-react-labels";
import { debounce } from "utils/utils";

const dataLimit = 3;
const PurchasePackagePanel = (props: any) => {
  const debouncedRatePerSession = useRef(
    debounce((value: any) => props?.handlePackageReccurence("Schedules", value), 400)
  ).current;
  const TooltipContentTemplate = (propData: any) => {
    return (
      <div>
        <span className="">
          {`The pay later option is available
   only for purchases with a  start date
    within the next six months.`}
        </span>
      </div>
    );
  };

  const [limit, setLimit] = React.useState(dataLimit);
  let dropdownBillingData = [
    // {
    //   text: "First Day of Month",
    //   value: 1,
    // },
    {
      text: `${moment(props?.package?.StartDate).format("Do")} of Month`,
      value: 2,
    },
    {
      text: "Custom",
      value: 3,
    },
  ];
  if (!props?.staffPage) {
    dropdownBillingData = [
      {
        text: `${moment(props?.package?.StartDate).format("Do")} of Month`,
        value: 2,
      },
      {
        text: `${moment([
          moment(props?.package?.StartDate).year(),
          moment(props?.package?.StartDate).month(),
          props?.package?.BillingDay,
        ]).format("Do")} of Month`,
        value: 3,
      },
    ];
  }
  const filterClass = props?.package?.EntityMappings?.filter(
    (i: any) => i.ClassId === null
  );
  let AllClasses = false;
  if (filterClass?.length > 0) {
    AllClasses = true;
  }
  const BillingDaysArray = Array.from({ length: 28 }, (_, index) => index + 1);
  const proratedHintDay = moment([
    moment(props?.package?.StartDate).year(),
    moment(props?.package?.StartDate).month(),
    props?.package?.BillingDay,
  ]).format("Do");
  const notProratedHintDay = moment(props?.package?.StartDate).format("Do");
  const schedulesToUse =
    props?.package?.SessionType === 2 ? "Unlimited" : props?.package?.SessionType === 3 ? props?.package?.Schedules : props?.package?.OptedSchedules;
  const totalCost = props?.package?.SessionType === 3 ? props?.package?.IndividualSessionRate : props?.package?.DiscountedCost;
  const today1 = new Date();
  const threeMonths = new Date(today1);
  threeMonths.setMonth(threeMonths.getMonth() + 3);
  const sixMonths = new Date(today1);
  sixMonths.setMonth(sixMonths.getMonth() + 6);
  let MaxStartDate = sixMonths;
  if (
    props?.package?.Frequency === PackageFrequencyEnum.ONETIME &&
    props?.package?.ExpireOn === 8
  ) {
    const dateVal = new Date(props?.package?.ExpiryDate);
    dateVal.setDate(dateVal.getDate() - 1);
    MaxStartDate = dateVal;
  }
  let staffPage = props?.staffPage;
  let minSignUpDate = today1;
  if (props?.package?.Frequency === PackageFrequencyEnum.DAILY) {
    const mindateValue = moment(minSignUpDate)
      .subtract(props?.package?.RepeatFrequency - 1, "days")
      .format();
    minSignUpDate = new Date(mindateValue);
  } else if (props?.package?.Frequency === PackageFrequencyEnum.MONTHLY) {
    const mindateValue = moment(minSignUpDate).subtract(28, "days").format();
    minSignUpDate = new Date(mindateValue);
  }

  const BilledOnTooltip = (propData: any) => {
    const dataItem = propData?.dataItem;
    const proratedHintDay = moment([
      moment(dataItem?.StartDate).year(),
      moment(dataItem?.StartDate).month(),
      dataItem?.BillingDay,
    ]).format("Do");
    const notProratedHintDay = moment(dataItem?.StartDate).format("Do");

    if (
      dataItem?.BillingType === 3 &&
      dataItem?.BillingDay &&
      moment(dataItem?.StartDate).date() !== dataItem?.BillingDay &&
      dataItem?.RepeatFrequency === 1
    ) {
      return (
        <div>
          <span className="">
            {`Package billing amount will be prorated for any current charges until the ${proratedHintDay}
             of this month. From then on, Package billing amount will be 
             charged on the ${proratedHintDay} of every month`}
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="">
            {`Package billing amount will be charged on the ${notProratedHintDay} of 
            every month for any recurring fees or charges associated`}
          </span>
        </div>
      );
    }
  };

  let Type = props?.package?.Type;
  let TypeName;
  if (Type === 1) {
    TypeName = "Groups";
  } else if (Type === 2) {
    TypeName = "Private Appointments";
  } else if (Type === 4) {
    TypeName = "Semi-Private Appointments";
  }
  return (
    <>
      <div className={"mb-1 order-summary-class-details"}>
        <div className={"order-summary-class-name"}>
          {props?.package?.Name}{" "}
          <span className="d-block d-sm-inline">
            {`Available to Redeem : ${schedulesToUse}`}
          </span>
        </div>
        <span className={"order-summary-class-cost"}>
          {CurrencyFormat(totalCost)}
        </span>
      </div>
      {props?.package?.SessionType !== SessionTypeEnum.PRESET && (<>
        <small>{`You can redeem the package for the below ${TypeName}`}</small>
        <div className="bz-packages-tags-new">
          {AllClasses ? (
            <>
              <Chip
                type={"info"}
                disabled={true}
                text={"All Groups"}
                removable={false}
              />
            </>
          ) : (
            <>
              <ChipList
                key={props?.package?.EntityMappings?.length}
                style={{ width: "100%", flexWrap: "wrap" }}
                data={props?.package?.EntityMappings?.slice(0, limit)}
                chip={(props: ChipProps) => {
                  return (
                    <Chip
                      type="success"
                      disabled={true}
                      text={props?.dataItem?.Name}
                      removable={false}
                    />
                  );
                }}
              />
              {props?.package?.EntityMappings?.length > dataLimit && (
                <div className="text-center">
                  <span className="btn btn-link  align-baseline bz-cursor-pointer p-0">
                    {props?.package?.EntityMappings?.length > limit ? (
                      <span
                        key="down"
                        onClick={() => setLimit((val) => val + limit)}
                      >
                        View More
                        <i className="fas fa-caret-down ml-1"></i>
                      </span>
                    ) : (
                      <span key="up" onClick={() => setLimit(dataLimit)}>
                        View Less
                        <i className="fas fa-caret-up ml-1"></i>
                      </span>
                    )}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </>
      )}
      {props?.package?.SessionType !== 3 ? (
        <>
          <div className="row">
            {staffPage && (
              <>
                <div className="col-md-6 py-1">
                  <label>Total Cost</label>
                  <NumericTextBox
                    id={"DiscountedCost"}
                    name={"DiscountedCost"}
                    width={"100%"}
                    format={"c2"}
                    placeholder="Total Cost"
                    min={0}
                    disabled={props?.staffPage ? !props?.ratepermission : true}
                    value={props?.package?.DiscountedCost}
                    onChange={(e: any) => {
                      if (e.target.value > 0) {
                        props?.handlePackageReccurence(
                          "DiscountedCost",
                          e.target.value
                        );
                      } else {
                        props?.handlePackageReccurence("DiscountedCost", 0);
                      }
                    }}
                  />
                </div>
                {props?.package?.SessionType === 1 && (
                  <div className="col-md-6 py-1">
                    <label>Total Appointments</label>
                    <NumericTextBox
                      id={"OptedSchedules"}
                      name={"OptedSchedules"}
                      width={"100%"}
                      placeholder="Total Appointments"
                      min={1}
                      format={"n0"}
                      value={props?.package?.OptedSchedules}
                      onChange={(e: any) =>
                        props?.handlePackageReccurence(
                          "OptedSchedules",
                          e.target.value
                        )
                      }
                    />
                  </div>
                )}
                {props?.package?.SessionType === 1 &&
                  props?.package?.Frequency > PackageFrequencyEnum.ONETIME && (
                    <div className="col-md-6 py-1">
                      <label>
                        <div className="d-flex ">
                          <div>Rollover</div>
                          <BuzopsTooltip
                            position={"right"}
                            html={
                              <button
                                className="btn btn-icon"
                                title={
                                  "Max Rollover Appointments to next billing cycle"
                                }
                              >
                                <BiInfoCircle color="#008db9" />
                              </button>
                            }
                          />
                        </div>
                      </label>
                      <NumericTextBox
                        id={"OptedMaxRollOverSchedules"}
                        name={"OptedMaxRollOverSchedules"}
                        width={"100%"}
                        placeholder="Rollover"
                        min={0}
                        format={"n0"}
                        value={props?.package?.OptedMaxRollOverSchedules}
                        onChange={(e: any) =>
                          props?.handlePackageReccurence(
                            "OptedMaxRollOverSchedules",
                            e.target.value
                          )
                        }
                      />
                      <Error>
                        {(props?.package?.OptedMaxRollOverSchedules < 0 ||
                          props?.package?.OptedMaxRollOverSchedules > 100) &&
                          `Rollover appointments should between 0 and 100`}
                      </Error>
                    </div>
                  )}
              </>
            )}
            <div className="col-md-6 py-1">
              <label className="d-flex">Sign up Date</label>
              <DatePicker
                width={"100%"}
                disabled={
                  true
                  // !staffPage ||
                  // props?.package?.Frequency === PackageFrequencyEnum.ONETIME ||
                  // props?.package?.Frequency === PackageFrequencyEnum.YEARLY
                }
                min={minSignUpDate}
                max={new Date(today1)}
                value={props?.package?.SignupDate}
                onChange={(e) => {
                  props?.handlePackageReccurence("SignupDate", e.value);
                }}
                popup={CustomPopup}
              />
            </div>
            <div className="col-md-6 py-1">
              <div className="d-flex ">
                <label>Start Date</label>
                <BuzopsTooltip
                  position={"right"}
                  html={
                    <button
                      className="btn btn-icon"
                      title={
                        "Choose a date from which you want to redeem your package."
                      }
                    >
                      <BiInfoCircle color="#008db9" />
                    </button>
                  }
                />
              </div>
              <DatePicker
                width={"100%"}
                min={new Date(today1)}
                // max={(props?.package?.ClientCanSkipPayment ? sixMonths : threeMonths)}
                max={MaxStartDate}
                value={props?.package?.StartDate}
                disabled={
                  (!staffPage && !props?.package?.CanSignupFutureDate) ||
                  moment(props?.package?.SignupDate).format("YYYY-MM-DD") <
                    moment(today1).format("YYYY-MM-DD")
                }
                onChange={(e) => {
                  props?.handlePackageReccurence("StartDate", e.value);
                }}
                popup={CustomPopup}
              />
            </div>
            {props?.package?.Frequency !== PackageFrequencyEnum.ONETIME && (
              <div className="col-md-6 py-1">
                <div className="d-flex ">
                  <label>Billed On*</label>
                  <BuzopsTooltip
                    toolTipclassName={"Cancellation-fee-tooltip"}
                    position={"right"}
                    content={(propdata: any) => (
                      <BilledOnTooltip dataItem={props?.package} />
                    )}
                    html={
                      <button className="btn btn-icon" title={"Billed on"}>
                        <BiInfoCircle color="#008db9" />
                      </button>
                    }
                  />
                </div>
                <DropDownList
                  style={{
                    width: "100%",
                  }}
                  // className="form-control"
                  onChange={(e) => {
                    props?.handlePackageReccurence(
                      "BillingType",
                      e.value?.value
                    );
                  }}
                  data={dropdownBillingData}
                  textField="text"
                  dataItemKey="value"
                  disabled={
                    props?.package?.Frequency !==
                      PackageFrequencyEnum.MONTHLY ||
                    props?.package?.RepeatFrequency !== 1 ||
                    !staffPage
                  }
                  value={
                    dropdownBillingData.filter(
                      (res: any) => res.value === props?.package?.BillingType
                    )[0]
                  }
                />
                {(props?.package?.BillingType === 2 ||
                  moment(props?.package?.StartDate).date() ===
                    props?.package?.BillingDay) &&
                  props?.package?.RepeatFrequency === 1 && (
                    <>
                      <Hint>
                        <span className="form-text text-muted">
                          {`Package billing amount will be charged on the ${notProratedHintDay} of every month for any recurring fees or charges associated`}
                        </span>
                      </Hint>
                    </>
                  )}
                {props?.package?.BillingDay > 0 &&
                  props?.package?.BillingType === 3 &&
                  moment(props?.package?.StartDate).date() !==
                    props?.package?.BillingDay && (
                    <>
                      <Hint>
                        <span className="form-text text-muted">
                          {props?.package?.BillingDay &&
                            `Package billing amount will be prorated for any current charges until the ${proratedHintDay} of this month. From then on, Package billing amount will be charged on the ${proratedHintDay} of every month`}
                        </span>
                      </Hint>
                    </>
                  )}
              </div>
            )}
            {props?.package?.BillingType === 3 &&
              staffPage &&
              props?.package?.Frequency === PackageFrequencyEnum.MONTHLY &&
              props?.package?.RepeatFrequency === 1 && (
                <>
                  <div className="col-md-6 py-1">
                    <div className="d-flex ">
                      <label>Billing Day*</label>
                      {props?.package?.BillingDay ? (
                        <BuzopsTooltip
                          position={"right"}
                          html={
                            <button
                              className="btn btn-icon"
                              title={`Billing occurs on the ${proratedHintDay} day of each month`}
                            >
                              <BiInfoCircle color="#008db9" />
                            </button>
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <DropDownList
                      style={{
                        width: "100%",
                      }}
                      // className="form-control"
                      onChange={(e) => {
                        props?.handlePackageReccurence("BillingDay", e.value);
                      }}
                      data={BillingDaysArray}
                      disabled={
                        props?.package?.Frequency !==
                          PackageFrequencyEnum.MONTHLY ||
                        props?.package?.RepeatFrequency !== 1 ||
                        (!staffPage && !props?.package?.IsProrated)
                      }
                      value={
                        props?.package?.BillingDay ||
                        "Please select Billing day"
                      }
                    />
                  </div>
                </>
              )}
            {props?.package?.SessionType === 1 &&
              props?.package?.BillingType === 3 &&
              moment(props?.package?.StartDate).date() !==
                props?.package?.BillingDay &&
              props?.package?.IsProrated &&
              props?.package?.Frequency === PackageFrequencyEnum.MONTHLY &&
              props?.package?.RepeatFrequency === 1 && (
                <>
                  <div className="col-md-6 py-1">
                    <div className="d-flex ">
                      <label>Prorated Appointments</label>
                      <BuzopsTooltip
                        position={"top"}
                        html={
                          <button
                            className="btn btn-icon"
                            title={
                              "Offer appointments for the current prorated month, ensuring the total appointments should not exceed the available allocation"
                            }
                          >
                            <BiInfoCircle color="#008db9" />
                          </button>
                        }
                      />
                    </div>
                    <NumericTextBox
                      id={"ProratedSchedules"}
                      name={"ProratedSchedules"}
                      width={"100%"}
                      placeholder="Prorated Appointments"
                      min={0}
                      format={"n0"}
                      disabled={!staffPage && !props?.package?.IsProrated}
                      value={props?.package?.ProratedSchedules}
                      onChange={(e: any) =>
                        props?.handlePackageReccurence(
                          "ProratedSchedules",
                          e.target.value
                        )
                      }
                    />
                    <Error>
                      {(props?.package?.ProratedSchedules < 1 ||
                        props?.package?.ProratedSchedules >
                          props?.package?.OptedSchedules) &&
                        `Appointments can be between 1 and available allocation, i.e ${props?.package?.OptedSchedules}`}
                    </Error>
                  </div>
                </>
              )}
          </div>
          {(staffPage || props?.package?.CanPayLater) && (
            <div className="col-md-6 row">
              
              <div className="d-flex align-items-center pl-0">
                <Checkbox
                  label={"Pay Later"}
                  style={{
                    height: "25px",
                    width: "25px",
                  }}
                  disabled={
                    moment(props?.package?.StartDate).format("YYYY-MM-DD") <=
                      moment().format("YYYY-MM-DD") ||
                    (!staffPage && !props?.package?.CanPayLater)
                  }
                  className="bz-plan-order-summary-checkbox mt-0"
                  value={props?.package?.ClientCanSkipPayment}
                  onChange={(e) => {
                    props?.handlePackageReccurence(
                      "ClientCanSkipPayment",
                      e.value
                    );
                  }}
                />
                <BuzopsTooltip
                  toolTipClass={"Cancellation-fee-tooltip"}
                  position={"right"}
                  content={(propdata: any) => (
                    <TooltipContentTemplate title={propdata.title} />
                  )}
                  html={
                    <button
                      className="btn btn-icon  d-flex align-items-end"
                      title={"Pay Later"}
                    >
                      <BiInfoCircle color="#008db9" />
                    </button>
                  }
                />
              </div>
            </div>
          )}
        </>
      ) : (
          <div className="row">
            <div className="col-md-6 py-1">
              <label>Count</label>
              <NumericTextBox
                id={"Schedules"}
                name={"Schedules"}
                width={"100%"}
                placeholder="Schedules"
                min={1}
                format={"n0"}
                disabled={!props?.staffPage}
                value={props?.package?.Schedules}
                onChange={(e: any) => {
                  if (e.target.value >= 1) {
                    debouncedRatePerSession(e.target.value)
                  } else {
                    props?.handlePackageReccurence("Schedules", 1);
                  }
                }}
              />
              {props?.staffPage &&   <span className="mt-2">Click here for   <span
              className="btn btn-link" onClick={()=> {props?.handleAppointmentRates()}}>Appointment Settings</span></span>}
            </div>
            <div className="col-md-6 py-1">
              <label>Rate per session</label>
              <NumericTextBox
                id={"IndividualSessionRate"}
                name={"IndividualSessionRate"}
                width={"100%"}
                format={"c2"}
                placeholder="Rate per Session"
                min={0}
                disabled={props?.staffPage ? !props?.ratepermission : true}
                value={props?.package?.IndividualSessionRate}
                onChange={(e: any) => {
                  if (e.target.value > 0) {
                    props?.handlePackageReccurence(
                      "IndividualSessionRate",
                      e.target.value
                    );
                  } else {
                    props?.handlePackageReccurence("IndividualSessionRate", 0);
                  }
                }}
              />
               <Error>{props?.package?.IndividualSessionRate === null &&
                          `Please provide Rate per Session`}</Error>
            </div>
          </div>
      )}
    </>
  );
};

export default PurchasePackagePanel;
