import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const SuccessForm = (props: any) => {
  const history = useHistory();
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    if (!props?.clientDashboard) {
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            return prevSeconds;
          }
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (seconds === 0 && !props?.clientDashboard) {
      // window.location.href = 'https://skygazer.bzcal.com/';  
      const ReturnUrl = props?.location?.state?.ReturnUrl || props?.ReturnUrl    
      const redirection = props?.location?.state?.redirection || props?.redirection  
      const shortcode = props?.location?.state?.shortcode || props?.shortcode  
      if(redirection=='false'){
        history.push(`${shortcode}?redirection=${redirection}`);
      } else if (ReturnUrl) {
        window.location.href = ReturnUrl;
      } else {
        history.push("/");
      }
    }
  }, [seconds]);

  return (
    <div className="online-form-generator m-2 p-2">
      <div className="alert alert-success" role="alert">
        Thanks! Form Submitted Successfully.
      </div>
      <h2>{props?.location?.state?.Title || props?.Title}</h2>
      <p>Your response has been recorded</p>
      {!props?.clientDashboard && <p>Redirecting in {seconds} seconds...</p>}
    </div>
  );
};

export default SuccessForm;
