import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import { Notification as Notify } from "@progress/kendo-react-notification";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { CheckUserMemberStatus, CurrencyFormat, checkIfUserCanAddBankAccount,checkHasTaxEnable } from "utils";
import { Error, Hint } from "@progress/kendo-react-labels";
import withNotification from "components/Hoc/withNotification";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import {
  Grid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import Transactions from "../Transactions";
import { CreditsErrorMessage } from "utils/form-utils";
const IndividualServiceCancel = (props: any) => {
  const [cancelDetails, setCancelDetails] = useState<any>(null);
  const [paymentProfile, setPaymentProfiles] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  const [success,setSuccess]=useState(false)
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const PaymentMethodItems = [
    {
      iconClass: "fa fa-credit-card pr-1",
      text: "Add credit card",
      key: "addCard",
      visible:true,
    },
    {
      iconClass: "fa fa-university pr-1",
      text: "Add bank account",
      key: "addBank",
      visible: checkIfUserCanAddBankAccount()
    },
  ];
  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const handleSuccessClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
    await fetchPaymentProfiles(props?.userData?.ParentId || props?.UserMemberId);
  };

  const handleTransactions=()=>{
    setShowTransactions(!showTransactions)
  }

  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${checkIfUserCanAddBankAccount() ? "Add Bank or Card": "Add Card"}`}
         
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={props?.staffPage}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={props?.userData?.ParentId || props?.UserMemberId}
          />
        </Dialog>
      </>
    );
  };
  const service = new TenantService();
  const CanScheduleAppointmentOptions = [
    { value: true, text: "Yes" },
    { value: false, text: "No" },
  ];
  const items = [
    { text: "Charge", id: 1 },
    { text: "No Charge", id: 3 },
  ];
  useEffect(() => {
    fetchAllApis();
  }, []);

  useEffect(() => {
    if (props?.submit) {
      handleRedirection();
    }
  }, [props?.submit]);

  const handleRedirection = () => {
    props?.goToServicePage();
  };

  const dateCell = (props: any) => {
    const TransactionDate = moment(props?.dataItem?.TransactionDate).format(
      "L"
    );
    return <td>{TransactionDate}</td>;
  };
  const amountCell = (props: any) => {
    const Amount = CurrencyFormat(props?.dataItem?.Amount);
    return <td>{Amount}</td>;
  };
  const FailedTransactionsGrid = (TransactionData: any) => {
    return (
      <>
        <h5 className="mb-2 pt-1 bz_fs_1_5">Failed Transactions</h5>
        <Hint>{"Below transactions will be waived automatically."}</Hint>
        <div className="bz-mt-1rem bz-mb-2_5rem">
          <div className="">
            <Grid
              data={TransactionData}
              className="bzc-grid bzc-failedTransactionGrid"
            >
              <Column
                field="TransactionId"
                title="Transaction Id"
                width={250}
              />
              <Column
                field="TransactionDate"
                title="Transaction Date"
                cell={dateCell}
              />
              <Column field="Amount" title="Amount" cell={amountCell} />
            </Grid>
          </div>
        </div>
      </>
    );
  };

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchCancellationDetails();
    console.log(props?.userData,'props?.userDate')
    await fetchPaymentProfiles(props?.userData?.ParentId || props?.UserMemberId);
    setLoading(false);
  };
  async function fetchPaymentProfiles(userMemberId: any) {
    const result = await service.paymentProfile(userMemberId);
    setPaymentProfiles(result || []);
  }
  const showErrorMessage = (key: any, mainKey: any) => {
    if (key === "PaymentGatewayPaymentProfileId") {
      if (!cancelDetails[key] || cancelDetails[key] === 0) {
        return <Error>{`${mainKey} is required`}</Error>;
      }
    } else if (
      key === "CanScheduleAppointment" ||
      key === "AvailableToRefund"
    ) {
      if (cancelDetails[key] === null || cancelDetails[key] === "") {
        return <Error>{`${mainKey} is required`}</Error>;
      }
    } else if (!cancelDetails[key] || cancelDetails[key].length <= 0) {
      return <Error>{`${mainKey} is required`}</Error>;
    }
    return <></>;
  };

  const handleChange = async (value: any, name: string) => {
    const data = JSON.parse(JSON.stringify(cancelDetails));
    let cancelDet = data;
    if (name === "PaymentGatewayPaymentProfileId") {
      const PaymentGatewayPaymentProfileID =
        value?.PaymentGatewayPaymentProfileID;
      cancelDet = {
        ...data,
        PaymentGatewayPaymentProfileId: PaymentGatewayPaymentProfileID,
        Credit: value?.Credit,
        CardTypeId: value?.CardTypeId,
      };
    } else if (name === "AvailableToRefund") {
      if (value > AvailableToRefundMaximum) {
        value = AvailableToRefundMaximum;
      }
      cancelDet = {
        ...data,
        [name]: value,
      };
    } else {
      cancelDet = {
        ...data,
        [name]: value,
      };
    }
    setCancelDetails(cancelDet);
  };
  const handleValidation = () => {
    if (
      !cancelDetails?.CancellationNotes ||
      cancelDetails?.CancellationNotes === ""
    ) {
      return false;
    }
    if (
      cancelDetails?.CancellationProcessType === 1 &&
      cancelDetails?.CancellationFee > 0 &&
      (!cancelDetails?.PaymentGatewayPaymentProfileId ||
        cancelDetails?.PaymentGatewayPaymentProfileId === 0)
    ) {
      return false;
    }
    if (
      cancelDetails?.CancellationProcessType === 1 &&
      cancelDetails?.CancellationFee > 0 &&
      (cancelDetails?.CardTypeId === 8 && cancelDetails?.CancellationFee > cancelDetails?.Credit)
    ) {
      const errorMsg = CreditsErrorMessage
      props?.handleNotificationMessage(errorMsg, "error");
      return false;
    }
    if (cancelDetails?.CancellationProcessType === 0) {
      return false;
    }
    if (cancelDetails?.CanScheduleAppointment === null) {
      return false;
    }
    if (
      cancelDetails?.FailedTransactions?.length > 0 &&
      cancelDetails?.WaivedComments === ""
    ) {
      return false;
    }
    return true;
  };
  const handleCompleteCancellation = async () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    setSubmit(true);
    const check = handleValidation();
    if (!check) {
      return true;
    }
    setBtnLoading(true);
    let reqpayload = { ...cancelDetails };
    if (cancelDetails.CancellationProcessType === 1) {
      const paidTransactions = cancelDetails?.PaidTransactions?.map(
        (i: any) => {
          return { ...i, Amount: cancelDetails?.CancellationFee };
        }
      );
      reqpayload = {
        ...cancelDetails,
        TotalAmountToCharge: cancelDetails?.CancellationFee,
        Charged: cancelDetails?.CancellationFee,
        AvailableToRefundMaximum: 0,
        PaidTransactions: paidTransactions,
      };
    } else if (cancelDetails.CancellationProcessType === 2) {
      const paidTransactions = cancelDetails?.PaidTransactions?.map(
        (i: any) => {
          return { ...i, AmountToBeRefunded: cancelDetails?.AvailableToRefund };
        }
      );
      reqpayload = {
        ...cancelDetails,
        TotalAmountToRefund: cancelDetails?.AvailableToRefund,
        Charged: 0,
        TotalAmountToCharge: 0,
        AvailableToRefundMaximum:cancelDetails?.AvailableToRefund,
        PaidTransactions: paidTransactions,
      };
    }
    const result = await service.IndividualServiceCompleteCancellation(
      reqpayload
    );
    setBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setSuccess(true)
      props?.handleNotificationMessage(
        result?.SuccessMessage,
        "success"
      );
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0
          ? result?.ErrorMessages[0]
          : "Error in Cancelling One-on-One";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const fetchCancellationDetails = async () => {
    const entityId = props.EntityId;
    let memberId = props?.UserMemberId;
    const req = {
      UserMemberId: memberId,
      PrivateServiceInstanceId: entityId,
    };
    const result = await service.GetIndividualCancellationDetails(req);
    setCancelDetails(result);
  };

  const handleClose=async()=>{
    setShowTransactions(false)
    await fetchAllApis()
  }
  if (loading) {
    return <BuzopsLoader type="list" />;
  }

  if(showTransactions){
    return (<>

    <Transactions
        allItems={false}
        EntityId={props.EntityId}
        FilterType={0}
        filterValues={[
          {
            "Column": "PaymentModeVal",
            "Filter": "eq",
            "Operator":"eq",
            "Value": 1,
          },
          {
            "Column": "PaymentTransactionStatus",
            "Filter": "eq",
            "Operator":"eq",
            "Value": 4,
          },
          {
            "Column": "AmountPaid",
            "Filter": "gt",
            "Operator":"gt",
            "Value": 0,
          }
        ]}
        userData={props?.userData}
        staffPage={props?.staffPage}
        fromCancel={true}
        handleClose={handleClose}
      />
    </>)
  }
  const {
    PurchasedDate,
    SessionRate,
    TotalPackageCost,
    Charged,
    CanScheduleAppointment,
    CancellationProcessType,
    AvailableToRefund,
    CancellationFee,
    AvailableToRefundMaximum,
    CanExcludedFromChurnRate,
    CanEmailToClient,
    ServiceName,
    HasUnsedPaidSchedules,
    UnusedPaidSchedulesCount,
    FailedTransactions,
    CanCollectTaxes=checkHasTaxEnable()
  } = cancelDetails;
  return (
    <>
      <h5 className="mb-3 bz_fs_1_5">One-on-One Cancellation</h5>
      <hr />
      <h6 className="mb-2 bz_fs_1_5">{`${ServiceName}(Service)`}</h6>
      <div className="bz-subsection-row bz-plan-overview bz-addon-cancellation">
        <div className="left-section">
          <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Purchased Date</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {moment(PurchasedDate).format("L")}
              </div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Per Appointment Rate
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {CurrencyFormat(SessionRate)}
              </div>
            </div>
            <div className="bz-content-text-row show-tax-content-row">
              <div className="bz-content-title text-right">
                <div className="d-flex flex-column justify-content-end">
                  <span>Total Cost {CanCollectTaxes && <span>*</span>}</span>
                  {CanCollectTaxes && <small style={{fontSize:'10px',color:'#777'}}>(Exclusion of Surcharges)</small>}
                </div>
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {CurrencyFormat(TotalPackageCost)}
              </div>
            </div>
            <div className="bz-content-text-row  show-tax-content-row">
              <div className="bz-content-title text-right">
                <div className="d-flex flex-column justify-content-end">
                  <span>Charged {CanCollectTaxes && <span>*</span>}</span>
                  {CanCollectTaxes && <small style={{fontSize:'10px',color:'#777'}}>(Inclusion of Surcharges)</small>}
                </div>
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{CurrencyFormat(Charged)}</div>
            </div>
            <div className="bz-content-text-row  show-tax-content-row">
              <div className="bz-content-title text-right">
              </div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body"><span>If you wish to refund, please <a className="btn btn-link" onClick={()=>handleTransactions()}>click here</a></span></div>
            </div>
          </div>
          <hr />
          <div className="bz-content-text-row align-items-start">
            <div className="bz-content-title text-right">
              {`You have (${UnusedPaidSchedulesCount}) paid schedules without usage, do you want to allow to schedule after cancellation?`}
              <span className="required-asterisk">*</span>
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <DropDownList
                data={CanScheduleAppointmentOptions}
                textField="text"
                dataItemKey="value"
                value={
                  CanScheduleAppointmentOptions?.filter(
                    (i: any) => i?.value === CanScheduleAppointment
                  )?.[0] || { text: "Please Select", value: 0 }
                }
                onChange={(e) =>
                  handleChange(e?.value?.value, "CanScheduleAppointment")
                }
                style={{ width: "100%" }}
              />
              {submit &&
                showErrorMessage(
                  "CanScheduleAppointment",
                  "Allow to schedule after cancellation"
                )}
            </div>
          </div>
          <div className="bz-content-text-row align-items-start">
            <div className="bz-content-title text-right">
              Cancellation Type<span className="required-asterisk">*</span>
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <DropDownList
                className="k-dropdown-width-100"
                id={`CancellationProcessType`}
                name={`CancellationProcessType`}
                data={items}
                textField="text"
                dataItemKey="id"
                value={
                  items?.filter(
                    (i: any) => i?.id === CancellationProcessType
                  )?.[0] || { text: "Please Select Cancellation Type", id: 0 }
                }
                onChange={(e) =>
                  handleChange(e.value?.id, "CancellationProcessType")
                }
              />
              {submit &&
                showErrorMessage(
                  "CancellationProcessType",
                  "Cancellation Type"
                )}
            </div>
          </div>
          {CancellationProcessType === 2 && (
            <>
              <div className="bz-content-text-row align-items-start">
                <div className="bz-content-title text-right">Refund Amount</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <NumericTextBox
                    id={`AvailableToRefund`}
                    name={`AvailableToRefund`}
                    min={0}
                    format={"c2"}
                    className="k-dropdown-width-100"
                    value={AvailableToRefund}
                    onChange={(e) =>
                      handleChange(e.target.value, "AvailableToRefund")
                    }
                  />
                  <Hint>{`Available to refund :${CurrencyFormat(
                    AvailableToRefundMaximum
                  )}`}</Hint>
                  {submit &&
                    showErrorMessage("AvailableToRefund", "Amount to Refund")}
                </div>
              </div>
            </>
          )}
          {CancellationProcessType === 1 && (
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Cancellation Fee
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <NumericTextBox
                  id={`CancellationFee`}
                  name={`CancellationFee`}
                  min={0}
                  format={"c2"}
                  className="k-dropdown-width-100"
                  value={CancellationFee}
                  onChange={(e) =>
                    handleChange(e.target.value, "CancellationFee")
                  }
                />
              </div>
            </div>
          )}
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">
              Send cancellation email to client/provider
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <Switch
                checked={CanEmailToClient}
                onChange={(e) => handleChange(e.value, "CanEmailToClient")}
              />
            </div>
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">
              Consider as upgrade/downgrade the service to exclude from churn
              rate
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <Switch
                checked={CanExcludedFromChurnRate}
                onChange={(e) =>
                  handleChange(e.value, "CanExcludedFromChurnRate")
                }
              />
            </div>
          </div>
          {HasUnsedPaidSchedules && CanScheduleAppointment && (
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right"></div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body">
                {" "}
                <div className="bz-plan-note">
                  <p className="mb-0">
                    <Notify key="info" type={{ style: "info" }}>
                      {`The Remaining (${UnusedPaidSchedulesCount}) appointments are available to schedule or reschedule.`}
                    </Notify>
                  </p>
                </div>
              </div>
            </div>
          )}
          {CanExcludedFromChurnRate && (
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right"></div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body">
                {" "}
                <div className="bz-plan-note">
                  <p className="mb-0">
                    <Notify key="info" type={{ style: "info" }}>
                      {
                        "The cancellation is processing to upgrade/downgrade the service, and this cancellation will not affect your churn rate."
                      }
                    </Notify>
                  </p>
                </div>
              </div>
            </div>
          )}

          {CancellationProcessType === 1 && CancellationFee > 0 && (
            <>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">
                  Payment Profile<span className="required-asterisk">*</span>
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body  payment-profile">
                  <div>
                    <DropDownList
                      id={`PaymentGatewayPaymentProfileId`}
                      name={`PaymentGatewayPaymentProfileId`}
                      data={paymentProfile}
                      textField="MaskedCCNumber"
                      valueRender={(
                        el: React.ReactElement<HTMLSpanElement>,
                        value: any
                      ) => PaymentValueRender(el, value, value)}
                      itemRender={PaymentItemRender}
                      onChange={(e) => {
                        handleChange(e.value, "PaymentGatewayPaymentProfileId");
                      }}
                      style={{ width: "100%" }}
                    />
                    {submit &&
                      showErrorMessage(
                        "PaymentGatewayPaymentProfileId",
                        "Payment Profile"
                      )}
                  </div>
                  <div className="addNewBtn">
                    <DropDownButton
                      text="Add New"
                      onItemClick={(e: any) => onCardOrBankAdd(e)}
                      items={PaymentMethodItems.filter((i:any)=>i?.visible===true)}
                      look="flat"
                      className="btn btn-link add-new-btn"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {FailedTransactions?.length > 0 && (
            <>
              {FailedTransactionsGrid(FailedTransactions)}
              <div className="bz-content-text-row align-items-start">
                <div className="bz-content-title text-right">
                  Waive Comments<span className="required-asterisk">*</span>
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <textarea
                    className="form-control"
                    placeholder="Please enter waive comments"
                    onChange={(e) =>
                      handleChange(e.target.value, "WaivedComments")
                    }
                  ></textarea>
                  {submit &&
                    showErrorMessage("WaivedComments", "Waive Comments")}
                </div>
              </div>
            </>
          )}
          <div className="bz-content-text-row align-items-start">
            <div className="bz-content-title text-right">
              Comments<span className="required-asterisk">*</span>
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <textarea
                className="form-control"
                placeholder="Please enter Comments"
                onChange={(e) =>
                  handleChange(e.target.value, "CancellationNotes")
                }
              ></textarea>
              {submit && showErrorMessage("CancellationNotes", "Comments")}
            </div>
          </div>
          {((CancellationProcessType === 1 && CancellationFee > 0) ||
            (CancellationProcessType === 2 && AvailableToRefund > 0)) && (
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">{`Total ${
                CancellationProcessType === 1 ? "Charge" : "Refund"
              } Amount`}</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {CurrencyFormat(
                  CancellationProcessType === 1
                    ? CancellationFee
                    : AvailableToRefund
                )}
              </div>
            </div>
          )}
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right"></div>
          <span className="bz-colon text-center"></span>
          <div className="bz-content-body">
            <BuzopsButton
              loading={btnLoading}
              disabled={btnLoading || success}
              label={"Complete"}
              onClick={() => handleCompleteCancellation()}
            />
          </div>
        </div>
      </div>
      {showPaymentDialog && PaymethodDialog()}
    </>
  );
};
export default withNotification(IndividualServiceCancel);
