import React, { useEffect, useState } from "react";
import BuzopsButton from "generic-components/BuzopsButton";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { CurrencyFormat, PaymentMadeFor, checkIfUserCanAddBankAccount } from "utils";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import { Switch } from "@progress/kendo-react-inputs";
import { TenantService } from "services/tenant/index.service";
import withNotification from "components/Hoc/withNotification";
import moment from "moment";
import { Dialog } from "@progress/kendo-react-dialogs";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import { CreditsErrorMessage } from "utils/form-utils";
import { showWaived } from "utils/permission-utils";

const PayNow = (props: any) => {
  const currentDate = new Date();
  const [PaynowDetails, setpaynowdetail] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [paymentProfile, setPaymentProfiles] = useState<any>([]);
  const [selectedPaymentProfile, setSelectedPaymentProfile] = useState<any>(null);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const PaymentMethodItems = [
    {
      iconClass: "fa fa-credit-card pr-1",
      text: "Add credit card",
      key: "addCard",
      visible:true,
    },
    {
      iconClass: "fa fa-university pr-1",
      text: "Add bank account",
      key: "addBank",
      visible: checkIfUserCanAddBankAccount()
    },
  ];
  const service = new TenantService();

  useEffect(() => {
    fetchAllApis();
  }, []);

  const onCardOrBankAdd = (e: any, memberId: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };
  const fetchAllApis = async () => {
    setLoading(true);
    const res = await fetchPaymentProfiles(props?.UserMemberId);
    await getData(res);
    setLoading(false);
  };

  async function getData(paymentProfiles: any) {
    let transactionid = props?.transachisid;
    const result = await service.GetPayNowTransaction(transactionid);
    const payProfile = paymentProfiles?.filter((i: any) => i?.PaymentGatewayPaymentProfileID === result?.PaymentProfileId)?.[0]
    setpaynowdetail(result || []);
    setSelectedPaymentProfile(payProfile);
  }

  async function fetchPaymentProfiles(userMemberId: any) {
    const result = await service.paymentProfile(userMemberId);
    setPaymentProfiles(result || []);
    return result
  }

  const handleChange = async (value: any, name: string) => {
    const data = JSON.parse(JSON.stringify(PaynowDetails));
    let datatrans = data;
    if (name === "PaymentGatewayPaymentProfileId") {
      const PaymentGatewayPaymentProfileID =
        value?.PaymentGatewayPaymentProfileID;
      datatrans = {
        ...data,
        PaymentProfileId: PaymentGatewayPaymentProfileID,
        Credit:value?.Credit,
        CardTypeId:value?.CardTypeId
      };
      setSelectedPaymentProfile(value)
    } else {
      datatrans = {
        ...data,
        [name]: value,
      };
    }
    setpaynowdetail(datatrans);
  };

  const showErrorMessage = (key: any, mainKey: any) => {
    if (key === "Comments") {
      if (!PaynowDetails[key] || PaynowDetails[key].length <= 0) {
        return <Error>{`${mainKey} is required`}</Error>;
      }
    }
    return <></>;
  };

  const handleValidation = () => {
    if (IsWaivePayment) {
      if (!PaynowDetails?.Comments || PaynowDetails?.Comments === "") {
        return false;
      }
    } else {
      if (!selectedPaymentProfile) {
        return false;
      }else  if(selectedPaymentProfile?.CardTypeId === 8 && PaynowDetails?.Amount > selectedPaymentProfile?.Credit){
        const errorMsg = CreditsErrorMessage
        props?.handleNotificationMessage(errorMsg, "error");
        return false
      }
    }

    return true;
  };

  const handleComplete = async () => {
    const check = handleValidation();
    if (!check) {
      return true;
    }
    setBtnLoading(true);
    const result = await service.GetPayNow(PaynowDetails);
    setBtnLoading(false);
    if (result?.ResponseCode === 100) {
      props?.handleNotificationMessage(result?.SuccessMessage, "success");
      props?.handleCloseViewDetail(true);
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0 ? result?.ErrorMessages[0] : "Error ";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog)
  }
  const handleSuccessClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog)
    fetchPaymentProfiles(props?.UserMemberId)
  }
  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${checkIfUserCanAddBankAccount() ? "Add Bank or Card": "Add Card"}`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation staffPage={true} addNewItem={addNewItem} handleClosePaymethodDialog={handleClosePaymethodDialog} handleSuccessClosePaymethodDialog={handleSuccessClosePaymethodDialog} UserMemberId={props?.UserMemberId} />
        </Dialog>
      </>
    );
  };
  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  const { Amount, IsWaivePayment, CanProrate } = PaynowDetails;
  return (
    <>

      <hr />
      <div className="bz-plan-overview bz-addon-cancellation">
        <div className="left-section">
          <h5 className="mb-3 pt-3 bz_fs_1_5">
            {props?.staffPage ? "Pay or waive payment" : "Pay"}
          </h5>
          <div className="bz-mt-2rem bz-mb-2_5rem">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Transaction ID</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{props?.transid}</div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Amount</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{CurrencyFormat(Amount)}</div>
            </div>
            {props?.staffPage && showWaived(PaynowDetails) && (
              <>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">
                    Do you want to waive this payment ?
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <Switch
                      checked={IsWaivePayment}
                      onChange={(e) => handleChange(e.value, "IsWaivePayment")}
                    />
                  </div>
                </div>
                {(PaynowDetails?.PaymentMadeFor === PaymentMadeFor["Package"] && moment(currentDate)?.format('DD/MM/YYYY') !== moment(props?.TransactionDate)?.format('DD/MM/YYYY')) &&
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">
                      Restart the Service from Current Day
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      <Switch
                        checked={CanProrate}
                        onChange={(e) => handleChange(e.value, "CanProrate")}
                      />
                    </div>
                  </div>
                }
              </>
            )}
            {IsWaivePayment == false && (
              <>
                <div className="bz-content-text-row align-items-start">
                  <div className="bz-content-title text-right">
                    Payment Profile
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <div className="d-flex">
                      <div>
                        <DropDownList
                          id={`PaymentGatewayPaymentProfileId`}
                          name={`PaymentGatewayPaymentProfileId`}
                          data={paymentProfile}
                          textField="MaskedCCNumber"
                          valueRender={(
                            el: React.ReactElement<HTMLSpanElement>,
                            value: any
                          ) => PaymentValueRender(el, value, selectedPaymentProfile)}
                          itemRender={PaymentItemRender}
                          onChange={(e) => {
                            handleChange(e.value, "PaymentGatewayPaymentProfileId");
                          }}
                          style={{ width: "100%" }}
                        />
                        {showErrorMessage(
                          "PaymentGatewayPaymentProfileId",
                          "Payment Profile"
                        )}
                      </div>
                      {
                        props?.canAddCardOrBank &&
                        <div className="addNewBtn pl-1">
                          <DropDownButton
                            text="Add New"
                            onItemClick={(e: any) => onCardOrBankAdd(e, props?.UserMemberId)}
                            items={PaymentMethodItems.filter((i:any)=>i?.visible===true)}
                            look="flat"
                            className="btn btn-link add-new-btn"
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </>
            )}

            {IsWaivePayment === true && (
              <>
                <div className="bz-content-text-row align-items-start">
                  <div className="bz-content-title text-right">Comments</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <textarea
                      className="form-control"
                      onChange={(e) => handleChange(e.target.value, "Comments")}
                    ></textarea>
                    {showErrorMessage("Comments", "Comments")}
                  </div>
                </div>
              </>
            )}
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right"></div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body">
                <Button
                  onClick={() => props?.handleCloseViewDetail(false)}
                  className="ml-0"
                  disabled={btnLoading}
                >
                  Cancel
                </Button>
                <BuzopsButton
                  loading={btnLoading}
                  disabled={btnLoading}
                  label={"Complete"}
                  onClick={() => handleComplete()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPaymentDialog && PaymethodDialog()}
    </>
  );
};

export default withNotification(PayNow);
