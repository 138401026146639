import { Button } from "@progress/kendo-react-buttons";
import useState from 'react-usestateref';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import { Input, NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import { Error, Hint } from "@progress/kendo-react-labels";
import {
  Avatar,
  Card,
  CardBody,
  CardHeader,
} from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsNotification from "generic-components/BuzopsNotification";
import React, { useEffect, useRef } from "react";
import { ClassService } from "services/class/index.service";
import { TenantService } from "services/tenant/index.service";
import {
  CurrencyFormat,
  CustomToFixed,
  checkIfUserCanAddBankAccount,
  getDayFormat,
  getFullImageUrl,
  getMonthFormat,
  getTimeFormat,
  getWeekdayFormat,
  getYearFormat,
} from "utils";
import { PermissionEnum } from "utils/PermissionEnum";
import CheckPermissions from "utils/permissions";
import { GetLocalStore } from "utils/storage";
import { DropDownButton } from "@progress/kendo-react-buttons";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import Location from "components/Location/Location";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TooltipContentRefundTax } from "components/Transactions/Refund";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { PaymentItemRender, PaymentValueRender } from "components/Payment/PaymentRenderItems";
import { CreditsErrorMessage } from "utils/form-utils";

const Cancellation = (props: any) => {
  const refundInputRef = useRef<any>();
  const [selectedPaymentProfile, setSelectedPaymentProfile] = useState<any>({
    selectedPayment: undefined,
    chargeAmount: 0,
  });
  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });
  const [showCancelled, setshowCancelled] = useState(true);
  const [cancelSession, setCancelSession] = useState<boolean>(
    props?.cancellationType === "session" ? true : false
  );
  const [message, setMessage] = useState("");
  const [cancelReq, setCancelReq] = useState<any>([]);
  const [cancelledText, setCancelledText] = useState("");
  const [visible, setvisible] = useState(false);
  const [submit, setSubmit] = useState(false);
  // const [selectedClientData, setSelectedClientData] = useState<any>();

  const [clients, setClients] = useState<any>([]);
  const [attendeesList, setAttendeesList] = useState<any>([]);
  const [bulkchargeAmount, setBulkChargeAmount] = useState(0);
  const [selectedUserMemberId, setSelectedUserMemberId] = useState(null);
  const [bulkrefundAmount, setBulkRefundAmount] = useState(0);
  const [bulkchargeType, setBulkChargeType] = useState<any>({});
  const service = new ClassService();
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);

  const [addNewItem, setAddNewItem] = useState<any>(null);
  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [loading, setLoading] = useState<any>(true);
  const [classSession, setClassSession, classSessionRef] = useState<any>(null);
  const [btnLoading,setbtnLoading]=useState(false);
  const PaymentMethodItems = [
    {
      iconClass: "fa fa-credit-card pr-1",
      text: "Add credit card",
      key: "addCard",
      visible: true,
    },
    {
      iconClass: "fa fa-university pr-1",
      text: "Add bank account",
      key: "addBank",
      visible: checkIfUserCanAddBankAccount()
    },
  ];
  const onCardOrBankAdd = (e: any, userMemberId: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
    setSelectedUserMemberId(userMemberId);
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const handleSuccessClosePaymethodDialog = async () => {
    setShowPaymentDialog(false);
    const successMsg = "Payment creation successfully done";
    setMessage(successMsg);
    setNotification({ ...notification, success: true });
    const udpatedClientData = await fetchAllPaymentProfiles(attendeesList?.clientData);
    setAttendeesList({
      ...attendeesList,
      clientData: udpatedClientData
    });
  };

  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${checkIfUserCanAddBankAccount() ? "Add Bank or Card" : "Add Card"}`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={true}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              () => handleSuccessClosePaymethodDialog()
            }
            UserMemberId={selectedUserMemberId}
          />
        </Dialog>
      </>
    );
  };

  useEffect(() => {
    let timer = 1000;
    if (notification.error) {
      timer = 3000;
    }
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
      if (submit) {
        props.handleWindow();
      }
      if (attendeesList?.clientData?.length === 0) {
        props.handleWindow(1);
      }
    }, timer);
    return () => {
      clearTimeout(timer1);
    };
  }, [notification.error, notification.success, submit]);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClassession = async (sessionId = props?.classSession?.ClassSessionId) => {
    const req = {
      ClassSessionId: sessionId,
    };
    const service = new ClassService();
    const result = await service.filterClassSchedule(req);
    setClassSession(result?.Items[0]);
  };


  const fetchClients = async () => {
    setLoading(true)
    await fetchClassession()
    let cancelClients = props?.cancelClients;
    let clientsData = [];
    if (props?.isInhouse && props?.cancellationType === "session") {
      const req = {
        ClassSessionId: props?.classSession?.ClassSessionId,
        SortDirection: "ascending",
        SortBy: "Name",
        PageSize: 200,
        PageNumber: 0,
      };
      const result = await service.getClientsForSession(req);
      cancelClients = result?.Items || [];
    }
    setLoading(false)
    clientsData = cancelClients.map((item: any) => {
      let CanCollectTaxes = item?.CanCollectTaxes
      // const totalCost=classSessionRef.current?.Cost //total Paid Amount
      const totalCost = item?.DropInPrice //total Paid Amount
      let chargeAmount = 0;
      if (classSessionRef.current?.CancellationFee) {
        chargeAmount = classSessionRef.current?.CancellationFee;
      }
      let refundAmount = 0;
      if (classSessionRef.current?.CancellationFee === 0) {
        refundAmount = totalCost;
      } else if (
        classSessionRef.current?.CancellationFee > totalCost
      ) {
        refundAmount = 0;
      } else if (
        classSessionRef.current?.CancellationFee < totalCost
      ) {
        refundAmount =
          totalCost - classSessionRef.current?.CancellationFee;
      }
      let chargeTypeValue: any = {};
      if (classSessionRef.current?.CancellationFee === totalCost) {
        chargeTypeValue = { id: 3, text: "No Charge & No Refund" };
      } else if (
        classSessionRef.current?.CancellationFee > totalCost
      ) {
        chargeTypeValue = { id: 1, text: "Charge" };
      } else if (
        classSessionRef.current?.CancellationFee < totalCost
      ) {
        chargeTypeValue = { id: 2, text: "Refund" };
      }
      if (item?.PaymentMode === 2 || item?.PaymentMode === 3) {
        chargeTypeValue = { id: 3, text: "No Charge & No Refund" };
      }
      if (item?.IsWaitList && !item?.IsPaid) {
        chargeTypeValue = { id: 3, text: "No Charge & No Refund" };
      }

      return {
        ...item,
        Refund: refundAmount,
        Charge: chargeAmount,
        ChargeType: chargeTypeValue,
        PaymentProfiles: [],
        CanCollectTaxes,
        paymentHistoryData: []
      };
    });
    setClients(clientsData);
    setAttendeesList({
      clientData: props?.isInhouse
        ? clientsData
        : clientsData.filter(
          (item: any) =>
            item?.UserId === GetLocalStore("userDetails")?.UserSession?.UserId
        ),
    });
    const udpatedClientData = await fetchAllPaymentProfiles(clientsData);
    await fetchAllTransactionHistoryDetails(udpatedClientData);
  };

  async function asyncForEach(array: any, callback: any) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
  const fetchAllPaymentProfiles = async (clientsInfo: any) => {
    let paymentProfileList: any = [];
    await asyncForEach(clientsInfo, async (val: any) => {
      const result = await fetchPaymentProfiles(val?.PrimaryUserMemberId);
      paymentProfileList.push({
        UserMemberId: val.UserMemberId,
        paymentProfiles: result,
      });
    });
    let oldAttendeeList = JSON.parse(JSON.stringify(clientsInfo));
    const UpdatedData = oldAttendeeList.map((element: any) => {
      const paymentProfiles = paymentProfileList.find(
        (value: any) => value.UserMemberId === element.UserMemberId
      ).paymentProfiles
      return { ...element, PaymentProfiles: paymentProfiles }
    });
    return UpdatedData
  };
  const fetchAllTransactionHistoryDetails = async (clientsInfo: any) => {
    let paymentHistoryList: any = [];
    await asyncForEach(clientsInfo, async (val: any) => {
      let result = []
      if (val?.CanCollectTaxes) {
        result = await fetchTransactionHistory(val?.PaymentGatewayTransactionHistoryId);
        result = result?.filter((i: any) => i?.MetaData)
      }
      paymentHistoryList.push({
        UserMemberId: val.UserMemberId,
        paymentHistoryData: result,
      });
    });
    let oldAttendeeList = JSON.parse(JSON.stringify(clientsInfo));
    oldAttendeeList.forEach((element: any) => {
      element.paymentHistoryData = paymentHistoryList.find(
        (value: any) => value.UserMemberId === element.UserMemberId
      ).paymentHistoryData;
    });
    setAttendeesList({ clientData: oldAttendeeList });
  };

  //to get the payment profiles
  async function fetchPaymentProfiles(userMemberId: any) {
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(userMemberId);
    return result;
  }
  async function fetchTransactionHistory(PaymentGatewayTransactionHistoryId: any) {
    const service = new TenantService();
    const result = await service.ParentPaymentTransactionHistoryId(PaymentGatewayTransactionHistoryId, true);
    return result;
  }


  const items = [
    {
      text: "Charge",
      id: 1,
      disabled: !CheckPermissions(PermissionEnum["GroupServiceLevel3"]),
    },
    {
      text: "Refund",
      id: 2,
      disabled: !CheckPermissions(PermissionEnum["GroupServiceLevel3"]),
    },
    {
      text: "No Charge & No Refund",
      id: 3,
      disabled: !CheckPermissions(PermissionEnum["GroupServiceLevel3"]),
    },
  ];

  const handleBulkChargeType = (val: any) => {
    const allUserIds = attendeesList?.clientData?.map(
      (item: any) => item.UserId
    );
    const updatedClients = attendeesList?.clientData?.map((item: any) => {
      if (allUserIds.indexOf(item?.UserId) !== -1) {
        if (item?.PaymentMode === 1 || item?.PaymentMode === 3 || item?.PaymentMode === 5) {
          return {
            ...item,
            ChargeType: {
              text: "No Charge & No Refund",
              id: 3,
              disabled: false,
            },
            Charge: 0,
            Refund: 0,
          };
        } else {
          if (val?.id === 1) {
            return { ...item, ChargeType: val, Refund: 0 };
          } else if (val?.id === 2) {
            return { ...item, ChargeType: val, Charge: 0 };
          } else {
            return { ...item, ChargeType: val, Charge: 0, Refund: 0 };
          }
        }
      } else {
        return { ...item };
      }
    });
    setBulkChargeType(val);
    if (val?.id !== 1) {
      setBulkChargeAmount(0);
    }
    setAttendeesList({
      ...attendeesList,
      clientData: updatedClients,
    });
  };
  const handleBulkCharge = (val: any) => {
    const allUserIds = attendeesList?.clientData?.map(
      (item: any) => item.UserId
    );
    const updatedClients = attendeesList?.clientData?.map((item: any) => {
      if (allUserIds.indexOf(item?.UserId) !== -1) {
        return { ...item, Charge: val };
      } else {
        return { ...item };
      }
    });
    setBulkChargeAmount(val);
    setAttendeesList({
      ...attendeesList,
      clientData: updatedClients,
    });
  };
  const handleBulkRefund = (val: any) => {
    if (val > classSession?.Cost - classSession?.CancellationFee) {
      setMessage("Refund fee should be less than appointment price");
      setNotification({ ...notification, error: true });
      return true;
    }
    const allUserIds = attendeesList?.clientData?.map(
      (item: any) => item.UserId
    );
    const updatedClients = attendeesList?.clientData?.map((item: any) => {
      if (allUserIds.indexOf(item?.UserId) !== -1) {
        return { ...item, Refund: val };
      } else {
        return { ...item };
      }
    });
    setBulkRefundAmount(val);
    setAttendeesList({
      ...attendeesList,
      clientData: updatedClients,
    });
  };

  const attendeeesBtn = () => {
    let itemsList = items;
    if (
      classSessionRef.current?.PaymentOption === 2 ||
      classSessionRef.current?.PaymentOption === 3
    ) {
      itemsList = items.filter((i: any) => i?.id !== 2);
    }
    return (
      <>
        <small className="bz-ol-muted">
          {
            "Select a Cancellation Type and chargable amt for all the selected records,you can update for each user if applicable."
          }
        </small>
        <div className="bz-cancellation-type my-3">
          <div className="bzc-provider-name mb-3 mb-md-0">
            <span className="k-label">Cancellation Type</span>
            <div className="bzc-time-sec">
              <DropDownList
                id={`chargeType`}
                name={`chargeType`}
                data={itemsList}
                textField="text"
                dataItemKey="id"
                value={bulkchargeType}
                onChange={(e) => handleBulkChargeType(e.value)}
              />
            </div>
          </div>
          {bulkchargeType?.id === 1 && (
            <div className="bzc-provider-name">
              <span className="k-label">Charge Amount</span>
              <div className="bzc-time-sec">
                <NumericTextBox
                  id={`charge`}
                  name={`charge`}
                  min={0}
                  format={"c2"}
                  value={bulkchargeAmount}
                  onChange={(e) => handleBulkCharge(e.target.value)}
                />
              </div>
            </div>
          )}
          {bulkchargeType?.id === 2 && (
            <div className="bzc-provider-name">
              <span className="k-label">Refund Amount</span>
              <div className="bzc-time-sec">
                <NumericTextBox
                  id={`refund`}
                  name={`refund`}
                  min={0}
                  format={"c2"}
                  value={bulkrefundAmount}
                  onChange={(e) => handleBulkRefund(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const handleShowCancelled = (e: any) => {
    setshowCancelled(e.value);
  };

  const focusRefundInput = (e: any) => {
    var val = e.target.value;
    e.target.value = "";
    e.target.value = val;
  };
  // to select the payment profile
  const handleChangeSavedCard = (val: any, userId: any, chargeAmount: any) => {
    if (val?.CardTypeId === 8 && chargeAmount > val?.Credit) {
      const errorMsg = CreditsErrorMessage;
      setMessage(errorMsg);
      setNotification({ ...notification, error: true });
      return false
    }
    const updatedClients = attendeesList?.clientData?.map((item: any) => {
      if (item?.UserId === userId) {
        return {
          ...item,
          PaymentGatewayPaymentProfileID: val?.PaymentGatewayPaymentProfileID,
          PaymentProfileID: val?.PaymentProfileID,
          CardTypeId: val?.CardTypeId,
          BankAccountType: val?.BankAccountType,
          CardDescriptionWithoutExpiryDate:
            val?.CardDescriptionWithoutExpiryDate,
          IsCreditCard: val?.IsCreditCard,
          MaskedCCNumber: val?.MaskedCCNumber,
          ExpiryDate: val?.ExpiryDate,
          CustomerProfileID: val?.CustomerProfileID,
          Credit: val?.Credit,
        };
      } else {
        return { ...item };
      }
    });
    setAttendeesList({
      ...attendeesList,
      clientData: updatedClients,
    });
  };
  // to select the payment profile
  const handleChargeType = (val: any, userId: any) => {
    const updatedClients = attendeesList?.clientData?.map((item: any) => {
      if (item?.UserId === userId) {
        if (val?.id !== 1) {
          return { ...item, ChargeType: val, Charge: 0 };
        } else {
          return { ...item, ChargeType: val };
        }
      } else {
        return { ...item };
      }
    });

    setAttendeesList({
      ...attendeesList,
      clientData: updatedClients,
    });
  };
  // to select the payment profile
  const handleCharge = (val: any, dataItem: any) => {
    let value = val
    if (val < 0) {
      value = 0
    }
    if (dataItem?.PaymentGatewayPaymentProfileID && dataItem?.CardTypeId === 8 && value > dataItem?.Credit) {
      const errorMsg = CreditsErrorMessage;
      setMessage(errorMsg);
      setNotification({ ...notification, error: true });
      return false
    }
    const updatedClients = attendeesList?.clientData?.map((item: any) => {
      if (item?.UserId === dataItem?.UserId) {
        return { ...item, Charge: value };
      } else {
        return { ...item };
      }
    });

    setAttendeesList({
      ...attendeesList,
      clientData: updatedClients,
    });
  };
  const handleRefund = (val: any, dataItem: any) => {
    const userId = dataItem?.UserId
    const totalAmount = dataItem?.DropInPrice - classSessionRef.current?.CancellationFee
    if (val > totalAmount) {
      setMessage("Refund fee should be less than appointment price");
      setNotification({ ...notification, error: true });
      return true;
    }
    const updatedClients = attendeesList?.clientData?.map((item: any) => {
      if (item?.UserId === userId) {
        return { ...item, Refund: val };
      } else {
        return { ...item };
      }
    });

    setAttendeesList({
      ...attendeesList,
      clientData: updatedClients,
    });
  };

  const validateUserData = (data: any) => {
    console.log(data)
    let res: any = { status: true };
    if (data?.PaymentMode === 1) {
      res.payload = {
        ClassSessionId: props?.classSession?.ClassSessionId,
        ScheduleId: data.ScheduleId,
        UserId: data.UserId,
        ClassInstanceId: data.ClassInstanceId,
        Refund: 0,
        Charge: 0,
        ChargeType: "NOCHARGE",
      };
      return res;
    } else if (data?.ChargeType?.id) {
      let collectTax = false
      if (data?.paymentHistoryData?.length > 0) {
        collectTax = true
      }
      switch (data.ChargeType.id) {
        case 1:
          if (!data.Charge || !data.PaymentProfileID) {
            res.status = false;
            if (!data.Charge && !data.PaymentProfileID) {
              res.key = ["Charge", "PaymentProfileID"];
            } else if (!data.Charge) {
              res.key = ["Charge"];
            } else if (!data.PaymentProfileID) {
              res.key = ["PaymentProfileID"];
            }
          }
          res.payload = {
            ClassSessionId: props?.classSession?.ClassSessionId,
            ScheduleId: data.ScheduleId,
            UserId: data.UserId,
            ClassInstanceId: data.ClassInstanceId,
            UserMemberId: data.UserMemberId,
            Charge: data.Charge,
            ChargeType: "CHARGE",
            PaymentProfile: {
              CustomerProfileId: data.CustomerProfileID,
              PaymentGatewayPaymentProfileId:
                data.PaymentGatewayPaymentProfileID,
              PaymentProfileId: data.PaymentProfileID,
            },
            TransactionId: "",
            IsPaymentSuccess: true,
            CanCollectTaxes: collectTax,
          };
          break;
        case 2:
          if (!data.Refund) {
            res.status = false;
            res.key = ["Refund"];
          }

          res.payload = {
            ClassSessionId: props?.classSession?.ClassSessionId,
            ScheduleId: data.ScheduleId,
            CanCollectTaxes: collectTax,
            UserId: data.UserId,
            ClassInstanceId: data.ClassInstanceId,
            UserMemberId: data.UserMemberId,
            Refund: data.Refund,
            ChargeType: "REFUND",
          };
          break;
        case 3:
          res.payload = {
            ClassSessionId: props?.classSession?.ClassSessionId,
            ScheduleId: data.ScheduleId,
            UserId: data.UserId,
            UserMemberId: data.UserMemberId,
            ClassInstanceId: data.ClassInstanceId,
            Refund: 0,
            Charge: 0,
            ChargeType: "NOCHARGE",
            CanCollectTaxes: collectTax,
          };
          break;
        default:
          res.status = false;
          res.key = [];
          return res;
      }
      return res;
    } else {
      res.status = false;
      res.key = "ChargeType";
      return res;
    }
  };
  const handleSubmit = () => {
    let text = `Are you sure you want to cancel appointment?`;
    if (!props?.isInhouse || props?.fromDashboard) {
      text = `Are you sure you want to cancel appointment?`;
    }
    let selectedClients = [];
    if (attendeesList?.clientData.length === 1) {
      selectedClients = attendeesList?.clientData;
    } else {
      const allUserIds = attendeesList?.clientData?.map(
        (item: any) => item.UserId
      );
      selectedClients = attendeesList?.clientData?.filter((item: any) => {
        if (allUserIds.indexOf(item?.UserId) !== -1) {
          return item;
        }
      });
    }
    let ChangeScheduleStatusList = [];
    let formValid = [];
    for (let index = 0; index < selectedClients.length; index++) {
      const value = selectedClients[index];
      const validateData = validateUserData(value);
      if (!validateData.status) {
        formValid.push(index);
        selectedClients[index].validations = validateData.key;
      }
      ChangeScheduleStatusList.push(validateData.payload);
    }
    if (formValid.length > 0) {
      setAttendeesList({
        ...attendeesList,
        clientData: selectedClients,
      });
      return true;
    }

    setCancelReq(ChangeScheduleStatusList);
    setbtnLoading(true);
    setvisible(true);
    setCancelledText(text);
  };

  const showErrorMessage = (key: any, data: any, mainKey: any, value: any) => {
    if (!value && data?.validations?.length > 0) {
      if (data.validations.includes(key)) {
        return <Error>{`${mainKey} is required`}</Error>;
      }
    }
    return <></>;
  };
  const handleCalculateSurcharges = (
    refundAmt: any,
    TaxItems: any
  ) => {
    const mainTaxItems = TaxItems?.filter((i: any) => i?.MetaData);
    const TaxesArray = mainTaxItems?.map((i: any) => {
      const percentage = parseFloat(i?.MetaData?.split(":")[1]);
      const amount = (refundAmt * percentage) / 100;
      const calcAmt = CustomToFixed(amount, 2)
      return {
        Description: i?.Description,
        Percentage: percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount

    }, 0);
    return {
      Taxes: TaxesArray,
      TaxAmountToRefund: taxAmt
    }
  };
  const AttendesRender = (text: any) => {
    let item = text.dataItem;
    let session = classSessionRef.current;
    const Attributes = classSessionRef.current?.Attributes
    const AttributesValues = Attributes && typeof Attributes === "string"
      ? JSON.parse(Attributes)
      : Attributes;
    let PaymentMode = ""
    if (item?.PaymentMode === 1) {
      PaymentMode = "Packages"
    } else if (item?.PaymentMode === 5) {
      PaymentMode = "Complimentary"
    } else if (item?.PaymentMode === 3) {
      PaymentMode = AttributesValues?.NonPaidLabel
    } else if (item?.PaymentMode === 0) {
      PaymentMode = "Cash"
    } else {
      PaymentMode = "Not Paid"
    }

    let PaymentModeText = ""
    if (item?.PaymentMode === 1) {
      // PaymentModeText="Since the Group was purchased through a package, upon cancellation, the credits will be added back to the client account"
      PaymentModeText = "Redemeed via Packages"
    } else if (item?.PaymentMode === 5) {
      PaymentModeText = "Redeemed via Complimentary"
    } else if (item?.PaymentMode === 3) {
      // PaymentModeText="Since the Group Cost is Zero Dollar"
      PaymentModeText = ""
    }

    let chargeAmount = 0;
    if (!item.hasOwnProperty("Charge")) {
      if (session?.CancellationFee > session?.Cost) {
        chargeAmount = session?.CancellationFee - session?.Cost;
      } else {
        chargeAmount = 0;
      }
    } else {
      chargeAmount = item?.Charge;
    }
    let refundAmount = 0;
    if (!item.hasOwnProperty("Refund")) {
      if (session?.CancellationFee === 0) {
        refundAmount = session?.Cost;
      } else if (session?.CancellationFee > session?.Cost) {
        refundAmount = 0;
      } else if (session?.CancellationFee < session?.Cost) {
        refundAmount = session?.Cost - session?.CancellationFee;
        console.log(refundAmount)
      }
    } else {
      refundAmount = item?.Refund;

    }
    let chargeTypeValue: any = {};
    if (!item.hasOwnProperty("ChargeType")) {
      if (session?.CancellationFee === session?.Cost) {
        chargeTypeValue = { id: 3, text: "No Charge & No Refund" };
      } else if (session?.CancellationFee > session?.Cost) {
        chargeTypeValue = { id: 1, text: "Charge" };
      } else if (session?.CancellationFee < session?.Cost) {
        chargeTypeValue = { id: 2, text: "Refund" };
      }
    } else {
      chargeTypeValue = item?.ChargeType;
    }
    if (item?.PaymentMode === 1 || item?.PaymentMode === 3 || item?.PaymentMode === 5) {
      chargeTypeValue = { id: 3, text: "No Charge & No Refund" };
    }
    let itemsList = items;
    let cardClassName = "";
    if (item?.PaymentMode === 2 || item?.PaymentMode === 3) {
      itemsList = items.filter((i: any) => i?.id !== 2);
      cardClassName = "zero-dollar-payment";
    }
    if (cancelSession) {
      itemsList = items.filter((i: any) => i?.id !== 1);
    }
    let Taxes: any = [];
    let TaxAmountToRefund = 0
    if (chargeTypeValue?.id === 2 && item?.paymentHistoryData?.length > 0 && item?.CanCollectTaxes) {
      const historyData = item?.paymentHistoryData
      const obj = handleCalculateSurcharges(refundAmount, historyData)
      Taxes = obj.Taxes
      TaxAmountToRefund = obj.TaxAmountToRefund
    }
    const refundAmountSelected = chargeTypeValue?.id === 2 && refundAmount > 0 && item?.CanCollectTaxes && TaxAmountToRefund > 0 ? 'refund-amount' : '';
    return (
      <div
        className={
          item?.loading || item?.failed
            ? "card bzc-attndees-card bz-shadow-class bz-default-cursor loading"
            : "card bzc-attndees-card bz-shadow-class bz-default-cursor"
        }
        key={item?.UserId}
      >
        <div className="card-body">
          <div className={!(item?.PaymentMode === 0) ? "package" : ""}>
            <div
              className={
                chargeTypeValue?.id === 1 || chargeTypeValue?.id === 2
                  ? chargeTypeValue?.id === 1 && chargeAmount > 0
                    ? `bz-cancellation-card-row ${cardClassName} bz-charge-amount`
                    : `bz-cancellation-card-row charge-dropdown ${cardClassName} ${refundAmountSelected}`
                  : `bz-cancellation-card-row ${cardClassName}`
              }
            >
              <div className="column-1">
                {/* <Checkbox
                  checked={
                    selectedClientData.findIndex(
                      (val: any) => val.UserId === item?.UserId
                    ) !== -1
                      ? true
                      : false
                  }
                  name={item?.UserId}
                  //   disabled={session?.Status === 2 || selectedTab !== 0}
                  disabled={attendeesList?.clientData?.length <= 1}
                  className={"mr-2"}
                  onChange={(e) => handleChange(item)}
                /> */}
                <Avatar
                  type="icon"
                  size="large"
                  shape={"circle"}
                  themeColor={"inverse"}
                >
                  {item.ProfileImage ? (
                    <img src={getFullImageUrl(item.ProfileImage)} alt="profileImg" />
                  ) : (
                    <i className="far fa-user-circle"></i>
                  )}
                </Avatar>
                {item.Name}
              </div>
              {item?.PaymentMode === 0 ? (
                <>
                  <div className="column-2">
                    <div className="bzc-date-sec">
                      <small>Price</small>
                      <h5 className="text-capitalize">
                        {CurrencyFormat(item?.DropInPrice)}
                      </h5>
                    </div>
                  </div>
                  <div className="column-3">
                    <div className="bzc-time-sec">
                      <small>Cancellation Fee</small>
                      <h5 className="text-capitalize">
                        {CurrencyFormat(session?.CancellationFee)}
                      </h5>
                    </div>
                  </div>
                </>
              ) : (
                <div className="column-2">
                  <div className="bzc-date-sec">
                    {item?.PaymentMode === 3 ? (
                      <>
                        <h5 className="text-capitalize">{"Non Paid"}</h5>
                      </>
                    ) : (
                      <>
                        <small>{`Paid By`}</small>
                        <h5 className="text-capitalize">{PaymentMode}</h5>
                      </>
                    )}
                  </div>
                </div>
              )}
              {item?.PaymentMode !== 1 &&
                item?.PaymentMode !== 5 &&
                item?.PaymentMode !== 3 ? (
                <div className="column-4">
                  <div className="bzc-provider-name">
                    <small>Cancellation Type</small>
                    <div className="bzc-time-sec">
                      <DropDownList
                        id={`chargeType${item?.UserId}`}
                        name={`chargeType${item?.UserId}`}
                        data={itemsList}
                        textField="text"
                        dataItemKey="id"
                        disabled={!props?.isInhouse || item?.PaymentMode === 1}
                        value={chargeTypeValue}
                        onChange={(e) =>
                          handleChargeType(e.value, item?.UserId)
                        }
                      />
                    </div>
                    {showErrorMessage(
                      "ChargeType",
                      item,
                      "Cancellation Type",
                      chargeTypeValue
                    )}
                  </div>
                </div>
              ) : (
                <div className="column-4">
                  <small>{PaymentModeText}</small>
                </div>
              )}
              {chargeTypeValue && chargeTypeValue?.id === 1 && (
                <div className="column-5">
                  <div className="bzc-provider-name">
                    <small>Charge Amount ($)</small>
                    <div className="bzc-time-sec">
                      <Input
                        type="number"
                        id={`charge${item?.UserId}`}
                        name={`charge${item?.UserId}`}
                        min={0}
                        // format={"c2"}
                        step={"any"}
                        disabled={!props?.isInhouse}
                        value={chargeAmount}
                        onChange={(e) =>
                          handleCharge(e.target.value, item)
                        }
                        autoFocus
                      />
                    </div>
                    {showErrorMessage(
                      "Charge",
                      item,
                      "Charge amount",
                      chargeAmount
                    )}
                  </div>
                </div>
              )}
              {chargeTypeValue && chargeTypeValue?.id === 2 && (
                <div className="column-6">
                  <div className="bzc-provider-name">
                    <small>Refund Amount($)</small>
                    <div className="bzc-time-sec">
                      <Input
                        type="number"
                        id={`refund${item?.UserId}`}
                        name={`refund${item?.UserId}`}
                        min={0}
                        step={"any"}
                        onFocus={focusRefundInput}
                        ref={refundInputRef}
                        disabled={!props?.isInhouse}
                        value={refundAmount}
                        onChange={(e) => handleRefund(e.target.value, item)}
                        autoFocus
                      />
                    </div>
                    <Hint>
                      {`Available Amount to Refund :${CurrencyFormat(
                        item?.DropInPrice -
                        classSessionRef.current?.CancellationFee
                      )}`}
                    </Hint>
                    {showErrorMessage(
                      "Refund",
                      item,
                      "Refund amount",
                      refundAmount
                    )}
                  </div>
                </div>
              )}
              {chargeTypeValue?.id === 2 &&
                refundAmount > 0 &&
                item?.CanCollectTaxes &&
                TaxAmountToRefund > 0 && (
                  <>
                    <div className="column-7">
                      <div className="bzc-time-sec">
                        <small>
                          <Tooltip
                            content={(tooltipProps: any) => {
                              return (
                                <TooltipContentRefundTax
                                  title={tooltipProps.title}
                                  data={Taxes}
                                  TotalTaxAmount={TaxAmountToRefund}
                                />
                              );
                            }}
                            tooltipClassName="bz-tooltip-table"
                          >
                            <a
                              title="Surcharges"
                              style={{
                                borderBottom: "1px dotted #0d6efd",
                                cursor: "pointer",
                              }}
                            >
                              {"Surcharges to be refunded"}
                            </a>
                          </Tooltip>
                        </small>
                        <h5 className="text-capitalize">
                          {CurrencyFormat(TaxAmountToRefund)}
                        </h5>
                      </div>
                    </div>
                    <div className="column-8">
                      <div className="bzc-time-sec">
                        <small>{"Total Refund Amount"}</small>
                        <h5 className="text-capitalize">
                          {CurrencyFormat(
                            Number(refundAmount) + Number(TaxAmountToRefund)
                          )}
                        </h5>
                      </div>
                    </div>
                  </>
                )}
              {chargeTypeValue &&
                chargeTypeValue?.id === 1 &&
                chargeAmount > 0 && (
                  <div className="column-7 add-payment-profile">
                    <div className="bzc-provider-name">
                      <small>Payment Profile</small>
                      <div className="bzc-time-sec">
                        <DropDownList
                          id={`paymentProfile${item?.UserId}`}
                          name={`paymentProfile${item?.UserId}`}
                          data={item?.PaymentProfiles}
                          textField="MaskedCCNumber"
                          // dataItemKey="PaymentProfileID"
                          valueRender={(
                            el: React.ReactElement<HTMLSpanElement>,
                            value: any
                          ) => PaymentValueRender(el, value, item)}
                          itemRender={PaymentItemRender}
                          onChange={(e) => {
                            handleChangeSavedCard(e.value, item?.UserId, chargeAmount);
                          }}
                          disabled={
                            (chargeTypeValue && chargeTypeValue?.id !== 1) ||
                            chargeAmount <= 0
                          }
                        />
                      </div>
                      {showErrorMessage(
                        "PaymentProfileID",
                        item,
                        "Payment profile",
                        item?.MaskedCCNumber
                      )}
                    </div>
                    <div className="addNewBtn">
                      <DropDownButton
                        text="Add New"
                        onItemClick={(e: any) =>
                          onCardOrBankAdd(e, item?.UserMemberId)
                        }
                        items={PaymentMethodItems.filter(
                          (i: any) => i?.visible === true
                        )}
                        look="flat"
                        className="btn btn-link add-new-btn"
                        popupSettings={{
                          popupClass: "payNow-addCard-popup",
                          anchorAlign: {
                            horizontal: "right",
                            vertical: "bottom"
                          },
                          popupAlign: {
                            horizontal: "right",
                            vertical: "top"
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              {item?.loading && (
                <div className="column-8 loading">
                  <div className="d-flex align-items-center">
                    {"Processing "} <Loader size="small" type={"pulsing"} />
                  </div>
                </div>
              )}
              {item?.failed && (
                <div className="column-8">
                  {" "}
                  <span
                    className={"bz-statuses bz-failed"}
                  >{`Failed - ${item?.failedReason}`}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleRemove = (val: boolean) => {
    if (val) {
      handleCancelSession(cancelReq);
    }
    toggleDialog();
  };

  const toggleDialog = () => {
    setvisible(!visible);
  };

  const handleCancelSession = async (req: any) => {
    let redeem = false
    if (attendeesList?.clientData?.length === 1 && attendeesList?.clientData[0]?.PaymentMode === 1) {
      redeem = true
    }
    let finalArray: any = [];
    let newArray: any = [];
    let oldArray: any = [];
    const allUserIds = req.map((item: any) => item.UserId);
    const userSession = GetLocalStore("tenantUserSession");
    const updatedClientInfo = attendeesList?.clientData.map((i: any) => {
      if (allUserIds.indexOf(i?.UserId) !== -1) {
        return { ...i, loading: true, failed: false, failedReason: "" };
      } else {
        const val = { ...i, failed: false, failedReason: "", loading: false };
        oldArray.push(val);
        return val;
      }
    });
    setAttendeesList({
      ...attendeesList,
      clientData: updatedClientInfo,
    });
    let reqItems: any = [];
    let reqChargeItems: any = [];
    req.forEach((item: any) => {
      if (item.ChargeType === "CHARGE") {
        reqChargeItems.push(item);
      } else {
        reqItems.push(item);
      }
    });

    if (reqChargeItems.length > 0) {
      for (let i = 0; i < reqChargeItems.length; i++) {
        const arr = [reqChargeItems[i]];
        const reqChargeItemsObj: any = {
          ClubDateTime: new Date(),
          ApplicationType: userSession?.CurrentApplicationType,
          CurrentUserId: userSession?.UserId,
          classSessionUserMappings: arr,
          CanEmailToClient: showCancelled,
        };
        const res = await CancelChargeType(reqChargeItemsObj);
        if (res) {
          newArray.push(res);
        }
      }
    }
    if (cancelSession && newArray?.length === 0) {
      const sessionItem = {
        ClassSessionId: props?.classSession?.ClassSessionId,
      };
      reqItems.push(sessionItem);
    }
    if (reqItems.length > 0) {
      const reqItemsObj: any = {
        ClubDateTime: new Date(),
        ApplicationType: userSession?.CurrentApplicationType,
        CurrentUserId: userSession?.UserId,
        classSessionUserMappings: reqItems,
        CanEmailToClient: showCancelled,
      };
      const reqItemUserIds = reqItems.map((item: any) => item.UserId);
      const result = await service.cancelClassSession(reqItemsObj);
      if (result?.ResponseCode === 100) {
        if (result?.Items?.length > 0) {
          if (result?.Items[0]?.ErrorReason) {
            const failedReason = result?.Items[0]?.ErrorReason;
            attendeesList?.clientData.forEach((i: any) => {
              if (reqItemUserIds.indexOf(i?.UserId) !== -1) {
                newArray.push({
                  ...i,
                  failed: true,
                  failedReason,
                  loading: false,
                });
              }
            });
          }
        }
      } else {
        const failedReason =
          result?.ErrorMessages.length > 0
            ? result?.ErrorMessages?.[0]
            : "Error while cancelling appointment";
        attendeesList?.clientData.forEach((i: any) => {
          if (reqItemUserIds.indexOf(i?.UserId) !== -1) {
            newArray.push({ ...i, failed: true, failedReason, loading: false });
          }
        });
        setbtnLoading(false);
        setMessage(failedReason);
        setNotification({ ...notification, error: true });
        fetchClients();
        return
      }
    }

    finalArray = [...oldArray, ...newArray];
    if (finalArray.length > 0) {
      setAttendeesList({
        ...attendeesList,
        clientData: finalArray,
      });

    } else {
      let successMsg = "Appointment Cancelled Successfully"
      const checkRefund = reqItems?.some((i: any) => i?.ChargeType === "REFUND")
      if (checkRefund) {
        successMsg = "Appointment cancelled. Refund processing in 24 hours."
      }
      if (redeem) {
        successMsg = "Appointment successfully cancelled and the appointment has been restored into the group package"
      }
      setMessage(successMsg);
      setNotification({ ...notification, success: true });
      setSubmit(true)
    }
  };

  async function CancelChargeType(reqChargeItemsObj: any) {
    const newArray = [];
    const reqChargeItemsUserIds =
      reqChargeItemsObj?.classSessionUserMappings.map(
        (item: any) => item.UserId
      );
    const result = await service.cancelClassSession(reqChargeItemsObj);
    if (result?.ResponseCode === 100) {
      if (result?.Items?.length > 0) {
        if (result?.Items[0]?.ErrorReason) {
          const failedReason = result?.Items[0]?.ErrorReason;
          const findItem = attendeesList?.clientData.find(
            (jj: any) =>
              jj.UserId ===
              reqChargeItemsObj?.classSessionUserMappings[0]?.UserId
          );
          newArray.push({
            ...findItem,
            failed: true,
            failedReason,
            loading: false,
          });
        }
      }
    } else {
      const failedReason =
        result?.ErrorMessages.length > 0
          ? result?.ErrorMessages?.[0]
          : "Error while cancelling appointment";
      attendeesList?.clientData.forEach((i: any) => {
        if (reqChargeItemsUserIds.indexOf(i?.UserId) !== -1) {
          newArray.push({ ...i, failed: true, failedReason, loading: false });
        }
      });
      setMessage(failedReason);
      setNotification({ ...notification, error: true });
      fetchClients();
    }
    if (newArray.length > 0) {
      return newArray?.[0];
    } else {
      return null;
    }
  }
  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };

  const CancelEntireSession = (e: any) => {
    setCancelSession(e);
  };
  const Attributes = classSessionRef.current?.Attributes
  const groupCancellation = props.fromDashboard ? "bz-cancellation-client-dashboard" : "bz-cancellation-inhouse";

  if (loading) {
    return <BuzopsLoader type="list" />
  }
  return (
    <div>
      <Card className={`m-0 bz-attendeesList-container bz-cancellation-page bz-cancellation-page-new ${groupCancellation}`}>
        <CardHeader className="d-flex justify-content-between px-0 pt-1 pb-0">
          {/* <CardTitle className="mb-1">
            {"Attendees List"} <br />
          </CardTitle> */}
          {props?.fromDashboard && (
            <>
              <h5 className="mb-1 bz_fs_1_5">
                <button
                  className="btn btn-primary bz-back-btn btn-sm"
                  onClick={() => props.handleWindow()}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
                &nbsp;Cancel Appointment
              </h5>
            </>
          )}
        </CardHeader>
        <CardBody className="px-0">
          <>
            <div className="card bzc-sessions-list-card bz-sessionRoaster-card bz-default-cursor mb-3 p-2">
              <div className="bzc-sessions-list-row">
                <div className="bzc-date-sec d-flex">
                  {classSessionRef.current?.ClassName}{Attributes && Attributes !== "" && Attributes !== "null" && <Location Attributes={Attributes} showDetails={true} VirtualMeetingLink={classSessionRef.current?.VirtualMeetingLink} />}
                </div>
                <div className="bzc-date-sec">
                  <i className="far fa-calendar-alt mr-0"></i>
                  {getWeekdayFormat(classSessionRef.current?.ClassSessionStartDate)}
                  ,&nbsp;
                  {getDayFormat(classSessionRef.current?.ClassSessionStartDate)}
                  &nbsp;
                  {getMonthFormat(classSessionRef.current?.ClassSessionStartDate)}
                  &nbsp;
                  {getYearFormat(classSessionRef.current?.ClassSessionStartDate)}
                </div>
                <div className="bzc-date-sec">
                  <span className={""}>
                    <i className="far fa-clock mr-1"></i>
                    {getTimeFormat(
                      classSessionRef.current?.ClassSessionStartDate
                    )}{" "}
                    - {getTimeFormat(classSessionRef.current?.ClassSessionEndTime)}
                  </span>
                </div>
              </div>
            </div>
            <div>
            </div>
            {attendeesList?.clientData?.length === 0 ? (
              <>
                <div className="bz-norecords-available">
                  {"No Attendees Available"}
                </div>
              </>
            ) : (
              <ListView
                data={attendeesList.clientData || []}
                item={AttendesRender}
                style={{
                  width: "100%",
                }}
                className="bzc-attendees-list mt-2"
              />
            )}

            <div className={"float-right pt-2"}>
              <Button onClick={() => props.handleWindow()} disabled={btnLoading}>Cancel</Button>
              {attendeesList?.clientData?.length > 0 && (
                <BuzopsButton
                  label={"Apply"}
                  onClick={handleSubmit}
                  loading={btnLoading}
                  disable={btnLoading}
                  key={btnLoading.toString()}
                />
              )}
            </div>
          </>
        </CardBody>
      </Card>
      {visible && (
        // eslint-disable-next-line react/jsx-no-undef
        <Dialog onClose={() => toggleDialog()}>
          <p className={"dialogContainer"}>{cancelledText}</p>
          <DialogActionsBar>
            <Button onClick={() => {
              handleRemove(false);
              setbtnLoading(false);
            }}>
              {"No, keep it."}
            </Button>

            <Button primary={true} onClick={() => handleRemove(true)}>
              Yes
            </Button>
          </DialogActionsBar>
          <p className={"dialogText"}>
            <Switch
              checked={showCancelled}
              onChange={(e) => handleShowCancelled(e)}
            />
            {props?.isInhouse ? props?.fromDashboard ? <span>Client will be notified via email</span> :
              <> <span>Enrolled clients will be notified via email</span></> : <span>Recieve cancellation notification</span>}
          </p>
        </Dialog>
      )}
      <BuzopsNotification
        notification={notification}
        message={message}
        handleNotification={(type: any) => handleNotification(type)}
      />
      {showPaymentDialog && PaymethodDialog()}
    </div>
  );
};

export default Cancellation;
