import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Switch } from '@progress/kendo-react-inputs';
import withNotification from 'components/Hoc/withNotification';
import { PaymentItemRender, PaymentValueRender } from 'components/Payment/PaymentRenderItems';
import PaymentModalCreation from 'components/PaymentCheckout/PaymentModalCreation';
import BuzopsButton from 'generic-components/BuzopsButton';
import React, { useEffect, useRef, useState } from 'react'
import { TenantService } from 'services/tenant/index.service';
import { CurrencyFormat, checkIfUserCanAddBankAccount } from 'utils';
import { CreditsErrorMessage } from 'utils/form-utils';
import { showWaived } from 'utils/permission-utils';
import { GetLocalStore } from 'utils/storage';


const PayNowDeclinedPayments = (props: any) => {
    const [paynowItem, setPayNowItem] = useState<any>(null);
    const [paymentProfile, setPaymentProfile] = useState<any>([]);
    const payNowRef: any = useRef(null);
    const [selectedPaymentProfile, setSelectedPaymentProfile] =
        useState<any>(undefined);
    const [btnloading, setBtnLoading] = useState<any>(false);
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [addNewItem, setAddNewItem] = useState<any>(null);
    const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
    const [paymentGatewayType, setPaymentGatewayType] =
      React.useState<any>(paymentGatewaytype);
    const service = new TenantService();

    const items = [
        {
          iconClass: "fa fa-credit-card pr-1",
          text: "Add credit card",
          key: "addCard",
          visible: true,
        },
        {
          iconClass: "fa fa-university pr-1",
          text: "Add bank account",
          key: "addBank",
          visible: paymentGatewayType === 1 ? true : false,
        },
      ];

    useEffect(() => {
        fetchDeclinedPayments();
        fetchPaymentProfiles(props?.userDetails?.UserMemberId);
    }, []);

    const fetchDeclinedPayments = async () => {
        const req = {
            UserMemberId: props?.userDetails?.UserMemberId,
            EntityId: props?.transactionData.EntityId,
        };

        const service = new TenantService();

        const result = await service.declinedPayments(req);
        if (result) {
            setPayNowItem(result.Items[0]);
        }
    }

    const fetchPaymentProfiles = async (userMemberId: any) => {
        const result = await service.paymentProfile(userMemberId);
        if (result) {
            if (result.length > 0) {
                // const test = result.filter((item: any) => item.CardTypeId !== -1);
                setPaymentProfile(result);
            }
        }
    }



    const togglePayNowDialog = () => {
        //setShowPayNowDialog(false);
        setPayNowItem(null);
        setSelectedPaymentProfile(undefined);
        props?.handleWindow();
    };


    console.log('PaymentProfile', paymentProfile);



    const onCardOrBankAdd = (e: any, memberId: any) => {
        setShowPaymentDialog(true);
        setAddNewItem(e.item.key);
    };

    const handleClosePaymethodDialog = async () => {
        setShowPaymentDialog(!showPaymentDialog);
    };
    const handleSuccessClosePaymethodDialog = async () => {
        setShowPaymentDialog(!showPaymentDialog);
        refreshPaymentProfiles();
    };

    const refreshPaymentProfiles = () => {
        fetchPaymentProfiles(props?.userDetails?.UserMemberId);
    };


    const PaymethodDialog = () => {
        return (
            <>
                <Dialog
                    className="bz-addCard-dialog"
                    title={`${checkIfUserCanAddBankAccount() ? "Add Bank or Card" : "Add Card"
                        }`}
                    onClose={() => handleClosePaymethodDialog()}
                    style={{ zIndex: 9999 }}
                >
                    <PaymentModalCreation
                        staffPage={true}
                        addNewItem={addNewItem}
                        handleClosePaymethodDialog={handleClosePaymethodDialog}
                        handleSuccessClosePaymethodDialog={
                            handleSuccessClosePaymethodDialog
                        }
                        UserMemberId={props?.userDetails?.UserMemberId}
                    />
                </Dialog>
            </>
        );
    };

    const handleChangeSavedCard = (val: any) => {
        setSelectedPaymentProfile(val);
    };

    const submitUnPaidDues = async () => {
        if (paynowItem?.IsWaivePayment || selectedPaymentProfile) {
            if (
                selectedPaymentProfile &&
                !paynowItem?.IsWaivePayment &&
                selectedPaymentProfile?.CardTypeId === 8 &&
                paynowItem?.Amount > selectedPaymentProfile?.Credit
            ) {
                const errorMsg = CreditsErrorMessage;
                props?.handleNotificationMessage(errorMsg, "error");
                return false;
            }
            setBtnLoading(true);
            const req = {
                PaymentProfileId:
                    selectedPaymentProfile?.PaymentGatewayPaymentProfileID || 0,
                PaymentGatewayTransactionHistoryId:
                    paynowItem?.PaymentGatewayTransactionHistoryId,
                PenaltyAmount: 0,
                Amount: paynowItem?.Amount,
                IsWaivePayment: paynowItem?.IsWaivePayment || false,
                // IsWaivePayment: false,
                EnablePreviousSession: true,
                WaiveComments: paynowItem?.WaiveComments || "",
                // WaiveComments: "",
                PaymentMadeFor: paynowItem?.PaymentMadeFor,
            };
            const res = await service.saveDeclinedPayments(req);
            setBtnLoading(false);
            if (res.ResponseCode === 100) {
                //togglePayNowDialog();
                const successMsg = "Unpaid Dues paid successfully";
                props?.handleNotificationMessage(successMsg, "success");
                props?.onSuccessfullPayment();
                //await fetchAllApis();
            } else {
                const errorMsg =
                    res?.ErrorMessages?.[0] || "Error While Paying Unpaid Dues";
                props?.handleNotificationMessage(errorMsg, "error");
            }
        } else {
            const errorMsg = "Please select payment profile";
            props?.handleNotificationMessage(errorMsg, "error");
        }
    };

    const handlePayChange = (val: any, name: string) => {
        setPayNowItem({ ...paynowItem, [name]: val });
    };
    if (!paynowItem) {
        return <div></div>;
    }
    return (
        <>
            <div>
                <hr />
                <h5 className="mb-3 pt-3 bz_fs_1_5">Pay Unpaid Dues</h5>
                <div className="bz-mt-2rem bz-mb-2_5rem" ref={payNowRef}>
                    <div className="bz-content-text-row">
                        <div className="bz-content-title text-right">Transaction Id</div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">{paynowItem?.TransactionId}</div>
                    </div>
                    <div className="bz-content-text-row">
                        <div className="bz-content-title text-right">Entity Name</div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">{paynowItem?.EntityName}</div>
                    </div>
                    <div className="bz-content-text-row" style={{ fontWeight: 600 }}>
                        <div className="bz-content-title text-right">Amount</div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                            {CurrencyFormat(paynowItem?.Amount)}
                        </div>
                    </div>
                    {props?.staffPage && showWaived(paynowItem) ? (
                        <div className="bz-content-text-row">
                            <div className="bz-content-title text-right">
                                Do you want to waive this payment ?
                            </div>
                            <span className="bz-colon text-center">:</span>
                            <div className="bz-content-body">
                                <Switch
                                    checked={paynowItem?.IsWaivePayment || false}
                                    onChange={(e) => handlePayChange(e.value, "IsWaivePayment")}
                                />
                            </div>
                        </div>
                    ) : null}
                    {paynowItem?.IsWaivePayment ? (
                        <div className="bz-content-text-row">
                            <div className="bz-content-title text-right">Comments</div>
                            <span className="bz-colon text-center">:</span>
                            <div className="bz-content-body">
                                <textarea
                                    style={{ maxWidth: "300px", fontSize: "14px" }}
                                    value={paynowItem?.WaiveComments || ""}
                                    onChange={(e) =>
                                        handlePayChange(e.target.value, "WaiveComments")
                                    }
                                ></textarea>
                            </div>
                        </div>
                    ) : (
                        <div className="bz-content-text-row">
                            <div className="bz-content-title text-right">Payment Method</div>
                            <span className="bz-colon text-center">:</span>
                            <div className="bz-content-body">
                                <div className="d-flex">
                                    <DropDownList
                                        id={"selectedPaymentProfile"}
                                        name={"selectedPaymentProfile"}
                                        data={paymentProfile}
                                        listNoDataRender={() => "No Payment Methods"}
                                        textField="MaskedCCNumber"
                                        dataItemKey="PaymentProfileID"
                                        className={"bz-payUnpaid-dues-dropdown"}
                                        valueRender={(
                                            el: React.ReactElement<HTMLSpanElement>,
                                            value: any
                                        ) => PaymentValueRender(el, value, selectedPaymentProfile)}
                                        itemRender={PaymentItemRender}
                                        onChange={(e) => {
                                            handleChangeSavedCard(e.value);
                                        }}
                                    />

                                    <div className="addNewBtn pl-1">
                                        <DropDownButton
                                            text="Add New"
                                            onItemClick={(e: any) =>
                                                onCardOrBankAdd(e, props?.userDetails?.UserMemberId)
                                            }
                                            items={items.filter((i: any) => i?.visible === true)}
                                            look="flat"
                                            className="btn btn-link add-new-btn"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="bz-content-text-row mt-4 pt-2">
                        <div className="bz-content-title text-right"></div>
                        <span className="bz-colon text-center"></span>
                        <div className="bz-content-body">
                            <Button className="ml-0" onClick={() => togglePayNowDialog()}>
                                Cancel
                            </Button>
                            <BuzopsButton
                                primary={true}
                                label={"Submit"}
                                onClick={() => submitUnPaidDues()}
                                disabled={btnloading}
                                loading={btnloading}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {showPaymentDialog && PaymethodDialog()}
        </>
    );
};


export default withNotification(PayNowDeclinedPayments);