import React, { useState } from "react";
import { BankAccountTypes, cardTypes } from "utils/form-utils";
import images from "utils/images";
import BuzopsButton from "generic-components/BuzopsButton";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import {
  BankAccountValidator,
  requiredValidator,
  routingNumberValidator,
} from "validators/validator";
import { FormDropDownList, FormInput } from "utils/form-components";
import { Button } from "@progress/kendo-react-buttons";
import { TenantService } from "services/tenant/index.service";
import payment from "payment";
import {
  formatCardNumber,
  formatExpiry,
  hasCardNumberReachedMaxLength,
  isHighlighted,
} from "../../utils/formatter";
import creditCardType from "credit-card-type";
import isExpiryInvalid from "../../utils/is-expiry-invalid";
import isZipValid from "../../utils/is-zip-valid";
import PaymentCardInput from "components/checkout/PaymentCardInput";
import withNotification from "components/Hoc/withNotification";
import { GetLocalStore } from "utils/storage";
import StripePayments from "components/stripePayment";
import { checkIfUserCanAddBankAccount } from "utils";
import SquarePaymentForm from "components/square-payment-form/paymentsv2";

const PaymentModalCreation = (props: any) => {
  const [bankSubmitBtnLoading, setBankSubmitBtnLoading] = useState(false);
  //for buzops button loading for card submit
  const [cardSubmitBtnLoading, setCardSubmitBtnLoading] = useState(false);

  const [addNewItem, setAddNewItem] = useState<any>(props?.addNewItem);
  //  payment profile bank initial form values
  const bankIntialValues = {
    AccountName: undefined,
    BankAccountType: undefined,
    BankName: undefined,
    RoutingNumber: undefined,
    AccountNumber: undefined,
    UserMemberId: props?.UserMemberId,
    PaymentType: "Bank",
  };
  const [bankFormValues, setBankFormValues] = useState<any>(bankIntialValues);
  // credit cards image placeholder
  const [cardImageIcon, setcardImageIcon] = useState(images.placeholder);
  //  payment profile card initial form values
  const cardIntialValues = {
    MaskedCCNumber: undefined,
    CardTypeId: undefined,
    ExpiryMonthYear: undefined,
    ExpiryMonth: undefined,
    ExpiryYear: undefined,
    ZipCode: undefined,
    PaymentType: "Card",
    UserMemberId: props?.UserMemberId,
  };
  const [cardFormValues, setCardFormValues] = useState<any>(cardIntialValues);
  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [paymentGatewayType, setPaymentGatewayType] =
    useState<any>(paymentGatewaytype);
  const userDetails = GetLocalStore("userDetails");
  const userConfiguration =
    userDetails?.ChargehubSquarePaymentProfile || undefined;
  // to handle credit card image icon
  const handleCardImageIcon = (imageUrl: any) => {
    setcardImageIcon(imageUrl);
  };

  const handleCancel = () => {
    setAddNewItem(null);
    setBankFormValues(bankIntialValues);
    setCardFormValues(cardIntialValues);
    props?.handleClosePaymethodDialog();
  };

  // handle card form submit
  const handleCardSubmit = async (dataItem: any, status = "addCard") => {
    if (status === "addCard") {
      setCardSubmitBtnLoading(true);
      const saveCardDetails = new TenantService();
      if (paymentGatewayType === 1) {
        dataItem.MaskedCCNumber = dataItem.MaskedCCNumber.split(" ").join("");
      }
      const result = await saveCardDetails.createPaymentProfile(dataItem);
      setCardSubmitBtnLoading(false);
      if (result?.ResponseCode === 100) {
        const successMsg = result?.SuccessMessage;
        setcardImageIcon(images.placeholder);
        setAddNewItem(null);
        setCardFormValues(cardIntialValues);
        props?.handleSuccessClosePaymethodDialog(successMsg);
      } else {
        const errorMsg = result?.ErrorMessages?.[0] || "Error In Creating";
        props?.handleNotificationMessage(errorMsg, "error");
      }
    }
  };

  // handle bank form submit
  const handleBankSubmit = async (dataItem: any) => {
    setBankSubmitBtnLoading(true);
    const saveBankDetails = new TenantService();
    const req={...dataItem,BankAccountType:dataItem?.BankAccountType?.id}
    const result = await saveBankDetails.createPaymentProfile(req);
    setBankSubmitBtnLoading(false);
    if (result.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage;
      setAddNewItem(null);
      setBankFormValues(bankIntialValues);
      props?.handleSuccessClosePaymethodDialog(successMsg);
    } else {
      setBankFormValues(dataItem);
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const [cardImagee, setcardImagee] = useState(images.placeholder);

  const [error, setError] = useState("");
  const [isValid, setisValid] = useState(false);
  const CARD_TYPES: any = {
    mastercard: "MASTERCARD",
    visa: "VISA",
    amex: "AMERICAN_EXPRESS",
    jcb: "JCB",
    dinersclub: "DINERS",
    discover: "DISCOVER",
  };
  const handleCardNumberChange = (e: any) => {
    let cardNumber = e.target.value;
    cardNumber = formatCardNumber(cardNumber);
    let cardId = undefined;
    if (cardNumber !== "") {
      const cardNumberLength = cardNumber.split(" ").join("").length;
      const cardTypeName = payment.fns.cardType(cardNumber);
      if (cardTypeName !== "" || cardTypeName !== undefined) {
        const res = cardTypes.filter((item: any) => item.text === cardTypeName);
        cardId = res?.[0]?.id;
      }
      const cardTypeInfo =
        creditCardType.getTypeInfo(
          creditCardType.types[CARD_TYPES[cardTypeName]]
        ) || {};

      const cardTypeLengths = cardTypeInfo.lengths || [16];
      const cardImageValue = images[cardTypeName] || images.placeholder;
      handleCardImageIcon(cardImageValue);
      setcardImagee(cardImageValue);

      if (cardTypeLengths) {
        for (let length of cardTypeLengths) {
          if (
            length === cardNumberLength &&
            payment.fns.validateCardNumber(cardNumber)
          ) {
            setisValid(true);
          } else {
            setisValid(false);
            setError("Card Number is Invalid");
          }
        }
      }
    } else {
      setisValid(false);
      setError("Card Number is required");
    }
    setCardFormValues({
      ...cardFormValues,
      MaskedCCNumber: cardNumber,
      CardTypeId: cardId,
    });
  };

  const handleCardNumberKeyPress = (e: any) => {
    const value = e.target.value;
    checkIsNumeric(e);
    if (value && !isHighlighted()) {
      const valueLength = value.split(" ").join("").length;
      if (hasCardNumberReachedMaxLength(value, valueLength)) {
        e.preventDefault();
      }
    }
  };

  const handlezipCodeBlur = (value: any) => {
    if (value !== "") {
      const zipLength = value.length;
      if (zipLength > 5) {
        setError("Zip code is invalid");
        setisValid(false);
      } else {
        if (isZipValid(value)) {
          setisValid(true);
          return true;
        } else {
          setisValid(false);
          setError("Zip code is invalid");
          return false;
        }
      }
    } else {
      setisValid(false);
      setError("Zip code is required");
      return false;
    }
  };

  const handlezipCodeChange = (e: any) => {
    const zip = e.target.value;
    if (zip !== "") {
      const zipLength = zip.length;
      if (zipLength > 5 && !isZipValid(zip)) {
        setError("Zip code is invalid");
        setisValid(false);
      } else {
        setisValid(true);
      }
    } else {
      setError("Zip code is required");
      setisValid(false);
    }
    setCardFormValues({ ...cardFormValues, ZipCode: zip });
  };

  const handlezipCodeKeyPress = (e: any) => {
    checkIsNumeric(e);
  };

  const handleCardExpiryBlur = (value: any) => {
    const cardExpiry = value?.split(" / ").join("/");
    if (cardExpiry) {
      const message = isExpiryInvalid(cardExpiry);
      if (message === false) {
        setisValid(true);
        return true;
      } else {
        setError(message);
        setisValid(false);
        return false;
      }
    } else {
      setError("Card Expiry Date is Required");
      setisValid(false);
      return false;
    }
  };

  const handleCardExpiryBlurNew = (value: any) => {
    const cardExpiry = value?.split(" / ").join("/");
    if (cardExpiry) {
      const message = isExpiryInvalid(cardExpiry);
      if (message === false) {
        setisValid(true);
        const expiryValue = cardExpiry.split("/");
        setCardFormValues({
          ...cardFormValues,
          ExpiryMonthYear: cardExpiry,
          ExpiryMonth: expiryValue[0],
          ExpiryYear: expiryValue[1],
        });
        return true;
      } else {
        setError(message);
        setisValid(false);
        return false;
      }
    } else {
      setError("Card Expiry Date is Required");
      setisValid(false);
      return false;
    }
  };

  const handleCardExpiryChange = (e: any) => {
    let cardExpiryField = formatExpiry(e);
    if (cardExpiryField) {
      cardExpiryField = cardExpiryField.split(" / ").join("/");
      const message = isExpiryInvalid(cardExpiryField);
      if (message === false) {
        setisValid(true);
      } else {
        setError(message);
        setisValid(false);
      }
    } else {
      setError("Card Expiry Date is Required");
      setisValid(false);
    }
    if (cardExpiryField) {
      const expiryValue = cardExpiryField.split("/");
      setCardFormValues({
        ...cardFormValues,
        ExpiryMonthYear: cardExpiryField,
        ExpiryMonth: expiryValue[0],
        ExpiryYear: expiryValue[1],
      });
    } else {
      setCardFormValues({
        ...cardFormValues,
        ExpiryMonthYear: "",
        ExpiryMonth: "",
        ExpiryYear: "",
      });
    }
  };
  const isMonthDashKey = ({ key, target: { value } }: any = {}) => {
    return !value.match(/[/-]/) && /^[/-]$/.test(key);
  };

  const handleCardExpiryKeyPress = (e: any) => {
    const value = e.target.value;

    if (value && !isMonthDashKey(e)) {
      checkIsNumeric(e);
    }

    if (value && !isHighlighted()) {
      const valueLength = value.split(" / ").join("").length;
      if (valueLength >= 5) {
        e.preventDefault();
      }
    }
  };

  const checkIsNumeric = (e: any) => {
    if (!/^\d*$/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleCardNumberBlur = (value: any) => {
    if (value) {
      if (!payment.fns.validateCardNumber(value)) {
        setisValid(false);
        setError("Card Number is Invalid");
        return false;
      } else {
        setisValid(true);
        return true;
      }
    } else {
      setisValid(false);
      setError("Card Number is required");
      return false;
    }
  };

  const handleFormValidation = () => {
    const { MaskedCCNumber, ExpiryMonthYear, ZipCode } = cardFormValues;
    if (addNewItem === "addCard") {
      if (
        handleCardNumberBlur(MaskedCCNumber) &&
        handleCardExpiryBlur(ExpiryMonthYear) &&
        handlezipCodeBlur(ZipCode)
      ) {
        handleCardSubmit(cardFormValues, addNewItem);
      }
    } else {
      if (handleCardExpiryBlur(ExpiryMonthYear)) {
        handleCardSubmit(cardFormValues, addNewItem);
      }
    }
  };
  const rendercardForm = (page = "add") => {
    const showCardBasedOnType = () => {
      if (paymentGatewayType === 3) {
        
        return (
          <SquarePaymentForm
            handleCardSubmit={handleCardSubmit}
            handleCancel={handleCancel}
            showCancel={true}
            userConfiguration={userConfiguration}
            cardSubmitBtnLoading={cardSubmitBtnLoading}
            UserMemberId={props?.UserMemberId}
          />
        );
      } else if (paymentGatewayType === 4) {
        return (
          <StripePayments
            handleCardSubmit={handleCardSubmit}
            userConfiguration={userConfiguration}
            showCancel={true}
            handleCancel={handleCancel}
            cardSubmitBtnLoading={cardSubmitBtnLoading}
            type={"card"}
            UserMemberId={props?.UserMemberId}
          />
        );
      } else {
        return (
          <FormElement className="bz-add-card-field">
            <div className="payment-card-input-new">
              <PaymentCardInput
                cardFormValues={cardFormValues}
                cardImageIcon={cardImageIcon}
                handleCardNumberKeyPress={handleCardNumberKeyPress}
                handleCardNumberBlur={(val: any) => handleCardNumberBlur(val)}
                handleCardNumberChange={(e: any) => handleCardNumberChange(e)}
                handleCardExpiryKeyPress={handleCardExpiryKeyPress}
                handleCardExpiryBlur={(val: any) =>
                  handleCardExpiryBlurNew(val)
                }
                handleCardExpiryChange={(e: any) => handleCardExpiryChange(e)}
                type={page}
                handlezipCodeKeyPress={handlezipCodeKeyPress}
                handlezipCodeBlur={(val: any) => handlezipCodeBlur(val)}
                handlezipCodeChange={(e: any) => handlezipCodeChange(e)}
                error={error}
                isValid={isValid}
              />
            </div>
            <div className="d-flex mt-4 pt-2">
              <Button
                type={"reset"}
                onClick={() => handleCancel()}
                className="ml-0"
              >
                Cancel
              </Button>
              <BuzopsButton
                primary
                label={page === "add" ? "Save" : "Update"}
                disable={!isValid}
                loading={cardSubmitBtnLoading}
                onClick={() => {
                  handleFormValidation();
                }}
              />
            </div>
          </FormElement>
        );
      }
    };
    return (
      <>
        <Form
          render={(cardRendorForm: any) => (
            <>
              {checkIfUserCanAddBankAccount() ? page === "add" ? (
                <h6 className="bz-fs-14px bz-fw-6">Add Card</h6>
              ) : (
                <h6 className="bz-fs-14px bz-fw-6">Edit Card</h6>
              ) : <></>}
              {showCardBasedOnType()}
            </>
          )}
        />
      </>
    );
  };
  const renderBankForm = () => {
    const showAccountBasedOnType = () => {
      if (paymentGatewayType === 4) {
        return (
          <StripePayments
            bankFormValues={bankFormValues}
            bankSubmitBtnLoading={bankSubmitBtnLoading}
            handleBankSubmit={handleBankSubmit}
            type={"bank"}
            UserMemberId={props?.UserMemberId}
          />
        );
      } else {
        return (
          <Form
            initialValues={bankFormValues}
            onSubmit={handleBankSubmit}
            render={(bankRenderForm: any) => (
              <>
                <h6 className="bz-fs-14px bz-fw-6 mb-0">Add Bank</h6>
                <FormElement style={{ width: "100%" }} className="bz-add-bank">
                  <div className="">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Field
                          id={"BankAccountType"}
                          name={"BankAccountType"}
                          component={FormDropDownList}
                          label={"Account Type"}
                          required={true}
                          validator={requiredValidator}
                          data={BankAccountTypes}
                          textField="text"
                          dataItemKey="id"
                          customvalidation={true}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Field
                          id={"AccountName"}
                          name={"AccountName"}
                          component={FormInput}
                          required={true}
                          placeholder={"Account Name"}
                          label={"Account Name"}
                          validator={requiredValidator}
                          customvalidation={true}
                        />
                      </div>
                      </div>
                     <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Field
                          id={"BankName"}
                          name={"BankName"}
                          component={FormInput}
                          placeholder={"Bank Name"}
                          required={true}
                          label={"Bank Name"}
                          validator={requiredValidator}
                          customvalidation={true}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Field
                          id={"RoutingNumber"}
                          name={"RoutingNumber"}
                          component={FormInput}
                          placeholder={"Routing Number"}
                          required={true}
                          label={"Routing Number"}
                          validator={routingNumberValidator}
                          customvalidation={true}
                        />
                      </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <Field
                            id={"AccountNumber"}
                            name={"AccountNumber"}
                            component={FormInput}
                            placeholder={"Account Number"}
                            label={"Account Number"}
                            required={true}
                            validator={BankAccountValidator}
                            customvalidation={true}
                          />
                        </div>
                      </div>
                  </div>
                  <div className="d-flex mt-3 pt-2">
                    <Button
                      type={"reset"}
                      disabled={bankSubmitBtnLoading}
                      onClick={() => handleCancel()}
                      className="ml-0"
                    >
                      Cancel
                    </Button>
                    <BuzopsButton
                      primary
                      label={"Save"}
                      type={"submit"}
                      disable={
                        !bankRenderForm.allowSubmit || bankSubmitBtnLoading
                      }
                      loading={bankSubmitBtnLoading}
                    />
                  </div>
                </FormElement>
              </>
            )}
          />
        );
      }
    };
    return <>{showAccountBasedOnType()}</>;
  };
  return (
    <>
      {addNewItem === "addBank" && renderBankForm()}
      {addNewItem === "addCard" && rendercardForm()}
    </>
  );
};

export default withNotification(PaymentModalCreation);
