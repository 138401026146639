import { Card, CardBody, Menu, MenuItem } from "@progress/kendo-react-layout";
import React, { useEffect } from "react";
import useState from "react-usestateref";
import { GetLocalStore } from "utils/storage";
import Overview from "./overview";
import ServiceOverview from "./individualServices/ServiceOverview";
import { Button } from "@progress/kendo-react-buttons";
import Payments from "./Payments";
import { Appointments } from "./Appointments";
import { useHistory } from "react-router-dom";
import { TenantService } from "services/tenant/index.service";
import Transactions from "./Transactions";
import Plans from "./Plans/Plans";
import PlanOverview from "./Plans/PlanOverview";
import { Tooltip } from "@progress/kendo-react-tooltip";
import PlanPayments from "./Plans/PlanPayments";
import AuditHistory from "./AuditHistory";
import Addons from "./Addons/Addons";
import AddonOverView from "./Addons/AddonOverView";
import AddonPayments from "./Addons/AddonPayments";
import MyProfile from "./MyProfile";
import PackageOverView from "./packages/PackageOverView";
import PackagesSummary from "components/client/PackagesSummary";
import BuzopsLoader from "generic-components/BuzopsLoader";
import PlansAndAddons from "./Plans/PlansAndAddons";
import CheckIns from "./CheckIns";
import AddonCancel from "./Addons/AddonCancel";
import PlanCancel from "./Plans/PlanCancel";
import AddonFreeze from "./Addons/AddonFreeze";
import NotFound from "generic-components/NotFound";
import PlanFreeze from "./Plans/PlanFreeze";
import ActivateSuspended from "./Plans/ActivateSuspended";
import CheckPermissions from "utils/permissions";
import { PermissionEnum } from "utils/PermissionEnum";
import IndividualServiceCancel from "./individualServices/IndividualServiceCancel";
import QuickCheckout from "./individualServices/QuickCheckout";
import ComplimentaryCheckout from "./individualServices/ComplimentaryCheckouts";
import Purchase from "./Purchase/Purchase";
import AssignAddon from "./PlanSubscribe/AssignAddon";
import { CheckUserMemberStatus } from "utils";
import GroupServices from "./groupServices/GroupServices";
import CancelPackage from "components/package/CancelPackage";
import menuIcon from "../../Images/menuIcon.svg";
import PurchasedPackages from "./packages/PurchasedPackages";
import PackagePayments from "./packages/PackagePayments";
import ClientOverviewInfo from "./ClientOverviewInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  SetSelectedAddon,
  SetSelectedMenuItem,
  SetSelectedPlan,
} from "redux-slices/genericSlice";
import Agreements from "./Agreements";
import Notes from "components/notes";
import AccessComponent from "components/onsiteaccess/Components/AccessComponent";
import PaymentMethod from "components/paymentMethod/PaymentMethod";
import ChargeBackGlobal from "components/ChargeBack/ChargeBackGlobal";
import PackageUtilization from "components/package/PackageUtilization";
import OneTimeCharge from "./oneTimeCharge/OneTimeCharge";
import withNotification from "components/Hoc/withNotification";
import Forms from "./Forms/Forms";
import { useApiClient } from "services/axios-service-utils";
import { setEnforceStatus } from "redux-slices/userSlice";
import { CancelPackagePermission, ChargeBackPermission } from "utils/permission-utils";
import { PackageService } from "services/package/index.service";
const Summary = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { axiosRequest } = useApiClient();
  const userSessionInfo = GetLocalStore("userDetails");
  const ShowOnsiteAccessConfiguration =
    userSessionInfo.ShowOnsiteAccessConfiguration;
  const [selectedMenu, setselectedMenu] = useState<any>(0);
  const [selectedTab, setSelectedTab] = useState<any>(0);
  const [selectedButton, setSelectedButton] = useState<any>(null);
  const [selectedItemId, setSelectedItemId] = useState<any>(null);
  const [purchaseItem, setPurchaseItem] = useState<any>(null);
  const [userData, setUserData,userDataref] = useState<any>(null);
  const [navigateToAPageOnButtonClick, SetNavigateToAPageOnButtonClick] = useState<any>(null);
  const [selectedSubscriptionStatus, setSelectedSubscriptionStatus] =
    useState<any>(null);
  const [selectedSubscription, setSelectedSubscription] = useState<any>(null);
  const [serviceInstance, setServiceInstance] = useState<any>(null);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAddPlan, setshowAddPlan] = useState(false);
  const tenantName = GetLocalStore("tenant");
  const [servicehandle, setservicehandle] = useState(
    props.location.state?.service || null
  );

  const [addMoreClasses, setAddMoreClasses] = useState(
    props.location.state?.AddMoreClasses || null
  );
  const [parentId, setParentId] = useState(null);
  const [classSelectedUserMemberId, setClassSelectedUserMemberId] = useState(
    props.location.state?.selectedUserMemberId || null
  );
  const [preset,setpreset]=useState(props?.location?.state?.showPreset || false);
  const service = new TenantService();
  const [subMenuType, setSubMenuType] = useState<any>(null);
  const uuid = props?.uuid || props.match?.params?.uuid;
  const clientId = props?.clientId || props.match?.params?.clientId;
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  const [showAutoRenewal, setShowAutorenewal] = useState<boolean>(false);
  const subscriptionId = props.match?.params?.subscriptionId;
  const assign = props.match?.params?.assign;
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 991;
  useEffect(() => {
    if (GetLocalStore("UserMemberId")) {
      getAllSubMembers(GetLocalStore("UserMemberId"));
    }
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const handleAfterSubmit = (userMemberId: any) => {
    getAllSubMembers(userMemberId);
  };

  useEffect(() => {
    handleRedirection();
  }, [GetLocalStore("UserMemberId")]);
  useEffect(() => {
    if (props?.location?.state?.planItem) {
      goToAssignPlanPage();
    }
  }, [props?.location?.state?.planItem]);

  const selectedPlanIdVal = useSelector(
    (state: any) => state?.generic?.selectedPlan
  )?.id;
  const navigateToAPageOnButton = useSelector(
    (state: any) => state?.generic?.navigateToAPageOnButtonClick);

  const redirectionObj = useSelector((state: any) => state?.redirection);
  const userInfo = useSelector((state: any) => state?.userConfiguration?.userDetails);
  const {
    IsEnforced=false,IsCardEnforced=false,IsFormsEnforced=false
  } = useSelector((state: any) => state?.userConfiguration);
  
  const { firstPathValue, secondRoute } = redirectionObj;


  useEffect(() => {
    if (!subMenuType) {
      dispatch(SetSelectedPlan(null));
      dispatch(SetSelectedAddon(null));
    }
  }, [subMenuType]);

  useEffect(() => {
    if (IsEnforced) {
      if(IsCardEnforced){
        goToPaymentMethodsPage()
      }else if(IsFormsEnforced){
        goToFormsPage()
      }
    }
  }, [IsEnforced,IsCardEnforced,IsFormsEnforced]);

  const genericReducersubscription = () => {
      const to = navigateToAPageOnButton
      SetNavigateToAPageOnButtonClick(to)
      handleMenuSelection(to.id,to.parent)
  }
  useEffect(() => {
    if(navigateToAPageOnButtonClick?.id !== navigateToAPageOnButton?.id){
      genericReducersubscription()
    }else if(navigateToAPageOnButtonClick?.id===136){
      genericReducersubscription()
    }
  
  }, [navigateToAPageOnButton])
  
  

  const getAllSubMembers = async (memberId: any) => {
    const tenantService = new TenantService();
    const res = await tenantService.GetSubMembersWithParent(memberId);
    setAllClientsList(res);
  };
  const handleSelectedButton = () => {
    setSelectedButton(null);
  };
  const handleRedirection = async () => {
    let UserMemberid = GetLocalStore("UserMemberId");
    if (clientId) {
      UserMemberid = clientId;
      await fetchUserDetails(UserMemberid);
      if (addMoreClasses) {
        goToPurchasePage();
      } else if (props?.location?.state?.purchaseoneonone) {
        handlePurchase("service");
      } else if (props?.location?.state?.purchasepackage) {
        handlePurchase("package");
      } else if (props?.location?.state?.redeemPackage){
        handleRedeemPackage()
      } else if (props?.location?.state?.purchaseplan) {
        handlePurchase("plan");
      } else if(subscriptionId==="undefined-0"){
        handleSummaryDashboard()
      }else if (subscriptionId) {
        const val = subscriptionId.split("-");
        let urlValue = val[0].toLowerCase();
        let typeval = null;
        let statusValue = null;
        let det = null;
        switch (urlValue) {
          case "p":
            typeval = "plan";
            if (assign) {
              const assignVal = assign.toLowerCase();
              switch (assignVal) {
                case "purchaseaddon":
                  typeval = "purchaseaddon";
                  break;
                case "audit":
                  typeval = "planaudit";
                  break;
                case "transactions":
                  typeval = "plantransactions";
                  break;
                case "payments":
                  typeval = "planpayments";
                  break;
              }
            }
            const planReq = {
              PageNumber: 0,
              PageSize: 32767,
              UserMemberId: parseInt(UserMemberid),
              MemberPlanId: parseInt(val[1]),
            };
            const planResult = await service?.getMemberPlanSummary(planReq);
            statusValue = planResult?.planStatusDescription;
            det = planResult;
            break;
          case "a":
            typeval = "addon";
            if (assign) {
              const assignVal = assign.toLowerCase();
              switch (assignVal) {
                case "audit":
                  typeval = "addonaudit";
                  break;
                case "transactions":
                  typeval = "addontransactions";
                  break;
              }
            }
            const addonReq = {
              PageSize: 32767,
              PageNumber: 0,
              UserMemberId: parseInt(UserMemberid),
              EntityId: parseInt(val[1]),
            };
            const addonResult = await service?.getAddOnSummary(addonReq);
            statusValue = addonResult?.Status;
            det = addonResult;
            break;
          case "pi":
            typeval = "service";
            if (assign) {
              const assignVal = assign.toLowerCase();
              switch (assignVal) {
                case "audit":
                  typeval = "piaudit";
                  break;
                case "transactions":
                  typeval = "pitransactions";
                  break;
                case "payments":
                  typeval = "pipayments";
                  break;
                case "cancel":
                  typeval = "picancel";
                  break;
                case "appointments":
                  typeval = "piappointments";
                  break;
                case "quickcheckout":
                  typeval = "piquickcheckout";
                  break;
                case "schedule":
                  typeval = "pischedule";
                  break;
              }
            }
            break;
          case "pk":
            typeval = "package";
            if (assign) {
              //const assignVal = assign.toLowerCase();
              const val = assign.split("-");
              let assignVal = val[0].toLowerCase();
              switch (assignVal) {
                case "audit":
                  typeval = "pkaudit";
                  break;
                case "schedule":
                  typeval = "pkschedule";
                  break;
                case "payments":
                  typeval = "pkpayments";
                  break;
                case "cancel":
                  typeval = "pkcancel";
                  break;
                case "agreements":
                  typeval = "pkagreements";
                  break;
                case "appointments":
                  typeval = "piappointments";
                  break;
                case "quickcheckout":
                  typeval = "pkquickcheckout";
                  break;
                case "complimentarycheckout":
                  typeval = "pkcomplimentarycheckout";
                  break;
              }
            }
            const req = {
              UserMemberId: parseInt(UserMemberid),
              PackageInstanceId: parseInt(val[1]),
            };
            const packageservice = new PackageService();
            const getPackageDetails = await packageservice.packageInstanceFilter(req);
            det = getPackageDetails.Items[0];
            break;
          case "purchasedpackage":
            typeval = "purchasedpackage";
            break;
          default:
            typeval = urlValue;
            break;
        }

        handleSelectionItem(val[1], statusValue, typeval, det);
      } else if (servicehandle) {
        handleSelectionItem(
          servicehandle?.PrivateServiceInstanceId || servicehandle?.EntityId,
          servicehandle,
          "service",
          servicehandle
        );
      } else {
        setselectedMenu(1);
      }
    } else if (uuid) {
      if (redirectionObj?.firstPathValue !== "") {
        history.push(`/${redirectionObj?.firstPathValue}/summary`);
      } else {
        history.push(`/summary`);
      }
    } else {
      if (!UserMemberid) {
        if (redirectionObj?.firstPathValue !== "") {
          if (redirectionObj?.firstPathValue === "external") {
            history.push(
              `/${redirectionObj?.firstPathValue}/${redirectionObj?.secondRoute}`
            );
          } else {
            history.push(`/${redirectionObj?.firstPathValue}`);
          }
        } else {
          history.push(`/`);
        }
      } else {
        await fetchUserDetails(UserMemberid);
        if (addMoreClasses) {
          goToPurchasePage();
        } else if (servicehandle) {
          handleSelectionItem(
            servicehandle?.PrivateServiceInstanceId || servicehandle?.EntityId,
            servicehandle,
            "service",
            servicehandle
          );
        } else if (props?.location?.state?.purchasepackage) {
          handlePurchase("package");
        } else if (props?.location?.state?.purchaseplan) {
          handlePurchase("plan");
        } else if (props?.location?.state?.purchaseoneonone) {
          handlePurchase("service");
        } else if (props?.location?.state?.plan) {
          goToPlanPage();
        }else if (props?.location?.state?.redeemPackage){
          handleRedeemPackage()
        }else if (props?.location?.state?.redirectedFrom==="purchasedpackage"){
          handleRedeemPackage()
        }else if(props?.location?.state?.redirectedFrom){
          const val = props?.location?.state?.redirectedFrom.split("-");
          setSubMenuType("package");
          setSelectedItemId(parseInt(val[1]));
          setselectedMenu(23);
          handleSideMenu(false);
        } else {
          setselectedMenu(1);
          unloadFunc();
        }
        await fetchEnforceDetails(UserMemberid)
      }
    }
  };
  const CheckPlanCancelPermission = (SubscriptionStatus: any) => {
    return (
      CheckPermissions(PermissionEnum["ApproveCancelRequest"]) &&
      SubscriptionStatus !== "Expired" &&
      SubscriptionStatus !== "Cancelled" 
    );
  };
  const CheckAddonCancelPermission = (SubscriptionStatus: any) => {
    return (
      CheckPermissions(PermissionEnum["ApproveCancelRequest"]) &&
      SubscriptionStatus !== "Completed"
    );
  };
  const CheckPlanFreezePermission = (SubscriptionStatus: any,SelectedSubscription:any) => {
    return (
      CheckPermissions(PermissionEnum["ApproveFreezeRequest"]) &&
      SubscriptionStatus !== "Expired" &&
      SubscriptionStatus !== "Cancelled" &&
      ((SelectedSubscription?.DurationType ===1 && SelectedSubscription?.Duration > 1) || SelectedSubscription?.DurationType ===2) 
    );
  };
  const CheckAddonFreezePermission = (SubscriptionStatus: any) => {
    return (
      CheckPermissions(PermissionEnum["ApproveFreezeRequest"]) &&
      SubscriptionStatus !== "Completed" &&
      SubscriptionStatus !== "Cancelled"
    );
  };
  const CheckPlanSuspendPermission = (SubscriptionStatus: any) => {
    return (
      CheckPermissions(PermissionEnum["ActivateAccountSuspension"]) &&
      SubscriptionStatus !== "Expired" &&
      SubscriptionStatus === "Suspended"
    );
  };

  const CheckCancelPackage = (SubscriptionStatus: any) => {
    return (
      SubscriptionStatus === "Cancelled" || SubscriptionStatus === "Expired" || SubscriptionStatus === "Completed"
    );
  };
  const setCurrentUserData = (userMemberId: any = null) => {
    const currentUserData = AllClientsList?.find(
      (val: any) =>
        val.UserMemberId === (userMemberId || GetLocalStore("UserMemberId"))
    );
    if (currentUserData) {
      let data = {
        ...currentUserData,
        Status: currentUserData?.UserMemberStatus,
        MainUserId:
          GetLocalStore("UserMemberId") ||
          currentUserData?.ParentId ||
          currentUserData?.UserMemberId,
      };
      setUserData(data);
    }
  };

  console.log('selectedSubscriptionStatus',selectedSubscriptionStatus);

  const fetchUserDetails = async (userMemberId: any) => {
    setLoading(true);
    const res = await service?.getUserInfo(userMemberId);

    setLoading(false);
    let data;
    if (res) {
      const {
        UserMemberId,
        UserId,
        ParentId,
        MemberNumber,
        MemberType,
        Status,
        User: {
          Email,
          FirstName,
          FullName,
          LastName,
          Photo,
          DateOfBirth,
          Address: { Phone },
        },
      } = res;
      const MainUserId = ParentId || GetLocalStore("UserMemberId") || UserMemberId;
       data = {
        UserMemberId,
        UserId,
        Email,
        FirstName,
        FullName,
        LastName,
        Photo,
        DateOfBirth,
        Phone,
        MemberType,
        MemberNumber,
        Status,
        MainUserId,
      };
      setUserData(data);
    } else {
      setUserData(null);
    }
    return data;
  };

  
  const fetchEnforceDetails  = async (userMemberId:any) => {
    await axiosRequest.get(`authentication/GetMemberStatus/${userMemberId}`, {
      successCallBack: (response: any) => {
        const data = response.data
        dispatch(setEnforceStatus({
          IsEnforced: data?.IsEnforced,
          IsCardEnforced: data?.IsCardEnforced,
          IsFormsEnforced: data?.IsFormsEnforced,
          UserMemberStatus: data?.UserMemberStatus,
        }))
      },
      errorCallBack: (response: any) => {
        props?.handleNotificationMessage(response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] || "Internal server error", "error");
      },
    });
  }

  const serviceMenuItems = [
    {
      id: 11,
      name: "overview",
      text: "Overview",
      visible: true,
      iconClass: "far fa-eye",
      parent: false,
    },
    {
      id: 12,
      name: "appointments",
      text: "Appointments",
      visible: true,
      iconClass: "far fa-calendar-alt",
      parent: false,
    },
    {
      id: 13,
      name: "payments",
      text: "Payments",
      visible: true,
      iconClass: "fas fa-money-bill-alt",
      parent: false,
    },
    {
      id: 42,
      name: "Agreements",
      text: "Documents",
      visible: true,
      iconClass: "fas fa-file-contract",
      parent: false,
    },
    {
      id: 14,
      name: "activity",
      text: "Activity",
      visible: true,
      iconClass: "fas fa-chart-line",
      parent: false,
    },
    {
      id: 15,
      name: "cancelService",
      text: "Cancel",
      visible:
        CheckPermissions(PermissionEnum["ServiceCancel"]) &&
        selectedSubscriptionStatus !== "Cancelled" && selectedSubscriptionStatus !== "Completed",
      iconClass: "fas fa-window-close",
      parent: false,
    },
    {
      id: 34,
      name: "quickCheckout",
      text: "Quick Checkout",
      visible: false,
      iconClass: "fas fa-globe",
      parent: false,
    },
    {
      id: 35,
      name: "complimentaryCheckout",
      text: "Complimentary Checkout",
      visible: false,
      iconClass: "fas fa-globe",
      parent: false,
    },
  ];
  const planMenuItems = [
    {
      id: 16,
      name: "overview",
      text: "Overview",
      visible: true,
      iconClass: "far fa-eye",
      parent: false,
    },
    {
      id: 17,
      name: "payments",
      text: "Payments",
      visible: true,
      iconClass: "fas fa-money-bill-alt",
      parent: false,
    },
    {
      id: 42,
      name: "Agreements",
      text: "Documents",
      visible: true,
      iconClass: "fas fa-file-contract",
      parent: false,
    },
    {
      id: 19,
      name: "auditHistory",
      text: "Audit History",
      visible: true,
      iconClass: "fas fa-history",
      parent: false,
    },
    {
      id: 29,
      name: "planCancel",
      text: "Cancel",
      visible: CheckPlanCancelPermission(selectedSubscriptionStatus),
      iconClass: "far fa-window-close",
      parent: false,
    },
    {
      id: 31,
      name: "planFreeze",
      text: selectedSubscriptionStatus !== "Frozen" ? "Freeze" : "Unfreeze",
      visible: CheckPlanFreezePermission(selectedSubscriptionStatus,selectedSubscription),
      iconClass: "fas fa-bolt",
      parent: false,
    },
    {
      id: 32,
      name: "activateSuspended",
      text: "Activate",
      visible: CheckPlanSuspendPermission(selectedSubscriptionStatus),
      iconClass: "fas fa-globe",
      parent: false,
    },
    {
      id: 33,
      name: "couponHistory",
      text: "Coupon History",
      visible: true,
      iconClass: "fas fa-history",
      parent: false,
    },
    {
      id: 36,
      name: "Addon",
      text: "Subscribe Addon",
      visible: false,
      iconClass: "fas fa-globe",
      parent: false,
    },
    {
      id: 136,
      name: "Addons",
      text: "Addons",
      visible: true,
      iconClass: "fas fa-globe",
      parent: false,
    },
  ];

  const packageMenuItems = [
    {
      id: 23,
      name: "overview",
      text: "Overview",
      visible: true,
      iconClass: "far fa-eye",
      parent: false,
    },
    {
      id: 25,
      name: "transactions",
      text: "Payments",
      visible: true,
      iconClass: "fas fa-money-bill-alt",
      parent: false,
    },
    {
      id: 42,
      name: "Agreements",
      text: "Documents",
      visible: true,
      iconClass: "fas fa-file-contract",
      parent: false,
    },
    {
      id: 26,
      name: "auditHistory",
      text: "Audit History",
      visible: true,
      iconClass: "fas fa-history",
      parent: false,
    },
    {
      id: 38,
      name: "packageCancel",
      text: "Cancel Package",
      visible: clientId && !CheckCancelPackage(selectedSubscriptionStatus) && CancelPackagePermission(),
      iconClass: "fas fa-window-close",
      parent: false,
    },
    {
      id: 47,
      name: "couponHistory",
      text: "Coupon History",
      visible: true,
      iconClass: "fas fa-history",
      parent: false,
    },
  ];
  const addOnMenuItems = [
    {
      id: 20,
      name: "overview",
      text: "Overview",
      visible: true,
      iconClass: "far fa-eye",
      parent: false,
    },
    {
      id: 21,
      name: "payments",
      text: "Payments",
      visible: true,
      iconClass: "fas fa-money-bill-alt",
      parent: false,
    },
    {
      id: 22,
      name: "auditHistory",
      text: "Audit History",
      visible: true,
      iconClass: "fas fa-history",
      parent: false,
    },
    {
      id: 28,
      name: "cancelAddon",
      text: "Cancel",
      visible: CheckAddonCancelPermission(selectedSubscriptionStatus),
      iconClass: "fas fa-window-close",
      parent: false,
    },
    {
      id: 30,
      name: "freezeAddon",
      text: selectedSubscriptionStatus !== "Frozen" ? "Freeze" : "Unfreeze",
      visible: CheckAddonFreezePermission(selectedSubscriptionStatus),
      iconClass: "fas fa-bolt",
      parent: false,
    },
  ];
  const MainMenuItemsData = [
    {
      id: 1,
      name: "overview",
      text: "Overview",
      visible: true,
      iconClass: "fa fa-th-large",
      parent: true,
    },
    {
      id: 3,
      name: "appointments",
      text: "Appointments",
      visible: true,
      iconClass: "fas fa-calendar-check",
      parent: true,
    },
    {
      id: 40,
      name: "onSiteAccess",
      text: "Access+Check-ins",
      visible: ShowOnsiteAccessConfiguration,
      iconClass: "fas fa-user-check",
      parent: true,
    },
    {
      id: 27,
      name: "plansAddons",
      text: "Memberships",
      visible: true,
      iconClass: "fas fa-id-card-alt",
      parent: true,
    },
    {
      id: 4,
      name: "services",
      text: "One-on-One (Pre-set)",
      visible: true,
      iconClass: "fas fa-user-friends",
      parent: true,
    },
    {
      id: 5,
      name: "classes",
      text: "Group",
      visible: true,
      iconClass: "fa fa-users",
      parent: true,
    },
    {
      id: 39,
      name: "packages",
      text: "Packages",
      visible: true,
      iconClass: "fas fa-box-open",
      parent: true,
    },
    {
      id: 6,
      name: "transactions",
      text: "Transactions",
      visible: true,
      iconClass: "fas fa-exchange-alt",
      parent: true,
    },
    {
      id: 45,
      name: "notes",
      text: "Notes",
      visible: clientId ? true : false,
      iconClass: "far fa-sticky-note",
      parent: true,
    },
    {
      id: 46,
      name: "Payment Methods",
      text: "Payment Methods",
      visible: true,
      iconClass: "fas fa-money-bill-alt",
      parent: true,
    },
    {
      id: 41,
      name: "Agreements",
      text: "Documents",
      visible: true,
      iconClass: "fas fa-file-contract",
      parent: true,
    },
    {
      id: 49,
      name: "Forms",
      text: "Forms",
      visible: true,
      iconClass: "fas fa-list-alt",
      parent: true,
    },
    {
      id: 9,
      name: "audit",
      text: "Audit",
      visible: true,
      iconClass: "fas fa-history",
      parent: true,
    },
    {
      id: 48,
      name: "oneTimeCharge",
      text: "One Time Charge",
      visible: clientId ? true : false,
      iconClass: "fas fa-bolt",
      parent: true,
    },
    {
      id: 10,
      name: "myprofile",
      text: "My Profile",
      visible: true,
      iconClass: "fas fa-user-circle",
      parent: true,
    },
    {
      id: 37,
      name: "purchase",
      text: "Purchase",
      visible: true,
      iconClass: "fas fa-globe",
      parent: true,
    },
    {
      id: 43,
      name: "logout",
      text: "Logout",
      visible: firstPathValue === "external" ? true : false,
      iconClass: "fas fa-sign-out-alt",
      parent: true,
    },
  ];

  const MainMenuItems = MainMenuItemsData.filter(
    (i: any) => i?.visible === true
  );

  const handleLogout = () => {
    if (firstPathValue === "external") {
      history.push({
        pathname: `/${firstPathValue}/${secondRoute}`,
        state: {
          logout: true,
        },
      });
    } else {
      history.push({
        pathname: `/${firstPathValue}`,
        state: {
          logout: true,
        },
      });
    }
  };

  const handleMenuSelection = (id: number, parent: boolean) => {
    if(IsEnforced && IsCardEnforced && !clientId){
      const errorMsg = `Please note that no action can be performed until the Payment Profile is created.`;
      props?.handleNotificationMessage(errorMsg, "error");
      return
    }else  if(IsEnforced && IsFormsEnforced && !clientId){
      const errorMsg = `Please note that no action can be performed until the Form is filled.`;
      props?.handleNotificationMessage(errorMsg, "error");
      return
    }
    dispatch(
      SetSelectedMenuItem({
        id,
        parent,
      })
    );
    if (id === 16) dispatch(SetSelectedAddon(null));
    if (parent) {
      setCurrentUserData();
      setSubMenuType(null);
      setSelectedTab(0);
    }
    setselectedMenu(id);
    handleSideMenu(false);
    setPurchaseItem(null);
    setshowAddPlan(false);
    unloadFunc();
  };
  const TooltipContentTemplate = (propsData: any) => {
    return (
      <a>
        <strong>{propsData.title}</strong>
      </a>
    );
  };
  const handleSelectTab = (e: any) => {
    setCurrentUserData(GetLocalStore("UserMemberId"));
    setSelectedTab(e);
  };

  const handlePurchase = (type: string) => {
    setPurchaseItem(type);
    goToPurchasePage();
  };
  const gotoPlanOverview = () => {
    setselectedMenu(16);
    setShowAutorenewal(false)
  };
  const gotoPlanRenewalPage = () => {
      setselectedMenu(16);
      setShowAutorenewal(true)
  };
  const handleCloseAutoRenewal=()=>{
    setShowAutorenewal(false)
  }
  const gotoPackageOverview = () => {
    setselectedMenu(23);
  };

  const handleSummaryDashboard=()=>{
    setselectedMenu(1)
  }
  const renderMenus = (show = false, MenuItems: any) => {
    const Items = MenuItems;
    return (
      <Menu vertical={true} className="bz-menu-list">
        {Items.filter((j: any) => j.visible === true).map(
          (item: any, index: number) => {
            const status = item.id === selectedMenu ? "active" : "inactive";
            return (
              <MenuItem
                data={item}
                key={item.id}
                cssClass={status}
                render={(props: any) => {
                  return (
                    <>
                      <Tooltip
                        content={(props: any) => (
                          <TooltipContentTemplate title={props.title} />
                        )}
                        anchorElement="target"
                        position="right"
                      >
                        <div style={{ width: "100%" }}>
                          <a
                            onClick={() =>
                              handleMenuSelection(
                                props.item.data.id,
                                props.item.data.parent
                              )
                            }
                            title={props.item.data.text}
                          >
                            <button
                              className={props.item.data.iconClass}
                              aria-hidden="true"
                              title={props.item.data.text}
                            ></button>
                            {show && (
                              <>
                                <span className="bz-menu-text">
                                  {props.item.data.text}
                                </span>
                              </>
                            )}
                          </a>
                        </div>
                      </Tooltip>
                      {status === "active" && show && (
                        <span className={"pl-1 float-right bz-menu-arrow-icon"}>
                          <i className="fas fa-chevron-right"></i>
                        </span>
                      )}
                    </>
                  );
                }}
              ></MenuItem>
            );
          }
        )}
      </Menu>
    );
  };
  const fetchServiceInstance = async (val: any) => {
    const fetchService = new TenantService();
    const result = await fetchService.getIndividualService(val);
    if (result) {
      setServiceInstance(result);
      setSelectedSubscription(result);
    }
  };

  const goToPaymentMethodsPage=()=>{
    setSubMenuType(null);
    setSelectedItemId(null);
    setselectedMenu(46);
    handleSideMenu(false);
  }
  const goToFormsPage=()=>{
    setSubMenuType(null);
    setSelectedItemId(null);
    setselectedMenu(49);
    handleSideMenu(false);
  }

  const getUserMemberId = (dataItem: any) => {
    if (dataItem?.UserMemberId) return dataItem?.UserMemberId;

    if (dataItem?.MemberName) {
      const currentClientData = AllClientsList.find(
        (val: any) => val.FullName === dataItem?.MemberName
      );
      if (currentClientData) return currentClientData?.UserMemberId;
    }

    return GetLocalStore("UserMemberId");
  };
  const handleSelectionItem = async (
    ID: number,
    status?: string,
    Type?: string,
    dataItem: any = null
  ) => {
    setParentId(null);
    const toDispatch = {
      id: ID,
      status: status || "Active",
      details: dataItem || {},
    };
    const result=userDataref?.current;
    setCurrentUserData(getUserMemberId(dataItem));
    switch (Type?.toLowerCase()) {
      case "service":
        fetchServiceInstance(ID);
        setSelectedSubscription(dataItem);
        setSubMenuType("service");
        setSelectedItemId(ID);
        setSelectedSubscriptionStatus(status);
        setselectedMenu(11);
        handleSideMenu(false);
        break;
      case "purchasedpackage":
        setSubMenuType(null);
        setSelectedItemId(null);
        setselectedMenu(39);
        handleSideMenu(false);
        break;
      case "plan":
        dispatch(SetSelectedPlan(toDispatch));
        setSubMenuType("plan");
        setSelectedTab(0);
        setSelectedItemId(ID);
        setSelectedSubscriptionStatus(status);
        setselectedMenu(16);
        handleSideMenu(false);
        setSelectedSubscription(dataItem);
        break;
      case "addon":
        const planReq = {
          PageNumber: 0,
          PageSize: 32767,
          UserMemberId: parseInt(dataItem?.UserMemberId),
          MemberPlanId: parseInt(dataItem?.MemberPlanId),
        };
        const planResult = await service?.getMemberPlanSummary(planReq);
        const statusValue = planResult?.planStatusDescription;
        dispatch(
          SetSelectedPlan({
            id: dataItem?.MemberPlanId,
            status: statusValue || "Active",
            details: planResult || {},
          })
        );
        dispatch(SetSelectedAddon(toDispatch));
        setSubMenuType("addon");
        setSelectedTab(1);
        setSelectedItemId(ID);
        setSelectedSubscriptionStatus(status);
        setSelectedSubscription(dataItem);
        setselectedMenu(20);
        handleSideMenu(false);
        break;
      case "pkschedule":
        history.push(
          `/member/${uuid}/${clientId}/scheduleappointment`,
          {
            selectedPackageInstanceId: ID,
            staffPage: true,
            userData: result,
            uuid: uuid,
            Complimentary: false,
            quickcheckout: false,
          }
        );
        break;
      case "pkquickcheckout":
        history.push(
          `/member/${uuid}/${clientId}/scheduleappointment`,
          {
            selectedPackageInstanceId: ID,
            staffPage: true,
            userData: result,
            uuid: uuid,
            Complimentary: false,
            quickcheckout: true,
          }
        );
        break;
      case "pkcomplimentarycheckout":
        history.push(
          `/member/${uuid}/${clientId}/scheduleappointment`,
          {
            selectedPackageInstanceId: ID,
            staffPage: true,
            userData: result,
            uuid: uuid,
            Complimentary: true,
            quickcheckout: true,
          }
        );
        break;
      case "pkaudit":
        setSubMenuType("package");
        setSelectedItemId(ID);
        setSelectedTab(1);
        setSelectedSubscription(dataItem);
        setSelectedSubscriptionStatus(status);
        setselectedMenu(26);
        handleSideMenu(false);
        break;
      case "pkpayments":
        setSubMenuType("package");
        setSelectedItemId(ID);
        setSelectedTab(1);
        setSelectedSubscription(dataItem);
        setSelectedSubscriptionStatus(status);
        setselectedMenu(25);
        handleSideMenu(false);
        break;
        case "pkcancel":
          setSubMenuType("package");
          setSelectedItemId(ID);
          setSelectedTab(1);
          setSelectedSubscription(dataItem);
          setSelectedSubscriptionStatus(status);
          setselectedMenu(38);
          handleSideMenu(false);
          break;
      case "pkagreements":
        setSubMenuType("package");
        setSelectedItemId(ID);
        setSelectedTab(1);
        setSelectedSubscription(dataItem);
        setSelectedSubscriptionStatus(status);
        setselectedMenu(42);
        handleSideMenu(false);
        break;
      case "package":
        if(selectedMenu === 4){
          setpreset(true);
        }
        setSubMenuType("package");
        setSelectedItemId(ID);
        setSelectedTab(1);
        setSelectedSubscription(dataItem);
        setSelectedSubscriptionStatus(status);
        setselectedMenu(23);
        handleSideMenu(false);
        break;
      case "gs":
        setSubMenuType(null);
        setSelectedItemId(ID);
        setSelectedTab(0);
        setselectedMenu(5);
        handleSideMenu(false);
        break;
      case "purchaseplan":
        setshowAddPlan(true);
        setSubMenuType(null);
        setSelectedItemId(null);
        setselectedMenu(27);
        handleSideMenu(false);
        break;
      case "purchaseaddon":
        dispatch(SetSelectedPlan(toDispatch));
        setSubMenuType("plan");
        setSelectedItemId(ID);
        setselectedMenu(36);
        handleSideMenu(false);
        setSelectedSubscription(dataItem);
        break;
      case "planaudit":
        setSubMenuType("plan");
        setSelectedItemId(ID);
        setselectedMenu(19);
        handleSideMenu(false);
        break;
      case "plantransactions":
        setSubMenuType("plan");
        setSelectedItemId(ID);
        setselectedMenu(17);
        handleSideMenu(false);
        break;
      case "planpayments":
        setSubMenuType("plan");
        setSelectedItemId(ID);
        setselectedMenu(17);
        handleSideMenu(false);
        break;
      case "addontransactions":
        setSubMenuType("addon");
        setSelectedItemId(ID);
        setselectedMenu(21);
        handleSideMenu(false);
        break;
      case "addonaudit":
        setSubMenuType("addon");
        setSelectedItemId(ID);
        setselectedMenu(22);
        handleSideMenu(false);
        break;
      case "pitransactions":
        fetchServiceInstance(ID);
        setSubMenuType("service");
        setSelectedItemId(ID);
        setSelectedSubscriptionStatus(status);
        setSelectedSubscription(dataItem);
        handleSideMenu(false);
        setselectedMenu(13);
        break;
      case "piaudit":
        fetchServiceInstance(ID);
        setSubMenuType("service");
        setSelectedItemId(ID);
        setSelectedSubscriptionStatus(status);
        setSelectedSubscription(dataItem);
        handleSideMenu(false);
        setselectedMenu(14);
        break;
      case "pipayments":
        fetchServiceInstance(ID);
        setSubMenuType("service");
        setSelectedItemId(ID);
        setSelectedSubscriptionStatus(status);
        setSelectedSubscription(dataItem);
        handleSideMenu(false);
        setselectedMenu(13);
        break;
      case "picancel":
        fetchServiceInstance(ID);
        setSubMenuType("service");
        setSelectedItemId(ID);
        setSelectedSubscriptionStatus(status);
        setSelectedSubscription(dataItem);
        handleSideMenu(false);
        setselectedMenu(15);
        break;
      case "piappointments":
        fetchServiceInstance(ID);
        setSubMenuType("service");
        setSelectedItemId(ID);
        setSelectedSubscriptionStatus(status);
        setSelectedSubscription(dataItem);
        handleSideMenu(false);
        setselectedMenu(12);
        break;
      case "picomplimentarycheckout":
        setSelectedButton("complimentary");
        setSubMenuType(null);
        handleSideMenu(false);
        setselectedMenu(4);
        break;
      case "piquick":
        setSelectedButton("quick");
        setSubMenuType(null);
        handleSideMenu(false);
        setselectedMenu(4);
        break;
      case "overview":
        // setSelectedButton("quick");
        setSubMenuType(null);
        handleSideMenu(false);
        setselectedMenu(1);
        break;
      case "scheduleoneone":
        setSubMenuType(null);
        handleSideMenu(false);
        setselectedMenu(4);
        break;
      case "picomplimentaryschedule":
        setSelectedButton("complimentaryschedule");
        setSubMenuType(null);
        handleSideMenu(false);
        setselectedMenu(4);
        break;
      case "piquickcheckout":
        fetchServiceInstance(ID);
        setSelectedButton("quick");
        setSubMenuType("service");
        setSelectedItemId(ID);
        setSelectedSubscriptionStatus(status);
        setSelectedSubscription(dataItem);
        handleSideMenu(false);
        setselectedMenu(11);
        break;
      case "pischedule":
        fetchServiceInstance(ID);
        setSelectedButton("schedule");
        setSubMenuType("service");
        setSelectedItemId(ID);
        setSelectedSubscriptionStatus(status);
        setSelectedSubscription(dataItem);
        handleSideMenu(false);
        setselectedMenu(11);
        break;
      case "purchasepi":
        setSubMenuType(null);
        setselectedMenu(37);
        setLoading(false);
        break;
      case "purchasegs":
        setSubMenuType(null);
        setPurchaseItem("groupevent");
        setselectedMenu(37);
        setLoading(false);
        break;
      case "purchasepk":
        setSubMenuType(null);
        setPurchaseItem("package");
        setselectedMenu(37);
        setLoading(false);
        break;
      case "purchaseaddonlink":
        setSubMenuType(null);
        setSelectedItemId(null);
        setselectedMenu(27);
        handleSideMenu(false);
        break;
      case "gsappointments":
        setSubMenuType(null);
        setSelectedItemId(null);
        setselectedMenu(5);
        handleSideMenu(false);
        break;
      case "payments":
        setSubMenuType(null);
        setSelectedItemId(null);
        setselectedMenu(46);
        handleSideMenu(false);
        break;
      case "agreements":
        setSubMenuType(null);
        setSelectedItemId(null);
        setselectedMenu(41);
        handleSideMenu(false);
        break;
    }
  };

  const goToProfile = () => {
    setSubMenuType(null);
    setselectedMenu(10);
  };
  const goToServicePage = () => {
    setSubMenuType(null);
    setselectedMenu(4);
  };
  const goToPurchasePage = () => {
    setSubMenuType(null);
    setselectedMenu(37);
    setLoading(false);
  };
  const handleRedeemPackage =() => {
    setSubMenuType(null);
    setselectedMenu(39);
    setLoading(false);
  }
  const goToPurchasePlanPage = () => {
    setPurchaseItem("plan");
    setSubMenuType(null);
    setselectedMenu(37);
    setLoading(false);
  };
  const goToAssignPlanPage = () => {
    setshowAddPlan(true);
    setSubMenuType(null);
    setSelectedItemId(null);
    setselectedMenu(27);
    handleSideMenu(false);
  };

  const goToPlanPage = () => {
    setSubMenuType(null);
    setSelectedTab(0);
    setselectedMenu(27);
    setShowAutorenewal(false)
  };

  const goToAddonPage = () => {
    setSubMenuType(null);
    setSelectedTab(1);
    setselectedMenu(27);
  };

  const goToMembershipAddonPage = () => {
    setSubMenuType("plan");
    setSelectedTab(0);
    setselectedMenu(136);
  };

  const unloadFunc = () => {
    props.history.replace({
      ...props.location,
      state: undefined,
    });
    setAddMoreClasses(null);
    setClassSelectedUserMemberId(null);
  };

  const renderBody = (menuId: number) => {
    switch (menuId) {
      case 1:
        return (
          <Overview
            {...props}
            goToProfile={() => goToProfile()}
            uuid={uuid}
            userData={userData}
            staffPage={clientId ? true : false}
            handleSelectionItem={(
              serviceId: number,
              status: string,
              type: string,
              dataItem: any
            ) => handleSelectionItem(serviceId, status, type, dataItem)}
          />
        );
      case 2:
        return (
          <CheckIns
            {...props}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
          />
        );
      case 3:
        return (
          <Appointments
            {...props}
            userData={userData}
            staffPage={clientId ? true : false}
            uuid={uuid}
          />
        );
      case 45:
        return <Notes userData={userData} />;
      case 46:
        return <PaymentMethod userData={userData} enforcePP={IsEnforced && IsCardEnforced ? true : false} 
        onSuccessfullPayment={() =>
          onSuccessfullPayment()
        }
        staffPage={clientId ? true : false}
        />;
      case 4:
        return (
          <PurchasedPackages
            {...props}
            from="online"
            staffPage={clientId ? true : false}
            uuid={uuid}
            userData={userData}
            showOnlyPreset={true}
            handlePurchase={(type: string) => handlePurchase(type)}
            handleSelectionItem={(
              selectedId: number,
              status: string,
              type: string,
              data: any
            ) => handleSelectionItem(selectedId, status, type, data)}
          />
        );
      case 5:
        return (
          <GroupServices
            {...props}
            from="online"
            staffPage={clientId ? true : false}
            uuid={uuid}
            userData={userData}
            selectedTab={selectedTab}
            handlePurchase={(type: string) => handlePurchase(type)}
            handleSelectionItem={(
              selectedId: number,
              status: string,
              type: string
            ) => handleSelectionItem(selectedId, status, type)}
          />
        );
      case 6:
        return (
          <Transactions
            {...props}
            allItems={true}
            uuid={uuid}
            userData={userData}
            staffPage={clientId ? true : false}
            onSuccessfullPayment={() => onSuccessfullPayment()}
          />
        );
      case 7:
        return (
          <Plans
            {...props}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            handleSelectionItem={(
              selectedId: number,
              status: string,
              type: string,
              dataItem: any
            ) => handleSelectionItem(selectedId, status, type, dataItem)}
          />
        );
      case 8:
        return (
          <Addons
            {...props}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            handleSelectionItem={(
              selectedId: number,
              status: string,
              type: string,
              dataItem: any
            ) => handleSelectionItem(selectedId, status, type, dataItem)}
          />
        );
      case 9:
        return (
          <AuditHistory
            {...props}
            allItems={true}
            heading={true}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            UserMemberId={userData?.UserMemberId}
          />
        );
      case 10:
        return (
          <MyProfile
            userData={userData}
            handleAfterSubmit={(userMember: any) =>
              handleAfterSubmit(userMember)
            }
            staffPage={clientId ? true : false}
          />
        );
      case 11:
        return (
          <ServiceOverview
            {...props}
            staffPage={clientId ? true : false}
            uuid={uuid}
            selectedService={selectedItemId}
            userData={userData}
            selectedButton={selectedButton}
            goToProfile={() => goToProfile()}
            handleSubMenuNav={(id: number, parent: boolean) =>
              handleMenuSelection(id, parent)
            }
          />
        );
      case 12:
        return (
          <Appointments
            {...props}
            selectedService={selectedItemId}
            serviceInstance={serviceInstance}
            userData={userData}
            staffPage={clientId ? true : false}
            uuid={uuid}
            dontIncludeSubmember={true}
          />
        );
      case 13:
        return (
          <Payments
            {...props}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            selectedService={selectedItemId}
            serviceInstance={serviceInstance}
            onSuccessfullPayment={() => onSuccessfullPayment()}
            fetchServiceInstance={(id: any) => fetchServiceInstance(id)}
          />
        );
      case 14:
        return (
          <AuditHistory
            {...props}
            EntityId={selectedItemId}
            UserMemberId={userData?.UserMemberId}
            Type={8}
            heading={true}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
          />
        );
      case 15:
        return (
          <IndividualServiceCancel
            {...props}
            goToServicePage={goToServicePage}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            EntityId={selectedItemId}
            UserMemberId={userData?.UserMemberId}
          />
        );
      case 16:
        return (
          <>
            <PlanOverview
              {...props}
              selectedPlanId={selectedPlanIdVal}
              userData={userData}
              showAutoRenewal={showAutoRenewal}
              uuid={uuid}
              staffPage={clientId ? true : false}
              handleCloseAutoRenewal={handleCloseAutoRenewal}
              goToProfile={() => goToProfile()}
              setParentId={(id: any) => {
                setParentId(id);
                setSubMenuType("plan");
              }}
              setSelectedSubscription={setSelectedSubscription}
            />
          </>
        );
      case 17:
        return (
          <>
            <PlanPayments
              {...props}
              selectedPlanId={selectedPlanIdVal}
              userData={userData}
              uuid={uuid}
              staffPage={clientId ? true : false}
              goToProfile={() => goToProfile()}
              onSuccessfullPayment={() => onSuccessfullPayment()}
            />
          </>
        );
      case 19:
        return (
          <>
            <AuditHistory
              {...props}
              EntityId={selectedPlanIdVal}
              UserMemberId={userData?.UserMemberId}
              Type={2}
              heading={true}
              userData={userData}
              uuid={uuid}
              staffPage={clientId ? true : false}
            />
          </>
        );
      case 20:
        return (
          <AddonOverView
            {...props}
            selectedAddonId={selectedItemId}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            goToProfile={() => goToProfile()}
          />
        );
      case 21:
        return (
          <AddonPayments
            {...props}
            selectedAddonId={selectedItemId}
            onSuccessfullPayment={() => onSuccessfullPayment()}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
          />
        );
      case 22:
        return (
          <AuditHistory
            {...props}
            EntityId={selectedItemId}
            UserMemberId={userData?.UserMemberId}
            Type={16}
            heading={true}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
          />
        );
      case 23:
        return (
          <PackageOverView
            {...props}
            selectedPackageId={selectedItemId}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            goToProfile={() => goToProfile()}
            setSelectedSubscription={setSelectedSubscription}
            setSelectedSubscriptionStatus={setSelectedSubscriptionStatus}
            handleSubMenuNav={(id: number, parent: boolean) =>
              handleMenuSelection(id, parent)
            }
            showPreset={preset}
          />
        );
      case 24:
        return (
          <PackagesSummary
            {...props}
            PackageInstanceId={selectedItemId}
            UserMemberId={userData?.UserMemberId}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            from={"online"}
          />
        );
      case 25:
        return (
          <PackagePayments
            selectedPackageId={selectedItemId}
            FilterType={512}
            userData={userData}
            uuid={uuid}
            onSuccessfullPayment={() => onSuccessfullPayment()}
            staffPage={clientId ? true : false}
            
          />
        );
      case 26:
        return (
          <AuditHistory
            {...props}
            EntityId={selectedItemId}
            UserMemberId={userData?.UserMemberId}
            Type={512}
            heading={true}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
          />
        );
      case 27:
        return (
          <PlansAndAddons
            {...props}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            selectedTab={selectedTab}
            goToPurchasePlanPage={goToPurchasePlanPage}
            showAddPlan={showAddPlan}
            handleSelectTab={(e: any) => handleSelectTab(e)}
            handleSelectionItem={(
              selectedId: number,
              status: string,
              type: string,
              dataItem: any
            ) => handleSelectionItem(selectedId, status, type, dataItem)}
          />
        );
      case 28:
        return (
          <AddonCancel
            {...props}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            selectedAddonId={selectedItemId}
          />
        );
      case 29:
        return (
          <>
            <PlanCancel
              {...props}
              userData={userData}
              uuid={uuid}
              staffPage={clientId ? true : false}
              selectedPlanId={selectedPlanIdVal}
              gotoPlanOverview={() => gotoPlanOverview()}
              gotoPlanRenewalPage={() => gotoPlanRenewalPage()}
            />
          </>
        );
      case 30:
        return (
          <AddonFreeze
            {...props}
            selectedAddonId={selectedItemId}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
          />
        );
      case 31:
        return (
          <PlanFreeze
            {...props}
            selectedPlanId={selectedPlanIdVal}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            gotoPlanOverview={() => gotoPlanOverview()}
          />
        );
      case 32:
        return (
          <ActivateSuspended
            {...props}
            selectedPlanId={selectedPlanIdVal}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            gotoPlanOverview={() => gotoPlanOverview()}
          />
        );
      case 33:
        return (
          <AuditHistory
            {...props}
            EntityId={selectedSubscription?.MemberPlanId}
            UserMemberId={userData?.UserMemberId}
            Type={2}
            TypeOfChange={256}
            heading={true}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
          />
        );
      case 34:
        return (
          <QuickCheckout
            {...props}
            EntityId={selectedItemId}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
          />
        );
      case 35:
        return (
          <ComplimentaryCheckout
            {...props}
            uuid={uuid}
            EntityId={selectedItemId}
            userData={userData}
            staffPage={clientId ? true : false}
          />
        );
      case 36:
        return (
          <AssignAddon
            {...props}
            EntityId={selectedItemId}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            UserMemberId={userData?.UserMemberId}
            selectedPlanId={selectedPlanIdVal}
            goToAddonPage={goToMembershipAddonPage}
          />
        );
      case 136:
        return (
          <Addons
            {...props}
            EntityId={selectedItemId}
            userData={userData}
            uuid={uuid}
            staffPage={clientId ? true : false}
            UserMemberId={userData?.UserMemberId}
            selectedPlanId={selectedPlanIdVal}
            membershipDetails={selectedSubscription}
            goToAddonPage={goToAddonPage}
            handleSelectionItem={(
              selectedId: number,
              status: string,
              type: string,
              dataItem: any
            ) => handleSelectionItem(selectedId, status, type, dataItem)}
          />
        );
      case 37:
        return (
          <Purchase
            {...props}
            uuid={uuid}
            purchaseItem={purchaseItem}
            EntityId={selectedItemId}
            addMoreClasses={addMoreClasses}
            classSelectedUserMemberId={classSelectedUserMemberId}
            unloadFunc={() => unloadFunc()}
            userData={userData}
            staffPage={clientId ? true : false}
          />
        );
      case 38:
        return (
          <CancelPackage
            {...props}
            fromDashboard={true}
            EntityId={selectedItemId}
            userData={userData}
            packageData={{
              PackageInstanceId: selectedItemId,
              UserMemberId: userData?.UserMemberId,
              CanCollectTaxes:selectedSubscription?.CancollectTaxes,
              RenewalType:selectedSubscription?.RenewalType,
              UnusedPaidSchedules:selectedSubscription?.UnusedPaidSchedules,
            }}
            uuid={uuid}
            staffPage={clientId ? true : false}
            UserMemberId={userData?.UserMemberId}
            gotoPackageOverview={() => gotoPackageOverview()}
          />
        );
      case 39:
        return (
          <PurchasedPackages
            {...props}
            from="online"
            staffPage={clientId ? true : false}
            uuid={uuid}
            userData={userData}
            handlePurchase={(type: string) => handlePurchase(type)}
            handleSelectionItem={(
              selectedId: number,
              status: string,
              type: string,
              data: any
            ) => handleSelectionItem(selectedId, status, type, data)}
          />
        );
      case 40:
        return (
          <AccessComponent
            {...props}
            staffPage={clientId ? true : false}
            uuid={uuid}
            userData={userData}
          />
        );
      case 41:
        return (
          <Agreements
            {...props}
            staffPage={clientId ? true : false}
            uuid={uuid}
            userData={userData}
            type={subMenuType}
            IsChild={false}
          />
        );
      case 42:
        return (
          <Agreements
            {...props}
            staffPage={clientId ? true : false}
            uuid={uuid}
            userData={userData}
            selectedItemId={parentId || selectedItemId}
            type={subMenuType}
            IsChild={true}

          />
        );
      case 43:
        handleLogout();
        return <></>;
      case 44:
        return (
          <PackageUtilization
            EntityId={selectedItemId}
            userData={userData}
            packageData={{
              PackageInstanceId: selectedItemId,
              UserMemberId: userData?.UserMemberId,
            }}
            uuid={uuid}
            staffPage={clientId ? true : false}
            UserMemberId={userData?.UserMemberId}
            gotoPackageOverview={() => gotoPackageOverview()}
          />
        );
      case 47:
          return (
            <AuditHistory
              {...props}
              EntityId={selectedItemId}
              UserMemberId={userData?.UserMemberId}
              Type={512}
              TypeOfChange={256}
              heading={true}
              userData={userData}
              uuid={uuid}
              staffPage={clientId ? true : false}
            />
          );
      case 48:
          return (<OneTimeCharge UserMemberId={userData?.UserMemberId} userDetails={userData} />)
      case 49:
          return (<Forms UserMemberId={userData?.UserMemberId} userDetails={userData} staffPage={clientId ? true : false} enforceForm={IsEnforced && IsFormsEnforced ? true  :false} onSuccessfullFormSubmit={() =>
            onSuccessfullPayment()
          } />)
      default:
        return (
          <>
            <BuzopsLoader type="list" />
          </>
        );
    }
  };

  const getMenuItems = () => {
    switch (subMenuType) {
      case null:
        return MainMenuItems;
      case "service":
        return serviceMenuItems;
      case "plan":
        return planMenuItems;
      case "addon":
        return planMenuItems;
      case "package":
        return packageMenuItems;
      default:
        return MainMenuItems;
    }
  };
  const handleBackBtn = () => {
    dispatch(SetSelectedPlan(null));
    dispatch(SetSelectedAddon(null));
    setCurrentUserData();
    setSelectedTab(0);
    switch (subMenuType) {
      case "service":
        setSubMenuType(null);
        setselectedMenu(4);
        break;
      case "plan":
        setSubMenuType(null);
        setselectedMenu(27);
        setshowAddPlan(false);
        setShowAutorenewal(false)
        break;
      case "addon":
        setSubMenuType(null);
        setselectedMenu(27);
        setshowAddPlan(false);
        break;
      case "package":
        setSubMenuType(null);
        if(preset){
          setselectedMenu(4);
          setpreset(false);
        }else{
          setselectedMenu(39);
        }
        
        break;
      default:
        setselectedMenu(1);
        break;
    }
  };
  const handleSideMenu = (check: boolean) => {
    setShowSideMenu(check);
  };
  const menuItems = getMenuItems();
  let responsiveClass = "";
  if (width < breakpoint) {
    responsiveClass = "bzo-mobile-responsive";
    if (showSideMenu) {
      responsiveClass = "bzo-mobile-responsive active";
    }
  }
  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  const renderClientInfo = (selectedMenu: number) => {
    const subSelectionItems = [
      ...planMenuItems,
      ...serviceMenuItems,
      ...packageMenuItems,
      ...addOnMenuItems,
    ];
    if (subSelectionItems.findIndex((val) => val.id === selectedMenu) !== -1) {
      return (
        <>
          <ClientOverviewInfo
            selectedName={
              selectedSubscription?.EntityName ||
              selectedSubscription?.PlanName ||
              selectedSubscription?.Name
            }
            module={subMenuType}
            clientName={
              selectedSubscription?.MemberName ||
              `${selectedSubscription?.FirstName} ${selectedSubscription?.LastName}`
            }
            phoneNo={selectedSubscription?.Phone || (selectedSubscription?.MemberType !==2 ? userData?.Phone:"")}
            email={selectedSubscription?.Email || (selectedSubscription?.MemberType !==2 ? userData?.Email:"")}
            onEditClick={() => goToProfile()}
            memberType={selectedSubscription?.MemberType}
            selectedSubscription={selectedSubscription}
          />
          <hr />
        </>
      );
    }
  };

  const onSuccessfullPayment = async () => {
    let UserMemberid = GetLocalStore("UserMemberId");
    await getAllSubMembers(UserMemberid);
    await fetchUserDetails(UserMemberid);
  };
  return (
    <>
      {userData ? (
        <div className={`bz-online-main-row ${responsiveClass}`}>
          <div className="bz-main-menu-area">
            <Card className="bz-card bz-summary-menus-card">
              <CardBody>
                <span
                  className="bz-menu-close-icon"
                  role={"button"}
                  title={"Close Menu"}
                  onClick={() => handleSideMenu(false)}
                >
                  <i className="fas fa-times"></i>
                </span>
                <div className="menu-row ">
                  {subMenuType !== null && (
                    <>
                      <div className="mini-menu">
                        <>{renderMenus(false, MainMenuItems)}</>
                      </div>
                    </>
                  )}
                  <div className="main-menu">
                    {subMenuType !== null && (
                      <button
                        type="button"
                        className="btn btn-primary bz-back-btn btn-sm"
                        onClick={() => {
                          handleBackBtn();
                        }}
                      >
                        <i className="fas fa-arrow-left"></i>
                      </button>
                    )}
                    <h6 className="card-title bz-text-capitalize bz-fw-6 ml-2 mb-0">
                      {tenantName}
                    </h6>
                    <hr />
                    <>{renderMenus(true, menuItems)}</>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="bz-content-area">
            {(!CheckUserMemberStatus(userData) || userData.Status === 8) && (
                <div
                  className="alert alert-warning fade show"
                  role="alert"
                >
                  <span>
                    {userData.Status === 8
                      ? `Action Required on Chargeback/Return transaction. `
                      : `Action Required on Chargeback (CC) / Return (ACH) transaction, no action can be performed until the dispute is resolved. `}
                    {ChargeBackPermission() ? (
                      <>
                        <ChargeBackGlobal
                          clientId={clientId}
                          onSuccessfullPayment={() => onSuccessfullPayment()}
                          paymentTitle={
                            <a className="btn btn-link text-left mb-1">
                              <u>Please click here to make a payment.</u>
                            </a>
                          }
                        />
                      </>
                    ) : (
                      <>{`Please contact administrator.`}</>
                    )}
                  </span>
                </div>
              )}
            <div className="k-overlay bz-overlay-menu"></div>
            <Button
              onClick={() => handleSideMenu(true)}
              className={
                clientId
                  ? "bz-menu-btn bz-menu-btn-staff"
                  : "bz-menu-btn bz-menu-btn-client"
              }
            >
              <span>
                <img src={menuIcon} />
              </span>
            </Button>
            {selectedSubscription &&
              Object.keys(selectedSubscription).length > 0 &&
              renderClientInfo(selectedMenu)}
            {renderBody(selectedMenu)}
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default withNotification(Summary)
