import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import {
  CheckUserMemberStatus,
  getUserTimeZoneLong,
  PrivateServiceType,
  ScheduleStatus,
} from "utils";
import { TenantService } from "services/tenant/index.service";
import CheckPermissions from "utils/permissions";
import { PermissionEnum } from "utils/PermissionEnum";
import { GetLocalStore } from "utils/storage";
import { allTimeZonesArray, PrivateServiceTypeEnum } from "utils/form-utils";
import { useSelector } from "react-redux";
import { ClassService } from "services/class/index.service";
import { useHistory } from "react-router";
import { upcomingAppointmentEnum } from "./UpcomingAppointments";
import { SimpleAccessService } from "services/simpleAccess/index.service";
import { CheckInService } from "services/checkin/index.service";
import { appointmentTab } from "utils/utils";
import useSecuritySystemConfigs from "components/CheckIns/Container/SecuritysystemConfigs.hook";
import useCheckinUser from "components/CheckIns/Container/CheckinUser.hook";
const initialSelectedWaitlist={
  ScheduleId:0,loading:false
}
export const useUpcomingAppointments = (props: any) => {
  const hasMounted = useRef(false);
  const tenantService = new TenantService();
  const userSession = JSON.parse(
    JSON.stringify(GetLocalStore("tenantUserSession"))
  );
  const {
    securityConfig,
    isOpenPathSystem,
    isBarcodeSystem
  } = useSecuritySystemConfigs();
  const {
    checkIfUserCanCheckinForBarcode,
    checkIfUserCanCheckinForOpenPath
  } = useCheckinUser(props)
  const UserAssociateId = userSession.UserAssociateId;
  const { userData } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [searchAppointments, setSearchAppointments] = useState<any>({
    pageNumber: 0,
    maxPageNumber: 0,
    upcomingAppointmentsData: [],
  });
  const redirectionObj = useSelector((state: any) => state?.redirection);
  const classService = new ClassService();
  const history = useHistory();
  const { type, all = false, staffPage = false, ShowComplimentarySessionsOnly = false,onReload} = props;

  const [visible, setVisible] = useState({
    delete: false,
    notes: false,
    revert: false,
  });
  const [selectedAppt, setSelectedAppt] = useState<any>(null);
  const [notes, setNotes] = useState<any>("");
  const [notesLoading, setNotesLoading] = useState<any>(false);
  const [isLoading, setIsLoading] = useState({
    cancelBtnLoading: false,
    canceRecurrencelBtnLoading: false,
  });
  const [selectedWaitListClient,setSelectedWaitlistClient] = useState<any>(initialSelectedWaitlist);
  const [renewConfirmDialog, setRenewConfirmDialog] = useState(false);
  const [changePaymentObj, setChangePaymentItem] = useState<any>(null);
  const tzvalueName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneFullName = allTimeZonesArray.filter(
    (i: any) => i?.key === tzvalueName
  )?.[0]?.name;

  // check-ins state management
  const [transactionState, setTransactionState] = useState({
    visible: false,
    data: {},
  });
  const [cancelClients, setCancelClients] = useState<any>([]);
  const [session, setSession] = useState<any>(null);
  const [visibility, setVisibility] = useState({
    cancel: false,
    changePayment: false,
    cancelAppointment:false
  });
  const [userDetail, setUserDetail] = useState({});
  const [btndisableRenew,setbtndisableRenew]=useState(false);

  const onWindowClose = () => {
    setTransactionState({
      visible: false,
      data: {},
    });
    getUpcomingAppointments(0);
    if(transactionState?.data?.EntityType === 3 && transactionState?.data?.HasPackage === false){
      onReload(true);
    }
    
  };
  const handleVisibility=(handleEvent: any)=>{
    setVisibility(handleEvent);
    getUpcomingAppointments(0);
  }

  const itemsList = [
    {
      id: 1,
      icon: "",
      text: "Show",
      visibleForPast: true,
      visibleForFuture: false,
      visibleForClient: false,
      visibleForGs: true,
      visibleForIs: true,
      disabled: false,
    },
    {
      id: 2,
      icon: "",
      text: "No Show",
      visibleForPast: true,
      visibleForFuture: false,
      visibleForClient: false,
      visibleForGs: true,
      visibleForIs: true,
      disabled: false,
    },
    {
      id: 3,
      icon: "",
      text: "Dispute",
      visibleForPast: true,
      visibleForFuture: false,
      visibleForClient: false,
      visibleForGs: true,
      visibleForIs: true,
      disabled: false,
    },
    {
      id: 4,
      icon: "",
      text: "Notes",
      visibleForPast: true,
      visibleForFuture: true,
      visibleForClient: true,
      visibleForGs: true,
      visibleForIs: true,
      disabled: false,
    },
    {
      id: 5,
      icon: "",
      text: "Cancel",
      visibleForPast: true,
      visibleForFuture: true,
      visibleForClient: true,
      visibleForIs: true,
      visibleForGs: true,
      disabled: false,
    },
    {
      id: 6,
      icon: "",
      text: "Reschedule",
      visibleForPast: true,
      visibleForClient: true,
      visibleForFuture: true,
      visibleForGs: false,
      visibleForIs: true,
      disabled: false,
    },
    {
      id: 7,
      icon: "",
      text: "Revert Appointment Completion",
      visibleForPast: true,
      visibleForFuture: false,
      visibleForClient: false,
      visibleForGs: false,
      visibleForIs: true,
      disabled: false,
    },
  ];
  const checkinItem = {
    id: 9,
    icon: "",
    text: "Check-In",
    visibleForPast: true,
    visibleForFuture: true,
    visibleForClient: true,
    visibleForGs: true,
    visibleForIs: true,
    disabled: false,
  };

  const payRemainingDueItem = {
    id: 19,
    icon: "",
    text: upcomingAppointmentEnum.payNow,
    visibleForPast: false,
    visibleForFuture: true,
    visibleForClient: true,
    visibleForGs: true,
    visibleForIs: true,
    disabled: false,
  }

  const WaitListItems= [
    {
      id: 4,
      icon: "",
      text: "Notes",
      visibleForPast: true,
      visibleForFuture: true,
      visibleForClient: true,
      visibleForGs: true,
      visibleForIs: true,
      disabled: false,
    },
    {
      id: 19,
      icon: "",
      text: "Approve",
      visibleForPast: false,
      visibleForFuture: true,
      visibleForClient: false,
      visibleForGs: true,
      visibleForIs: false,
      disabled: false,
    },
    {
      id: 5,
      icon: "",
      text: "Deny",
      visibleForPast: false,
      visibleForFuture: true,
      visibleForClient: false,
      visibleForIs: false,
      visibleForGs: true,
      disabled: false,
    },
    {
      id: 20,
      icon: "",
      text: "Change Payment",
      visibleForPast: false,
      visibleForFuture: true,
      visibleForClient: false,
      visibleForIs: false,
      visibleForGs: true,
      disabled: false,
    },
  ]
  const getUpcomingAppointments = async (
    pageNumber: number,
    serviceId?: any
  ) => {
    const tzvalueName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(tzvalueName,"tzvalueName")
    let tzlabelVal;
    if (!props?.location?.state?.staffPage) {
      tzlabelVal = getUserTimeZoneLong(tzvalueName);
    }
    console.log(tzlabelVal,"tzlabelVal")

    const fetchAppointments = new TenantService();
    const pageSize = 20;
    let IsFromCalendar = false;
    let HasSpecificMember = false;
    let CanIncludeSubMember = true;
    if (!props?.serviceId) {
        HasSpecificMember = true
        IsFromCalendar = true
      }
    let req: any = {
      PageNumber: pageNumber,
      PageSize: pageSize,
      ShowFutureDate: true,
      UserMemberId: userData?.EntityUserMemberId || userData?.UserMemberId,
      UserTimeZoneId: tzlabelVal,
      IsFromCalendar: IsFromCalendar,
      HasSpecificMember: HasSpecificMember,
      CanIncludeSubMember: CanIncludeSubMember,
      SortDirection:'asc',
      FutureSchedules:true
    };
    if (props?.serviceId) {
      req = {
        ...req,
        ServiceInstanceId: props?.serviceId,
      };
    }
    if(props?.isPackage){
      req = {
        ...req,
        IsPackageInstance: props?.isPackage,
      }
    }
    if(props?.serviceFilter !== 0){
      if(props?.serviceFilter === 1){
        req = {
          ...req,
          ServiceFilter: "Group",
        }
      }else if(props?.serviceFilter === 2){
        req = {
          ...req,
          ServiceFilter: "Private",
        }
      }else if(props?.serviceFilter === 3){
        req = {
          ...req,
          ServiceFilter: "SemiPrivate",
        }
      }else if(props?.serviceFilter === 4){
        req = {
          ...req,
          ServiceFilter: 16,
        }
      }
    }
    if(props?.req){
      req = {
        ...props?.req, PageNumber: pageNumber,
        PageSize: pageSize,
      }
    }else if (type === appointmentTab.UPCOMING) {
      req = {
        ...req,
        FromDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        CanFilterUpToSpecifiedToTime: true,
        Status: 1,
      };
      if(props?.fromDateToDate?.from && props?.fromDateToDate?.to){
        let fromDateValue=props?.fromDateToDate?.from
        if(moment(fromDateValue).format("YYYY-MM-DD")===moment().format("YYYY-MM-DD")){
          fromDateValue=moment().format("YYYY-MM-DDTHH:mm:ss")
        }
        req= {
          ...req,
          FromDate: fromDateValue,
          ToDate: props?.fromDateToDate?.to
        }
      }
    } else if (type === appointmentTab.COMPLETED) {
      req = {
        ...req,
        ShowFutureDate: false,
        ToDate: moment().subtract(1, 'day').format("YYYY-MM-DD") + "T23:59:59",
        CanFilterUpToSpecifiedToTime: true,
        Status: 2,
        UserTimeZoneId: tzlabelVal,
        SortDirection:'desc'
      };
      if(props?.fromDateToDate?.from && props?.fromDateToDate?.to){
        let completedEndDate=moment().format("YYYY-MM-DDTHH:mm:ss")
        if(moment(props?.fromDateToDate?.to).format("YYYY-MM-DD") < moment(completedEndDate).format("YYYY-MM-DD")){
          completedEndDate=props?.fromDateToDate?.to
        }
        req= {
          ...req,
          FromDate: props?.fromDateToDate?.from,
          ToDate: completedEndDate
        }
      }
    }
    req = {...req, ShowComplimentarySessionsOnly};
    if (pageNumber === 0) {
      setLoading(true);
    }
    const result = await fetchAppointments.getUpcomingAppointments(req);
    if (pageNumber === 0) {
      setLoading(false);
    }else {
    }
    if (result) {
      const maxPageNumber = result.TotalItems / pageSize;
      if (pageNumber === 0) {
        setSearchAppointments({
          ...searchAppointments,
          pageNumber: pageNumber,
          maxPageNumber: maxPageNumber,
          upcomingAppointmentsData: result?.Result || [],
        });
      }else {
        setSearchAppointments({
            ...searchAppointments,
            pageNumber: pageNumber,
            maxPageNumber: maxPageNumber,
            upcomingAppointmentsData: searchAppointments.upcomingAppointmentsData.concat(result?.Result)
          });
      }
    }
    return result?.Result || [];
  };


  const fetchClientsForSession = async (sessionData: any) => {
    const req = {
      ClassSessionId: sessionData?.EntityId,
      UserId: sessionData?.MemberUserId,
    };

    const service = new ClassService();
    const result = await service.getClientsForSession(req);
    if (result?.ResponseCode === 100) {
      setCancelClients(result?.Items || []);
    }
    return result?.Items || []
  };
  const fetchClassSession = async (dataItem: any, type = "cancel") => {
    const service = new ClassService();
    const result = await service.getClassSession(dataItem?.EntityId);
    const res = {
      ClassSessionStartDate: result?.StartTime,
      ClassSessionEndTime: result?.EndTime,
      ClassSessionId: result?.ClassSessionId,
      CancellationFee: result?.CancellationFee,
      Cost: result?.DropInPrice,
      ClassName: dataItem?.ServiceName,
    };
    setSession(res);
  };

  const handlegroupEventDeleteView = async (value: any) => {
    await fetchClassSession(value);
    await fetchClientsForSession(value);
    setVisibility({ ...visibility, cancel: true });
  };
  const handlegroupEventChangePaymentView = async (value: any) => {
    await fetchClassSession(value);
    const res=await fetchClientsForSession(value);
    const dataItem = res[0]
    const obj = {
      ClassSessionId: value?.ServiceId,
      ClassId: value?.ClassId,
      ScheduleId: dataItem?.ScheduleId,
      UserId: dataItem?.UserId,
      UserMemberId: dataItem?.UserMemberId,
      PaymentMode: dataItem?.PaymentMode,
      PaymentGatewayTransactionHistoryId: dataItem?.PaymentGatewayTransactionHistoryId,
      DownPayment: dataItem.DropInPrice,
      Name: value.ServiceName,
      ProfileImage: "",
      CanCollectTaxes:dataItem?.CancollectTaxes || false
    };
    console.log(obj)
    setChangePaymentItem(obj);
    setVisibility({ ...visibility, changePayment: true });
  };

  const CanRevertSchedule = (dataItem: any) => {
    return (
      CheckPermissions(PermissionEnum["Level3"]) &&
      dataItem?.Status !== ScheduleStatus["Active"]
    );
  };


  const handleUpdateStatus = async (
    dataItem: any,
    text: string,
    restrict = true
  ) => {
    let statusUpdate;
    let Status = 1;
    if (dataItem.ShowRenewalConfirmation && restrict) {
      setRenewConfirmDialog(true);
      return true;
    }
    if (text === "Show") {
      Status = 2;
    } else if (text === "No Show") {
      Status = 3;
    } else if (text === "Dispute") {
      Status = 4;
    }

    if(dataItem?.CanRenew){
      setbtndisableRenew(true);
    }


    if (dataItem?.EntityType === 2) {
      const scheduleNameText=text?.replace(/\s/g, "")
      const ChangeScheduleStatusList = {
        ScheduleId: dataItem.ScheduleId,
        ClientId: userData?.UserMemberId,
        Status: scheduleNameText,
      };
      const req = {
        ClassSessionId: dataItem?.EntityId,
        ChangeScheduleStatusList: [ChangeScheduleStatusList],
      };
      statusUpdate = await classService.updateClassSessionStatus(req);
    } else {
      const req = {
        ScheduleId: dataItem.ScheduleId,
        Status: Status,
        CanRenew: dataItem?.CanRenew,
      };
      statusUpdate = await tenantService.UpdateStatusWithNotes(req);
    }

    if (statusUpdate.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Status Successfully updated`,
        "success"
      );
    } else {
      const errorMsg =
        statusUpdate?.ErrorMessages[0] || "Error in Updating Status";
      props?.handleNotificationMessage(errorMsg, "error");
    }
    getUpcomingAppointments(0, null)
    setRenewConfirmDialog(false);
    setbtndisableRenew(false);
  };
  const toggleDialog = () => {
    setVisible({
      delete: false,
      notes: false,
      revert: false,
    });
    setNotes("");
  };

  const cancelAppointment = async (item: any, recurrence = false) => {
    if (recurrence) {
      setIsLoading({ ...isLoading, canceRecurrencelBtnLoading: true });
    } else {
      setIsLoading({ ...isLoading, cancelBtnLoading: true });
    }
    const data = item;
    let req: any = {
      ScheduleId: data.ScheduleId,
      ClientEmail: data.ClientEmail,
    };
    if (recurrence) {
      req = {
        ScheduleId: data.ScheduleId,
        ClientEmail: data.ClientEmail,
        IsDeleteRecurrenceSeries: true,
      };
    }
    const cancelReq = await tenantService.cancelUpcomingAppointments(req);
    if (recurrence) {
      setIsLoading({ ...isLoading, canceRecurrencelBtnLoading: false });
    } else {
      setIsLoading({ ...isLoading, cancelBtnLoading: false });
    }
    toggleDialog();
    if (cancelReq.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Appointments cancelled Successfully`,
        "success"
      );
      getUpcomingAppointments(0, null)
    } else {
      if (cancelReq?.ErrorMessages) {
        const errorMsg = cancelReq?.ErrorMessages[0];
        props?.handleNotificationMessage(errorMsg, "error");
      }
    }
  };
  const addNotes = (e: any) => {
    setNotes(e.target.value);
  };
  const SubmitNotes = async (item: any) => {
    let notesSubmit;
    setNotesLoading(true);

    if (item?.EntityType === 2) {
      const ChangeScheduleStatusList = {
        ScheduleId: item.ScheduleId,
        ClientId: userData?.UserMemberId,
        notes: notes?.trim(),
      };
      const req = {
        ClassSessionId: item?.EntityId,
        ChangeScheduleStatusList: [ChangeScheduleStatusList],
      };
      notesSubmit = await classService.updateClassSessionStatus(req);
    } else {
      const req = {
        ScheduleId: item.ScheduleId,
        Notes: notes?.trim(),
        Status: item.Status,
      };
      notesSubmit = await tenantService.UpdateStatusWithNotes(req);
    }

    setNotesLoading(false);
    if (notesSubmit.ResponseCode === 100) {
      props?.handleNotificationMessage(`Notes Successfully updated`, "success");
    } else {
      const errorMsg =
        notesSubmit?.ErrorMessages[0] || "Error in Updating Notes";
      props?.handleNotificationMessage(errorMsg, "error");
    }
    toggleDialog();
    getUpcomingAppointments(0, null)
  };
  const revertAppointment = async (item: any) => {
    const data = item;
    const req = {
      ScheduleId: data.ScheduleId,
      ClientEmail: data.ClientEmail,
      RevertAppointmentCompletion: true,
    };
    setIsLoading({ ...isLoading, cancelBtnLoading: true });
    const cancelReq = await tenantService.cancelUpcomingAppointments(req);
    setIsLoading({ ...isLoading, cancelBtnLoading: false });
    if (cancelReq.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Appointment Reverted Successfully`,
        "success"
      );
    } else {
      if (cancelReq?.ErrorMessages) {
        const errorMsg = cancelReq?.ErrorMessages[0];
        props?.handleNotificationMessage(errorMsg, "error");
      }
    }
    toggleDialog();
    getUpcomingAppointments(0, null)
  };
  const handleSelection = async(text: any, item: any) => {
    //check Entity Type for group class or one-on-one events
    if (!staffPage && !CheckUserMemberStatus(userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (staffPage && !CheckUserMemberStatus(userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    if(text === upcomingAppointmentEnum.payNow) {
      
      setTransactionState({
        visible: true,
        data: item,
      });
      return;
    }
    if(text === "Change Payment") {
      console.log(item,'item')
      handlegroupEventChangePaymentView(item)
      return;
    }
    if (text === "Check-In") {
      handleCheckIn(item);
      return true;
    }
    if (item?.EntityType === 2) {
      // need to implement api for Group services
      if (text === "Notes") {
        setVisible({
          ...visible,
          notes: true,
        });
        setNotes(item?.TrainerComments);
        setSelectedAppt(item);
      } else if (text === "Cancel" || text === "Deny") {
        if(item?.IsWaitList){
          const userSession = GetLocalStore("tenantUserSession");
          const reqObj: any = {
            ClubDateTime: new Date(),
            ApplicationType: userSession?.CurrentApplicationType,
            CurrentUserId: userSession?.UserId,
            classSessionUserMappings: [
              {
                ClassSessionId: item?.ServiceId,
                ScheduleId: item.ScheduleId,
                UserId: item?.MemberUserId,
                UserMemberId: item?.UserMemberId,
                ClassInstanceId: item?.ClassInstanceId,
                Refund: 0,
                Charge: 0,
                ChargeType: "NOCHARGE",
                CanCollectTaxes: false,
              },
            ],
            CanEmailToClient: true,
            IsDenyWaitlistEnrollment:true
          };
          setSelectedWaitlistClient({
            ScheduleId:item.ScheduleId,
            loading:true
          })
          const res = await classService.cancelClassSession(reqObj);
          setSelectedWaitlistClient(initialSelectedWaitlist)
          if (res?.ResponseCode === 100) {
            props?.handleNotificationMessage(
              text === "Cancel" ? "Waitlist session cancelled successfully":"Waitlist Denied",
              "success"
            );
            getUpcomingAppointments(0, null);
          }else {
            const errorMsg =
              res?.ErrorMessages.length > 0
                ? res?.ErrorMessages?.[0]
                : "Error in denying waitlist";
            props?.handleNotificationMessage(errorMsg, "error");
          }
          return
        }
        
        if(staffPage || moment(item?.CancelDuration).format('YYYYMMDDHHmmss') > moment().format('YYYYMMDDHHmmss')){
          console.log('in')
          if (props?.deleteGs) {
            handlegroupEventDeleteView(item);
          }
        }else {
          console.log('in')
          props?.handleNotificationMessage(
            "Group can not be cancelled as it crossed Minimum Cancellation Duration.",
            "error"
          );
        }
      } else if(text === "Approve"){
        if(item?.IsWaitList){
          const req = {
            classSessionId: item?.ServiceId,
            scheduleId: item.ScheduleId,
          };
          setSelectedWaitlistClient({
            ScheduleId:item.ScheduleId,
            loading:true
          })
          const res = await classService.ApproveWaitListForClient(req);
          setSelectedWaitlistClient(initialSelectedWaitlist)
          if (res?.ResponseCode === 100) {
            props?.handleNotificationMessage(
              "Waitlist approved successfully, payment or package credit was applied successfully.",
              "success"
            );
            getUpcomingAppointments(0, null);
          }else {
            const errorMsg =
              res?.ErrorMessages.length > 0
                ? res?.ErrorMessages?.[0]
                : "Error in Approving Waitlist";
            props?.handleNotificationMessage(errorMsg, "error");
            getUpcomingAppointments(0, null);
          }
          return
        }
      }else{
          setSelectedAppt({ ...item, text: text });
          //api for Show,No show and dispute
          handleUpdateStatus(item, text);
      }
    } else {
      if (text === "Cancel") {
        if(item?.PackageInstanceId > 0 || item?.PrivateServiceType === PrivateServiceTypeEnum.PrivateUnpaid || item?.PrivateServiceType === PrivateServiceTypeEnum.SemiPrivateUnpaid){
          setSelectedAppt(item);
          setVisible({
            ...visible,
            delete: true,
          });
        } else {
          setSelectedAppt(item);
          const tenantService = new TenantService();
          const res = await tenantService.getClient(item.UserMemberId);
          const user = res;
          setUserDetail({
            FullName: item?.MemberName,
            UserId: user?.UserId,
            UserMemberId: item.UserMemberId,
            PrimaryMemberId: item?.ParentMemberId,
            MemberNumber: user?.MemberNumber,
            MemberType: item?.MemberType,
          });
          setVisibility({
            ...visibility,
            cancelAppointment: true,
          });
        }
        
      } else if (text === "Revert Appointment Completion") {
        setVisible({
          ...visible,
          revert: true,
        });
        setSelectedAppt(item);
      } else if (text === "Notes") {
        setVisible({
          ...visible,
          notes: true,
        });
        setNotes(item?.TrainerComments);
        setSelectedAppt(item);
      } else if (text === "Reschedule") {
        const currentTime = new Date();
        const sessionStartTime = new Date(item.FromTime);
        const sessionEndTime = new Date(item.ToTime);
        const currentDateTime=moment(currentTime)?.format("YYYY-MM-DDTHH:mm:ss")
        const sessionStartDateTime=moment(sessionStartTime)?.format("YYYY-MM-DDTHH:mm:ss")
        const sessionEndDateTime=moment(sessionEndTime)?.format("YYYY-MM-DDTHH:mm:ss")
        if (staffPage && currentDateTime >= sessionEndDateTime) {
          props?.handleNotificationMessage(
            "Cannot reschedule past appointments.",
            "error"
          );
          return;
        }
        if (!staffPage && currentDateTime >= sessionStartDateTime) {
          props?.handleNotificationMessage(
            "Cannot reschedule past appointments.",
            "error"
          );
          return;
        }
        let userInfo ={
          FullName: item?.MemberName,
          UserId: item?.MemberUserId,
          UserMemberId: item?.UserMemberId,
          PrimaryMemberId: item?.ParentMemberId
        };
        if (staffPage) {
          history.push(
            `/member/${props?.uuid}/${userData?.UserMemberId}/scheduleappointment`,
            {
              selectedEvent: item,
              staffPage: true,
              userData: userInfo,
              uuid: props?.uuid,
              UpdateAppointment: true,
              IsComplimentary:item?.IsComplimentary ? true : false
            }
          );
        } else {
          if (redirectionObj?.firstPathValue !== "") {
            history.push(`/${redirectionObj?.firstPathValue}/scheduleappointment`, {
              selectedEvent: item,
              UpdateAppointment: true,
              userData: userData,
              IsComplimentary:item?.IsComplimentary ? true : false
            });
          } else {
            history.push(`/scheduleappointment`, {
              selectedEvent: item,
              UpdateAppointment: true,
              userData: userInfo,
              IsComplimentary:item?.IsComplimentary ? true : false
            });
          }
        }
      } else {
        setSelectedAppt({ ...item, text: text });
        //api for Show,No show and dispute
        handleUpdateStatus(item, text);
      }
    }
  };

  
  useEffect(() => {
    if(!props?.fromDateToDate){
      getUpcomingAppointments(0, null);
    }
  }, []);
  useEffect(() => {
    if(props?.req){
      getUpcomingAppointments(0, null);
    }
  }, [props?.req]);
  useEffect(() => {
      getUpcomingAppointments(0, null);
  }, [props?.fromDateToDate]);
  useEffect(() => {
    if (hasMounted.current) {
      getUpcomingAppointments(0, null);
    }else {
      hasMounted.current = true;
    }
  }, [props?.serviceFilter]);

  // check-ins 
  const checkForFutureRecords = (dataItem: any) => {
    let doCheckin = false;
    const entityType = dataItem.EntityType;
    const timeStamp = dataItem.FromTime;
    let minDuration = 240;
    if (entityType === 2) {
      minDuration =
      securityConfig?.GroupClassCheckinPreference
          ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin;
    } else if (entityType === 3) {
      minDuration =
      securityConfig?.OneOnOneCheckinPreference
          ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin;
    }
    let diff = moment(moment(timeStamp).format("YYYY-MM-DD HH:mm:ss")).diff(
      moment().format("YYYY-MM-DD HH:mm:ss"),
      "minutes"
    );
    if (diff < minDuration && diff > -30) {
      doCheckin = true;
    } else {
      doCheckin = false;
    }
    if (doCheckin) {
      if (
        dataItem?.CheckinTimestamp !== null ||
        (!dataItem?.IsPaid && (dataItem.PrivateServiceType === PrivateServiceTypeEnum.PrivatePaid || dataItem.PrivateServiceType === PrivateServiceTypeEnum.SemiPrivatePaid)) ||
        dataItem?.futureAppointment
      ) {
        doCheckin = false;
      } else {
        doCheckin = true;
      }
    }

    return doCheckin;
  };
  

  const checkIfUserCanCheckin = (accessCardDetails: any) => {
    if(isOpenPathSystem()) {
      return checkIfUserCanCheckinForOpenPath(accessCardDetails);
    }
    if(isBarcodeSystem()) {
      return checkIfUserCanCheckinForBarcode(accessCardDetails);
    }
    let doCheckin = false;
    return doCheckin;
  };
  // To handle Check-In from Appointments
  const handleCheckIn = async (item: any) => {
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      PrimaryUserMemberId: props?.userData?.MainUserId,
      IncludeSubMember: false,
    };
    const simpleAccessService = new SimpleAccessService();
    let response = await simpleAccessService.getMembersWithAccess(req);
    let doCheckin = true;

    if (!response.length) {
      doCheckin = false;
      props?.handleNotificationMessage(
        "Access card is not assigned to the selected user",
        "error"
      );
    } else {
      doCheckin = checkIfUserCanCheckin(response[0].AccessCard);
    }
    if (!doCheckin) {
      return true;
    }
    const checkinService = new CheckInService();
    const obj = {
      Ocurred: moment().format("YYYY-MM-DDTHH:mm:ss"),
      Type: item?.EntityType,
      CheckedInUserId: props?.userData?.UserId,
      CheckinMode: 1,
      EntityName: item?.ServiceName,
      ScheduledStartTime: item?.FromTime,
      EntityId: item?.EntityId,
      EntityType: item?.EntityType,
      ScheduleId: item?.ScheduleId,
    };
    const result = await checkinService?.saveEventsCheckins({
      CheckinEvents: [obj],
    });
    console.log(result);
  if (result?.ResponseCode === 300) {
      props?.handleNotificationMessage(
        "Check-In successfully completed",
        "success"
      );
      getUpcomingAppointments(0);
    } else {
      props?.handleNotificationMessage(result.ErrorMessages[0], "error");
    }
  };

  
  return {
    loading,
    searchAppointments,
    visible,
    renewConfirmDialog,
    setRenewConfirmDialog,
    selectedAppt,
    handleUpdateStatus,
    toggleDialog,
    isLoading,
    cancelAppointment,
    revertAppointment,
    notes,
    addNotes,
    notesLoading,
    SubmitNotes,
    itemsList,
    CanRevertSchedule,
    handleSelection,
    getUpcomingAppointments,
    checkinItem,
    checkForFutureRecords,
    payRemainingDueItem,
    transactionState,
    onWindowClose,
    cancelClients,
    session,
    visibility,
    handleVisibility,
    WaitListItems,
    changePaymentObj,
    selectedWaitListClient,
    userDetail,
    btndisableRenew
  };
};