import { Dialog } from "@progress/kendo-react-dialogs";
import React from "react";
import { checkIfUserCanAddBankAccount, paymentDropdownItems } from "utils";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import BuzopsLoader from "generic-components/BuzopsLoader";
import BuzopsButton from "generic-components/BuzopsButton";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import withNotification from "components/Hoc/withNotification";
import useAddCreditsTopUp from "./AddCreditsTopup.hook";

const AddCreditsTopup = (props: any) => {
  const {
    handleReset,
    handleSuccessClosePaymethodDialog,
    handleClosePaymethodDialog,
    handleChangeSavedCard,
    onCardOrBankAdd,
    addNewItem,
    loading,
    formik,
    paymentProfile,
    selectedPaymentProfile,
    showPaymentDialog,
    btnLoading,
  } = useAddCreditsTopUp(props);
  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${
            checkIfUserCanAddBankAccount() ? "Add Bank or Card" : "Add Card"
          }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={true}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={props?.UserMemberId}
          />
        </Dialog>
      </>
    );
  };

  if (loading) {
    return <BuzopsLoader />;
  }

  return (
    <Dialog
      title={"Credits Top Up"}
      onClose={() => {
        props?.handleCloseDialog();
      }}
      className="bz-addCard-dialog bz-credit-topup-modal"
    >
      <div className="bz-subsection-row one-time-charge">
        <div className="left-section">
          <div className="bz-mt-2rem bz-mb-2_5rem">
            <form onSubmit={formik.handleSubmit}>
              <div
                className={`bz-content-text-row ${
                  formik.errors.CreditAmount ? "align-items-start" : ""
                }`}
              >
                <div className="bz-content-title text-right">Amount</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <div className={"k-form-field-wrap"}>
                    <NumericTextBox
                      width={280}
                      defaultValue={0}
                      format="c2"
                      min={0}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "CreditAmount",
                          event?.target?.value
                        );
                      }}
                      value={formik.values.CreditAmount}
                    />
                    {formik.errors.CreditAmount && (
                      <Error>
                        <span>{formik.errors.CreditAmount}</span>
                      </Error>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`bz-content-text-row ${
                  formik.errors.PaymentGatewayPaymentProfileID
                    ? "align-items-start"
                    : ""
                }`}
              >
                <div className="bz-content-title text-right">
                  Payment Profile
                </div>
                <span className="bz-colon text-center">:</span>

                <div className="bz-content-body payment-profile">
                  <div>
                    <DropDownList
                      // width={280}

                      id={"selectedPaymentProfile"}
                      name={"selectedPaymentProfile"}
                      data={paymentProfile?.filter(
                        (i: any) => i?.CardTypeId !== 8
                      )}
                      listNoDataRender={() => "No Payment Methods"}
                      textField="MaskedCCNumber"
                      dataItemKey="PaymentProfileID"
                      // className={"form-control col-md-5"}
                      valueRender={(
                        el: React.ReactElement<HTMLSpanElement>,
                        value: any
                      ) =>
                        PaymentValueRender(el, value, selectedPaymentProfile)
                      }
                      itemRender={PaymentItemRender}
                      key={`${selectedPaymentProfile?.PaymentGatewayPaymentProfileID}`}
                      onChange={(e) => {
                        handleChangeSavedCard(e.value);
                        formik.setFieldValue(
                          "PaymentGatewayPaymentProfileID",
                          e.value?.PaymentGatewayPaymentProfileID
                        );
                      }}
                      style={{ width: "280px" }}
                    />
                    {formik.errors.PaymentGatewayPaymentProfileID &&
                      formik.submitCount > 0 && (
                        <Error>
                          <span>
                            {formik.errors.PaymentGatewayPaymentProfileID}
                          </span>
                        </Error>
                      )}
                  </div>
                  <div className="addNewBtn">
                    <DropDownButton
                      text="Add New"
                      onItemClick={(e: any) => onCardOrBankAdd(e)}
                      items={paymentDropdownItems.filter(
                        (i: any) => i?.visible === true
                      )}
                      look="flat"
                      className="btn btn-link add-new-btn"
                    />
                  </div>
                </div>
              </div>
              <div className="bz-content-text-row mt-4 pt-2">
                <div className="bz-content-title text-right"></div>
                <span className="bz-colon text-center"></span>
                <div className="bz-content-body">
                  <Button
                    className="ml-0"
                    disabled={!formik.dirty || btnLoading}
                    // look="flat"
                    type="button"
                    onClick={() => handleReset()}
                  >
                    Reset
                  </Button>
                  <BuzopsButton
                    style={{ textTransform: "none" }}
                    type="submit"
                    label="Add to Wallet"
                    className="btn "
                    disabled={!formik.dirty || btnLoading}
                    loading={btnLoading}
                  />
                </div>
              </div>
            </form>
          </div>
          {showPaymentDialog && PaymethodDialog()}
        </div>
      </div>
    </Dialog>
  );
};

export default withNotification(AddCreditsTopup);
